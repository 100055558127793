import React from 'react'
import './Footer.css'
import { Link } from 'react-router-dom'

function Footer() {
    return (
        <>
            <div className="footerCnt">
                <div className="hmcustomContainer d-flex align-items-start justify-content-between flex-wrap">
                    <div className="lftFtrLgnCntn">
                        <a href={'/'} className="footlogo">
                            <img
                                className="Logo"
                                src="/images/footer_logo.png"
                                alt="Coins-and-Auctions-Since-1994"
                            />
                        </a>
                        <p className="pgTgInfoLn">LICENSED | BONDED | INSURED</p>
                        <div className="scmlMdaLnks">
                            <div className="mdaWrpr fbLnk">
                                <a
                                    href="https://www.facebook.com/coinssince1994/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img src="/images/fb_footer.svg" />
                                </a>
                            </div>
                            <div className="mdaWrpr twLnk">
                                <a
                                    href="https://twitter.com/CoinsSince1994?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img src="/images/twitter_footer.svg" />
                                </a>
                            </div>
                        </div>
                        <div className="termWrp">
                            <ul className="d-flex align-items-center justify-content-start">
                                <li>
                                    <Link to="/">Home</Link>
                                </li>
                                <li>
                                    <Link to="/contactus">Contact Us</Link>
                                </li>
                                <li>
                                    <Link to="/aboutus">About Us</Link>
                                </li>
                            </ul>
                            <div className="mlInfoTxt text-left">
                                <a href="mailto:ken@coinssince1994.com">ken@coinssince1994.com</a>
                                <br />
                                <a href="mailto:takumi@coinssince1994.com">
                                    takumi@coinssince1994.com
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="rtCntIntoWrpr">
                        <div className="adrsInfoWrpr text-left">
                            <p className="stnTtle">FIND US HERE</p>
                            <div className="flxGrdVwRp">
                                <img src="/images/footer_user.svg" />
                                <div className="rtCrsTxt">
                                    <p>Ken & Takumi</p>
                                </div>
                            </div>
                            <div className="flxGrdVwRp">
                                <img src="/images/footer_company.svg" />
                                <div className="rtCrsTxt">
                                    <p>Coin & Auctions Since 1994</p>
                                </div>
                            </div>
                            <div className="flxGrdVwRp">
                                <img src="/images/footer_location.svg" />
                                <div className="rtCrsTxt">
                                    <p>
                                        12014 SE Mill Plain Blvd.
                                        <br /> Suite 220B Vancouver, WA
                                        <br /> 98684{' '}
                                    </p>
                                </div>
                            </div>
                            <div className="flxGrdVwRp">
                                <img src="/images/footer_phone.svg" />
                                <div className="rtCrsTxt">
                                    <p>
                                        Toll Free: <a href="tel:8886552646">(888) 655-COIN(2646)</a>
                                    </p>
                                    <p>
                                        Text: <a href="tel:3602815588">(360) 281-5588</a>
                                    </p>
                                    <p>
                                        Local: <a href="tel:3602535565">(360) 253-5565</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="lvActnrRvw col-md-4 col-sm-6">
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11161.97595185729!2d-122.5486834!3d45.6208118!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x822420effd3235f0!2sCoins%20%26%20Auctions%20Since%201994!5e0!3m2!1sen!2sus!4v1640628672034!5m2!1sen!2sus"
                                width="300"
                                height="300"
                                style={{ border: 0 }}
                                allowFullScreen=""
                                loading="lazy"
                            ></iframe>
                        </div>
                    </div>
                </div>
            </div>
            <div className="cpyRtFtr">
                <p>
                    ©{new Date().getFullYear()} Coins & Auctions Since 1994 |{' '}
                    <Link to="/TermsandConditions">Terms & Conditions</Link>
                </p>
            </div>
        </>
    )
}

export default Footer
