import React, { useContext, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { currencyFormat, handleRedirectInternal } from '../../../common/components'
import axiosmethod from 'axios'
import axios from '../../../Utils/Utility/axiosconfig'
import SecondaryButton from '../../../components/atoms/SecondaryButton/index'
import Swal from 'sweetalert2'
import './Payment.css'
const AdditionalPayment = ({ item, user, setAddtionalpay }) => {
    const [savedcards, setSelectedCard] = useState([])
    const [allData, setAllData] = useState([])
    const [locdata, setLocdata] = useState(false)
    const [allBank, setAllBank] = useState([])
    const [cardData, setCardData] = useState('')
    const [achData, setAchData] = useState('')
    const [loading, setLoading] = useState(false)
    let history = useHistory()

    const allsavedCards = async () => {
        const userData = JSON.parse(localStorage.getItem('userData'))
        const cardID = userData.card_paymentid
        let body = {
            account_id: process.env.REACT_APP_AUCTIONPAYKEY,
            customer_id: cardID,
        }
        const { data } = await axiosmethod.post(
            `${process.env.REACT_APP_PAYMENT_API_URL}/api/customer/source/list`,
            body,
        )

        try {
            if (data.status === 'success') {
                console.log('data.responseData.data ', data.data.responseData.data)
                data.data.responseData.data &&
                    setAllData(data.data.responseData.data.filter((data) => data.object == 'card'))
            } else {
                setSelectedCard([])
            }
            setLocdata(true)
        } catch (err) {
            console.log(err)
        }
    }

    const allsavedbanks = async () => {
        const user = JSON.parse(localStorage.getItem('userData'))
        let body = {
            site_id: process.env.REACT_APP_SITE_ID,
            email: user.email ? user.email : '',
            user_id: user.id ? user.id : '',
        }
        const { data } = await axios.post(`plugin/ach/stripe/bank/getBankDetails`, body)
        try {
            if (data.status === 'success') {
                console.log('data.responseData.data ', data.data.message.data.responseData.data)
                data.data.message.data.responseData.data &&
                    setAllBank(data.data.message.data.responseData.data)
                // data.selectedCard && setSelected(data.selectedCard)
            } else {
                setAllBank([])
            }
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        allsavedCards()
        allsavedbanks()
    }, [])

    const successAfterPayAuctionPartial = async () => {
        var user_i = JSON.parse(localStorage.getItem('userData'))
        const body = {
            partial_pay: parseFloat(item.ship_offer),
            user_id: user_i.id ? user_i.id : '',
            partial_paytype: 'card payment',
            invoice_id: [item.common_invoice],
            payment_details: {
                source: cardData,
                customer: user.card_paymentid,
                currency: user?.country == 38 ? 'cad' : 'usd',
                description: `Additional Amount Invoice #${item.common_invoice}`,
            },
        }

        const res = await axiosmethod.post(
            `${process.env.REACT_APP_DOMAIN}api_buyer/successAfterPayAuctionPartial`,
            body,
        )
        setLoading(false)
        if (res.data.status == 'yes') {
            Swal.fire({
                title: 'Payment Updated Successfully!',
                icon: 'success',
                timer: 1500,
                showConfirmButton: false,
                position: 'center',
            })
            window.location.reload()
        } else {
            if (res.data.message) {
                Swal.fire({
                    title: res.data.message,
                    icon: 'error',
                    timer: 1500,
                    showConfirmButton: false,
                    position: 'center',
                })
            } else {
                Swal.fire({
                    title: 'Payment failed!',
                    icon: 'error',
                    timer: 1500,
                    showConfirmButton: false,
                    position: 'center',
                })
            }
        }
    }

    const sucessAfterPayAchPartial = async () => {
        var user_i = JSON.parse(localStorage.getItem('userData'))
        var data = {
            site_id: process.env.REACT_APP_SITE_ID,
            user_id: user_i.id ? user_i.id : '',
            payAmount: parseInt(parseFloat(item.ship_offer) * 100),
        }

        const res = await axios.post(
            `${process.env.REACT_APP_DOMAIN}plugin/ach/stripe/payment/createBankPay`,
            data,
        )
        if (
            res.data.status == 'success' &&
            res.data.data.message.data.message == 'Payment successfully!'
        ) {
            const second_body = {
                partial_pay: parseFloat(item.ship_offer),
                user_id: user_i.id ? user_i.id : '',
                partial_paytype: 'Ach payment',
                invoice_id: [item.common_invoice],
                payment_details: {},
            }

            const res_ach = await axiosmethod.post(
                `${process.env.REACT_APP_DOMAIN}api_buyer/successAfterPayAuctionPartial_Ach`,
                second_body,
            )
            setLoading(false)
            if (res_ach.data.status == 'yes') {
                Swal.fire({
                    title: 'Payment Updated Successfully!',
                    icon: 'success',
                    timer: 1500,
                    showConfirmButton: false,
                    position: 'center',
                })
                window.location.reload()
            } else {
                if (res_ach.data.message) {
                    Swal.fire({
                        title: res_ach.data.message,
                        icon: 'error',
                        timer: 1500,
                        showConfirmButton: false,
                        position: 'center',
                    })
                } else {
                    Swal.fire({
                        title: 'Payment failed!',
                        icon: 'error',
                        timer: 1500,
                        showConfirmButton: false,
                        position: 'center',
                    })
                }
            }
        } else {
            Swal.fire({
                title: res.data.data.message,
                icon: 'error',
                timer: 1500,
                showConfirmButton: false,
                position: 'center',
            })
            return false
        }
    }

    useEffect(() => {
        allsavedCards()
    }, [])

    const onChange = (e) => {
        setAchData('')
        e.target.checked ? setCardData(e.target.value) : setCardData('')
    }

    const onChangeach = (e) => {
        setCardData('')
        e.target.checked ? setAchData(e.target.value) : setAchData('')
    }

    useEffect(() => {
        if (allData.length > 0) {
            setCardData(allData[0].id)
        }
    }, [allData])
    const handlePayment = () => {
        if (!cardData && !achData) {
            return Swal.fire({
                title: 'Kindly Select A Card!',
                icon: 'error',
                timer: 1500,
                showConfirmButton: false,
                position: 'center',
            })
        }
        setLoading(true)
        if (cardData) {
            successAfterPayAuctionPartial()
        } else {
            sucessAfterPayAchPartial()
        }
    }
    return (
        <div>
            <div className="row">
                <div className="col-md-6">
                    <img
                        className="img-fluid"
                        src={`${process.env.REACT_APP_PRODUCT_IMG}${item.avatar}`}
                    />
                </div>
                <div className="col-md-6">
                    <div className="text-left additionalPay">
                        <h4 className="tit">{item.title}</h4>
                        <p className="mb-2">
                            <strong>Invoice Id:</strong> {item.common_invoice}
                        </p>
                        <p className="mb-2">
                            <strong>Additional Amount Need To Pay:</strong>{' '}
                            {currencyFormat(item.ship_offer)}
                        </p>
                        <p className="mb-2">
                            <b>Please Select Card:</b>
                        </p>
                        {allData.map((data, index) => (
                            <div key={index} className="cardSelect w-100">
                                <label htmlFor={`ritemb${index}`} className="cardmain w-100">
                                    <div className="flx d-flex align-items-center justify-content-between">
                                        <p>{'xxxx-xxxx-xxxx-' + data.last4}</p>
                                        <div className="radio-item">
                                            <input
                                                type="checkbox"
                                                id={`ritemb${index}`}
                                                name="ritem"
                                                value={data.id}
                                                checked={cardData === data.id}
                                                onChange={onChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="flx d-flex align-items-center justify-content-between">
                                        <p className="m-0">{data.brand}</p>
                                        <p className="m-0">
                                            {data.exp_month}/{data.exp_year}
                                        </p>
                                    </div>
                                </label>
                            </div>
                        ))}
                        {allData.length == 0 && locdata ? (
                            <SecondaryButton
                                label={`Kinldy add a Card to Continue!`}
                                className="mbv-btn"
                                onClick={() =>
                                    handleRedirectInternal(history, 'Dashboard/Paymenttabs')
                                }
                            />
                        ) : (
                            ''
                        )}
                        {/* <p>Please Select Ach:</p>
                        {allBank.map((data, index) => (
                            <div key={index} className="cardSelect">
                                <label htmlFor={`ritemb${index}`} className="cardmain">
                                    <div className="flx">
                                        <p>{data.bank_name}</p>
                                        <div className="radio-item">
                                            <input
                                                type="checkbox"
                                                id={`ritemb${index}`}
                                                name="ritem"
                                                value={index}
                                                checked={parseInt(achData) === parseInt(index)}
                                                onChange={onChangeach}
                                            />
                                        </div>
                                    </div>
                                    <div className="flx">
                                        <p>{data.account_holder_name}</p>
                                    </div>
                                </label>
                            </div>
                        ))}
                        {allBank.length == 0 ? (
                            <SecondaryButton
                                label={`Kinldy Add A Card To Continue!`}
                                className="mbv-btn"
                                onClick={() => handleRedirectInternal(history, 'Dashboard/bankDetails')}
                            />
                        ) : (
                            ''
                        )} */}
                        {allData.length > 0 || allBank.length > 0 ? (
                            <SecondaryButton
                                label={`Pay ${currencyFormat(item.ship_offer)}`}
                                className="mbv-btn"
                                onClick={handlePayment}
                                disabled={loading}
                            />
                        ) : (
                            ''
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdditionalPayment
