/*eslint-disable*/
import React, { useEffect, useState, useContext, useRef } from 'react'
import './cart.css'
import { connect, useDispatch } from 'react-redux'
import { currencyFormat, handleRedirectInternal } from '../../common/components'
import { onImageError } from '../../Utils/Utility'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import Swal from 'sweetalert2'
import cartDetails from '../../actions/cartAuction'
import axios from '../../Utils/Utility/axiosconfig'
import PrimaryButton from '../../components/atoms/PrimaryButton'
import { useHistory } from 'react-router-dom'
const CartComponent = (props) => {
    const [viewCartItems, setViewCartItems] = useState([])
    const [total, setTotal] = useState(0)
    const [totalBuyersPremium, setTotalBuyersPremium] = useState(0)
    const [shippingFee, setShippingFee] = useState(0)
    const [transactionFee, setTransactionFee] = useState(0)
    const [salesTax, setSalesTax] = useState(0)
    const history = useHistory()
    const dispatch = useDispatch()

    useEffect(() => {
        if (props.cart_details?.length > 0) {
            setViewCartItems(props.cart_details)
        } else {
            setViewCartItems([])
        }
    }, [props.cart_details])

    useEffect(() => {
        if (viewCartItems.length) {
            setTotal(_.sumBy(viewCartItems, (x) => parseFloat(x.bprice)))
            setShippingFee(_.sumBy(viewCartItems, (x) => parseFloat(x.shipping_fee)))
            setTransactionFee(_.sumBy(viewCartItems, (x) => parseFloat(x.transaction_fee)))
            setSalesTax(_.sumBy(viewCartItems, (x) => parseFloat(x.sales_tax)))
            setTotalBuyersPremium(
                _.sumBy(viewCartItems, (x) => parseFloat(x.buyers_premium + x.charge_fee)),
            )
        }
    }, [viewCartItems])

    const RemovetoCart = async (body) => {
        let { data } = await axios.get('mobileapi/removefromcart?id=' + body.id, {})
        if (data.success) {
            Swal.fire({
                icon: 'success',
                title: 'Item Removed From Cart Successfully',
                showConfirmButton: true,
                position: 'center',
            })
            dispatch(cartDetails({ auction_io: 1, city_sales_tax_enable: 0 }))
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Something Went Wrong!',
                showConfirmButton: true,
                position: 'center',
            })
        }
    }

    const redirect_chekout = () => {
        handleRedirectInternal(history, 'checkout/buynow')
    }

    return (
        <>
            <div className="container mt-5 cartCnt">
                {viewCartItems.length !== 0 ? (
                    <div className="row justify-content-center">
                        <div className="col-md-10">
                            <div className="cart-table">
                                <table id="myTable" className="table">
                                    <thead>
                                        <tr>
                                            <th>Product</th>
                                            <th>Name</th>
                                            {/* <th>Qty</th> */}
                                            <th>Price</th>

                                            <th>Buyer&apos;s Premium</th>
                                            <th>Transaction Fee</th>
                                            <th>Shipping Fee</th>
                                            <th>Sales Tax</th>
                                            <th className="text-right">
                                                <span id="amount" className="amount">
                                                    Amount
                                                </span>{' '}
                                            </th>

                                            <th data-title="Action">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {viewCartItems.map((item, index) => (
                                            <tr
                                                style={{
                                                    backgroundColor: '#ffd6cc',
                                                }}
                                                className="cart-row"
                                            >
                                                <td className="ct-img">
                                                    <div className="product-img">
                                                        <div className="img-prdct">
                                                            <LazyLoadImage
                                                                className="cardImage"
                                                                src={
                                                                    process.env
                                                                        .REACT_APP_PRODUCT_IMG +
                                                                    item.is_avatar
                                                                }
                                                                onError={onImageError}
                                                                // onClick={
                                                                //     props.onClick ? props.onClick : null
                                                                // }
                                                                effect="blur"
                                                                placeholderSrc="/images/imageLoading.svg"
                                                                height="100%"
                                                                width="100%"
                                                                alt="Next Lot"
                                                            />
                                                        </div>
                                                    </div>
                                                </td>
                                                <td data-title="Name">{`${item.title}`}</td>
                                                <td data-title="Price">
                                                    <div>
                                                        <span id="amount" className="amount">
                                                            {currencyFormat(
                                                                parseFloat(item.bprice),
                                                            )}
                                                        </span>
                                                    </div>
                                                </td>

                                                <td data-title="Buyer's Premium">
                                                    <div>
                                                        <span id="amount" className="amount">
                                                            {currencyFormat(
                                                                parseFloat(
                                                                    item.buyers_premium +
                                                                        item.charge_fee,
                                                                ),
                                                            )}
                                                        </span>{' '}
                                                        - (
                                                        {item.buyers_premium_percentage +
                                                            item.charge_percentage}
                                                        %)
                                                    </div>
                                                </td>

                                                <td data-title="Transaction Fee">
                                                    <div>
                                                        <span id="amount" className="amount">
                                                            {currencyFormat(
                                                                parseFloat(item.transaction_fee),
                                                            )}
                                                        </span>
                                                    </div>
                                                </td>

                                                <td data-title="Shipping Fee">
                                                    <div>
                                                        <span id="amount" className="amount">
                                                            {currencyFormat(
                                                                parseFloat(item.shipping_fee),
                                                            )}
                                                        </span>{' '}
                                                        {item.shipping_type == 1
                                                            ? `(${item.shipping_percentage}%)`
                                                            : ''}
                                                    </div>
                                                </td>

                                                <td data-title="Sales Tax">
                                                    <div>
                                                        <span id="amount" className="amount">
                                                            {currencyFormat(
                                                                parseFloat(item.sales_tax),
                                                            )}
                                                        </span>{' '}
                                                        ({item.sales_tax_percentage}%)
                                                    </div>
                                                </td>
                                                <td
                                                    data-title="Amount"
                                                    style={{ textAlign: 'right' }}
                                                    align="right"
                                                >
                                                    <div>
                                                        <span id="amount" className="amount">
                                                            {currencyFormat(
                                                                parseFloat(
                                                                    item.bprice +
                                                                        item.buyers_premium +
                                                                        item.charge_fee +
                                                                        item.shipping_fee +
                                                                        item.transaction_fee +
                                                                        item.sales_tax,
                                                                ),
                                                            )}
                                                        </span>
                                                    </div>
                                                </td>

                                                <td className="ct-action">
                                                    {' '}
                                                    <a
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() =>
                                                            RemovetoCart({ id: item.project_id })
                                                        }
                                                    >
                                                        {' '}
                                                        <span className="material-icons">
                                                            delete
                                                        </span>
                                                    </a>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <div className="cartAmtPay float-right">
                                    <div className="caVal d-flex justify-content-between align-items-center">
                                        <p>TOTAL : </p>
                                        <span id="total" className="total">
                                            {currencyFormat(parseFloat(total))}
                                        </span>
                                    </div>

                                    <div className="caVal d-flex justify-content-between align-items-center">
                                        <p>{`Buyer's Premium`} : </p>
                                        <span id="total" className="total">
                                            {currencyFormat(parseFloat(totalBuyersPremium))}
                                        </span>
                                    </div>

                                    <div className="caVal d-flex justify-content-between align-items-center">
                                        <p>Transaction Fee : </p>
                                        <span id="total" className="total">
                                            {currencyFormat(parseFloat(transactionFee))}
                                        </span>
                                    </div>

                                    <div className="caVal d-flex justify-content-between align-items-center">
                                        <p>Shipping Fee : </p>
                                        <span id="total" className="total">
                                            {currencyFormat(parseFloat(shippingFee))}
                                        </span>
                                    </div>

                                    <div className="caVal d-flex justify-content-between align-items-center">
                                        <p>Sales Tax : </p>
                                        <span id="total" className="total">
                                            {currencyFormat(parseFloat(salesTax))}
                                        </span>
                                    </div>
                                    {/* {user.tax_excempt === 0 && (
                                        <div className="caVal d-flex justify-content-between align-items-center">
                                            <p>TAX {taxData}% :</p>
                                            <span id="total" className="total">
                                                US$ {percentage(taxData, total).toUSFormat()}
                                            </span>
                                        </div>
                                    )}
                                    <div className="caVal d-flex justify-content-between align-items-center">
                                        <p>CONVENIENCE FEES {fee}% :</p>
                                        <span id="total" className="total">
                                            {` US$  ${percentage(fee, total).toUSFormat()}`}
                                        </span>
                                    </div> */}
                                    <div className="caVal d-flex justify-content-between align-items-center">
                                        <p>GRAND TOTAL :</p>
                                        <span id="total" className="total">
                                            {currencyFormat(
                                                parseFloat(
                                                    total +
                                                        totalBuyersPremium +
                                                        shippingFee +
                                                        transactionFee +
                                                        salesTax,
                                                ),
                                            )}
                                        </span>
                                    </div>

                                    <div
                                        className="text-center cartActBtn"
                                        style={{ marginBottom: '45px' }}
                                    >
                                        <PrimaryButton
                                            onClick={() => redirect_chekout()}
                                            label={'Check out'}
                                            //disabled={posLoading}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="np-pro-box">
                        {' '}
                        <p>No Records Found</p>
                    </div>
                )}
            </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    cart_details: state.cartDetails.cart_details,
})

export default connect(mapStateToProps)(CartComponent)
