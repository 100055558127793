import { Button } from '@material-ui/core'
import React, { useState, useContext, useEffect } from 'react'
import CustomInput from '../../atoms/Inputs/CustomInput'
import PrimaryButton from '../../atoms/PrimaryButton'
import '../../molecules/ProductCard/ProductCard.css'
import SecondaryButton from '../../atoms/SecondaryButton'
import { handleRedirectInternal, currencyFormat } from '../../../common/components'
import { useHistory } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import commonBidding from '../../../Utils/API/nomalBidding'
import isAuthenticate from '../../../Utils/Utility/auth'
import Swal from 'sweetalert2'
import Select from 'react-select'
const Bidding = (props) => {
    const [product, setProduct] = React.useState()
    const [product_id, setProductId] = useState()
    const history = useHistory()
    const [displayarray, setDisarray] = useState([])
    const [defaultValue_arr, setDefaultValue] = useState({})
    const [finalArray, setFinalArray] = useState([])
    const [offerbtnDisable, setOfferbtnDisable] = useState(false)
    useEffect(() => {
        setProduct(props.data)
    }, [props.data])

    useEffect(() => {
        setProductId(props.id)
        formik.setFieldValue('wsprice', '')
        formik.setErrors({})
        formik.setTouched(false)
    }, [props.id])

    const validationArray = Yup.object({
        wsprice: Yup.number()
            .min(props.next_bid ? props.next_bid : 0, `Min. Bid $${props ? props.next_bid : 0}`)
            .required('Enter Bid Amount'),
    })

    const formik = useFormik({
        initialValues: {
            wsprice: '',
            id: product_id,
            auction_io: 1,
            email: props.user.email,
            first_name: props.user.first_name,
            last_name: props.user.last_name,
            bid_increment: props.data.incrementamt,
            hardbid: 0,
        },
        validationSchema: validationArray,
        onSubmit: (values) => {
            if (new Date(product.date_added) > new Date()) {
                return Swal.fire({
                    icon: 'warning',
                    title: 'Wait until auction starts!',
                    timer: 1500,
                    showConfirmButton: false,
                    position: 'center',
                })
            }
            setOfferbtnDisable(true)
            values.id = product_id
            commonBidding(values)
            formik.values.wsprice = ''
            setOfferbtnDisable(false)
        },
    })

    const setFinalobj = () => {
        var next_set = finalArray,
            bid_inc = 0,
            new_current_bid = finalArray[finalArray.length - 1]
        for (var j = 0; j < 75; j++) {
            //console.log('incrment array', props.bidincrement, new_current_bid)
            props.bidincrement.map((data) => {
                if (
                    parseInt(data.bprice_range_from) <= parseInt(new_current_bid) &&
                    parseInt(data.bprice_range_to) >= parseInt(new_current_bid)
                ) {
                    bid_inc = parseInt(data.per_price)
                    new_current_bid = parseInt(new_current_bid) + parseInt(bid_inc)
                    next_set.push(new_current_bid)

                    //console.log('incrment array', bid_inc, new_current_bid)
                }
            })
            if (j == 74) {
                return next_set
            }
        }
    }

    const loadMoreOptions = async () => {
        const res_data = await setFinalobj()
        //console.log('Load More', res_data)
        setFinalArray([...res_data])
        //console.log('after set')
        //resetBidArraynew(Number(finalArray[finalArray.length - 1]), null, null)
    }
    const initialload = () => {
        var next_set_i = [props.next_bid],
            bid_inc_i = 0,
            new_current_bid_i = props.next_bid
        for (var k = 0; k < 75; k++) {
            //console.log('incrment array', props.bidincrement, new_current_bid_i)
            props.bidincrement.map((data) => {
                if (
                    parseInt(data.bprice_range_from) <= parseInt(new_current_bid_i) &&
                    parseInt(data.bprice_range_to) >= parseInt(new_current_bid_i)
                ) {
                    bid_inc_i = parseInt(data.per_price)
                    new_current_bid_i = parseInt(new_current_bid_i) + parseInt(bid_inc_i)
                    next_set_i.push(new_current_bid_i)

                    //console.log('incrment array', bid_inc_i, new_current_bid_i)
                }
            })
            if (k == 74) {
                //console.log('Load More', next_set_i)
                setFinalArray(next_set_i)
            }
        }

        //console.log('after set')
        //resetBidArraynew(Number(finalArray[finalArray.length - 1]), null, null)
    }

    useEffect(() => {
        if (finalArray.length > 0) {
            //console.log('final array', finalArray)
            var arr_dis = []
            finalArray.map((data) => {
                arr_dis.push({
                    label: currencyFormat(data),
                    value: data,
                })
            })
            setDisarray(arr_dis)
            formik.setFieldValue('wsprice', finalArray[0])
        }
    }, [finalArray])

    useEffect(() => {
        if (displayarray.length > 0) {
            setDefaultValue(displayarray[0])
        }
    }, [displayarray])

    useEffect(() => {
        //console.log('next bid amount', props.next_bid)
        if (props.next_bid) {
            initialload(props.next_bid)
        }
    }, [props.next_bid])

    return (
        <>
            {product ? (
                <>
                    {product.market_status === 'open' ? (
                        <form onSubmit={formik.handleSubmit}>
                            {props.dropenable ? (
                                <div className="biddingCnt">
                                    <Select
                                        className="bidSelectVal p-0"
                                        name="amount"
                                        value={defaultValue_arr}
                                        onChange={(val) => {
                                            setDefaultValue(val)
                                            formik.setFieldValue('wsprice', val.value)
                                        }}
                                        isSearchable={false}
                                        onMenuScrollToBottom={loadMoreOptions}
                                        options={displayarray}
                                    ></Select>
                                    {/* <CustomInput
                            label={`Enter bid`}
                            variant="standard"
                            onChange={formik.handleChange}
                            pattern="^\d*(.\d{0,2})?$"
                            name="amount"
                            type="number"
                            value={formik.values.amount}
                            placeholder={`Min bid ${currencyFormat(lotdetails.next_bid)}`}
                        /> */}
                                    <p className="text-danger text-left my-2">
                                        {formik.errors && formik.errors.amount}
                                    </p>
                                    {isAuthenticate() ? (
                                        <PrimaryButton
                                            label={'Place Max bid'}
                                            type="submit"
                                            btnSize={props.size ? props.size : 'small'}
                                            className="fs-16"
                                            disabled={offerbtnDisable}
                                        />
                                    ) : (
                                        <PrimaryButton
                                            label="Login to Bid"
                                            btnSize={props.size ? props.size : 'small'}
                                            onClick={(e) => {
                                                e.preventDefault()
                                                handleRedirectInternal(history, 'login')
                                            }}
                                        />
                                    )}
                                </div>
                            ) : (
                                <div className="biddingCnt d-flex align-items-start mt-4">
                                    <div className="mr-2">
                                        <CustomInput
                                            size="small"
                                            label="Enter bid"
                                            value={formik.values.wsprice}
                                            name="wsprice"
                                            inputProps={{
                                                min: props.next_bid,
                                            }}
                                            onChange={formik.handleChange}
                                            placeholder={
                                                props.next_bid
                                                    ? `Min. Bid ` + currencyFormat(props.next_bid)
                                                    : ''
                                            }
                                            min="0"
                                            type="number"
                                            error={
                                                formik.errors.wsprice &&
                                                formik.touched.wsprice &&
                                                formik.errors.wsprice
                                            }
                                            helperText={
                                                formik.errors.wsprice &&
                                                formik.touched.wsprice &&
                                                formik.errors.wsprice
                                            }
                                        />
                                    </div>
                                    {isAuthenticate() ? (
                                        product.bidtopstatus === 'winner' ? (
                                            <SecondaryButton
                                                type="submit"
                                                label="Increase Bid"
                                                btnSize="small"
                                            />
                                        ) : (
                                            <PrimaryButton
                                                type="submit"
                                                label="Place Bid"
                                                btnSize="small"
                                            />
                                        )
                                    ) : (
                                        <PrimaryButton
                                            label="Login to Bid"
                                            btnSize="small"
                                            onClick={() => handleRedirectInternal(history, 'login')}
                                        />
                                    )}
                                </div>
                            )}
                        </form>
                    ) : null}
                </>
            ) : null}
        </>
    )
}

export default Bidding
