//LOGO
export const logo = '/assets/svg/logo.svg'

export const testimonial = [
    {
        img_url:
            'https://p1.liveauctioneers.com/6607/226850/117764393_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Photos were exactly the items I bid on. Description matched the items when I got them. I wouldn’t hesitate to bid on their items again. And ZERO percent buyer’s premium? Where you gonna get that now?',
        short_msg: 'Bid With Confidence',
        userName: 'Joe',
        location: 'Vernon, CT',
        date: 'Dec 22, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/226850/118027881_1_x.jpg?height=94&quality=70&version=1',
        message:
            'I love all of the coins that I purchased but you really have to take a good look at what you are bidding on, I was not aware that all coins do not have dates on them but I got what I bid on and I am very happy. Living and learning',
        short_msg:
            'I am very new to coin collecting and I was a little taken aback that not all coins have dates',
        userName: 'Ronald',
        location: 'Daytona Beach, FL',
        date: 'Dec 13, 2021',
        stars: '4',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/226850/118147729_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'glenn',
        location: 'Denver, CO',
        date: 'Dec 13, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/226850/118147730_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Andre',
        location: 'Mcminnville, OR',
        date: 'Dec 12, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/226850/117666514_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Johanna',
        location: 'Philadelphia, PA',
        date: 'Dec 12, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/226850/117764364_1_x.jpg?height=94&quality=70&version=1',
        message:
            'All auction houses could learn a thing or two from these guys. Item always what they described!',
        short_msg: 'Great service!',
        userName: 'Payless',
        location: 'Lake Worth, FL',
        date: 'Dec 11, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/226850/118147737_1_x.jpg?height=94&quality=70&version=1',
        message:
            'This auction house is absolutely legit. Great service and by far the most care with packaging. The shipping was excellent.',
        short_msg: 'Great service',
        userName: 'Matthew',
        location: 'Sanford, FL',
        date: 'Dec 10, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/224343/116280379_1_x.jpg?height=94&quality=70&version=1',
        message: 'Easy to deal with and the price is always right.',
        short_msg: 'Great auction house!',
        userName: 'Streeter',
        location: 'Montgomery, Alabama',
        date: 'Dec 08, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/224343/116075366_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'James',
        location: 'Whittier, CA',
        date: 'Dec 08, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/222430/115413253_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Andre',
        location: 'Mcminnville, OR',
        date: 'Dec 01, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/224343/117022745_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'glenn',
        location: 'Denver, CO',
        date: 'Nov 29, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/224343/117022710_1_x.jpg?height=94&quality=70&version=1',
        message: 'Great auction! Can’t wait until they have another auction.',
        short_msg: 'No fees. Free shipping!',
        userName: 'Larry',
        location: 'Monroe, Louisiana',
        date: 'Nov 25, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/222430/115100831_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Dianne',
        location: 'Las Vegas, NV',
        date: 'Nov 17, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/222430/115101597_1_x.jpg?height=94&quality=70&version=1',
        message: 'Fast and easy transaction',
        short_msg: '5 star auction house',
        userName: 'Nickerson',
        location: 'Port St Lucie., Florida',
        date: 'Nov 17, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/222430/115408788_1_x.jpg?height=94&quality=70&version=1',
        message:
            'great items to bid on every auction. its fun to win the item that you want. cant wait to see what they have next time!',
        short_msg: 'Fastest delivery of any auction site that i have used.',
        userName: 'glenn',
        location: 'Denver, CO',
        date: 'Nov 03, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/216803/111863342_1_x.jpg?height=94&quality=70&version=1',
        message: 'Their items and shipping are always spot on. Can’t go higher than five stars.',
        short_msg: 'Great as usual',
        userName: 'Gregory',
        location: 'Metuchen, New Jersey',
        date: 'Oct 20, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/219367/113412425_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'glenn',
        location: 'Denver, CO',
        date: 'Oct 20, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/216803/111789764_1_x.jpg?height=94&quality=70&version=1',
        message: 'Items, auctions, shipping and overall service first class',
        short_msg: 'Great Auctions',
        userName: 'Joseph',
        location: 'Snowshoe, WV',
        date: 'Oct 08, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/216803/112175349_1_x.jpg?height=94&quality=70&version=1',
        message: 'Very helpful and courteous with any questions and service.',
        short_msg: 'Good People!',
        userName: 'Troy',
        location: 'Auburn, CA',
        date: 'Oct 05, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/216803/111612448_1_x.jpg?height=94&quality=70&version=1',
        message:
            'I think they are the best shop on the west coast. I have been all over the US and there Auctions are the best. They always have fast shipping and packing is tight. Thank you and I hope to be buying from you for many more years.',
        short_msg: 'A + + + The best on the west coast.',
        userName: 'CASEY',
        location: 'Wood Village, OR',
        date: 'Oct 05, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/215274/110354182_1_x.jpg?height=94&quality=70&version=1',
        message:
            'I had been out of coin collecting for may years, I dropped in with questions and got great answers to get back into collecting and they recommended trying an auction. Thi was my first auction.',
        short_msg: 'Very Helpful',
        userName: 'Monty',
        location: 'Rigefield, WA',
        date: 'Oct 05, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/216803/112073454_1_x.jpg?height=94&quality=70&version=1',
        message:
            'If I get hit by a bus and they can’t put all the pieces back together again, I’ve told my wife to send all my coins (which she believes is only a couple thousand dollars worth;)) to Ken to auction off. She’ll be in for a surprise lol. When people ask me where I get good coins from, I tell them "I got a guy". Ken and Coins Since 1994 are where it’s at!',
        short_msg: 'My "Go To" auction house',
        userName: 'chris',
        location: 'Seattle, Washington',
        date: 'Oct 05, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/216803/111143952_1_x.jpg?height=94&quality=70&version=1',
        message: 'great product, great price.',
        short_msg: 'great product, great price',
        userName: 'Cresent',
        location: 'Scarborough, CA',
        date: 'Oct 01, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/216803/111801572_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Michael',
        location: 'Mingo, Iowa',
        date: 'Sep 23, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/212029/109130770_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Michael',
        location: 'Mingo, Iowa',
        date: 'Sep 19, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/215274/110578210_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'James',
        location: 'Whittier, CA',
        date: 'Sep 15, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/215274/110572674_1_x.jpg?height=94&quality=70&version=1',
        message:
            'All 6 lots have arrived in Germany. Thank you so much. Your service is perfect. Fast and safe shipping. I know what I’m talking about. I bought thousands of items in up to fourty auction houses. But your one of the best on this platform. I’m happy that I discovered you in this jungle. And I’m looking forward to take part in other auctions you’re planning. Sincerly Thomas',
        short_msg: 'One of the best on this platform',
        userName: 'Thomas',
        location: 'Buda, TX',
        date: 'Sep 15, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/188080/94949872_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Tyler',
        location: 'Fountain Valley, CA',
        date: 'Jan 06, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/188080/94989843_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'michael',
        location: 'Thorne Bay, Alaska',
        date: 'Jan 06, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/188080/94610490_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Daniel',
        location: 'Palm Springs, CA',
        date: 'Jan 06, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/188080/94700401_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Dale',
        location: 'Garden Grove, CA',
        date: 'Jan 06, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Patrick',
        location: 'Castle Rock, Washington',
        date: 'Dec 25, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Lane',
        location: 'Pollock Pines, California',
        date: 'Dec 24, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'David',
        location: 'New Providence, New Jersey',
        date: 'Dec 23, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/186088/93565437_1_x.jpg?height=94&quality=70&version=1',
        message: 'Wonderful experience and great items received',
        short_msg: 'Excellent',
        userName: 'Mary',
        location: 'Kenmore, New York',
        date: 'Dec 23, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/186088/94139190_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Steve',
        location: 'Casper, Wyoming',
        date: 'Dec 23, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/186088/93565435_1_x.jpg?height=94&quality=70&version=1',
        message:
            'No B. S. Honest, straightforward and Quality!!! I highly recommend Coins and Auctions Since 1994!!!!!!!!!',
        short_msg:
            'The Best Experience I have had by far since bidding for items on your platform!!!',
        userName: 'John',
        location: 'Cape Elizabeth, ME',
        date: 'Dec 22, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/186088/93565407_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Wendy',
        location: 'Palatine, Illinois',
        date: 'Dec 22, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/186088/93565380_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Stephen',
        location: 'San Antonio, TX',
        date: 'Dec 22, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/186088/93565487_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'William',
        location: 'Wenonah, New Jersey',
        date: 'Dec 22, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/186088/94008427_1_x.jpg?height=94&quality=70&version=1',
        message:
            'David was a pleasure to deal with. Answered all questions In detail. Package was shipped on time and well organized.',
        short_msg: 'Detailed shipping instructions',
        userName: 'David',
        location: 'Lebanon, New Jersey',
        date: 'Dec 22, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/186088/93849819_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Sam',
        location: 'Helotes, Texas',
        date: 'Dec 22, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/186088/93917638_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Received my purchase very quickly I would definitely bid with coins and auctions again',
        short_msg: 'Great people great product',
        userName: 'Mark',
        location: 'Portland, Oregon',
        date: 'Dec 22, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/186088/94036606_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Daniel',
        location: 'Vancouver, Washington',
        date: 'Dec 22, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/186088/94009191_1_x.jpg?height=94&quality=70&version=1',
        message: 'Top Notch All the WAY!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!',
        short_msg: 'Top Notch All the WAY!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!',
        userName: 'Lane',
        location: 'Pollock Pines, California',
        date: 'Dec 22, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/181768/92159781_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Steve',
        location: 'Casper, Wyoming',
        date: 'Dec 10, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message: 'They have always given me the VIP treatment.',
        short_msg: 'From soup to nuts!',
        userName: 'chris',
        location: 'Seattle, Washington',
        date: 'Nov 26, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/181768/92420490_1_x.jpg?height=94&quality=70&version=1',
        message: 'as first time buyer good experience',
        short_msg: 'helpfull',
        userName: 'don',
        location: 'Sutherlin, OR',
        date: 'Nov 24, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/181768/92150465_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'michael',
        location: 'Thorne Bay, Alaska',
        date: 'Nov 23, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/181768/92420481_1_x.jpg?height=94&quality=70&version=1',
        message: 'would bid with confidence again',
        short_msg: 'great auction',
        userName: 'Frank',
        location: 'Hampstead, North Carolina',
        date: 'Nov 23, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/181768/92476738_1_x.jpg?height=94&quality=70&version=1',
        message: 'Have bid with this auction house many times, always quality coins!',
        short_msg: 'Excellent service and coins!',
        userName: 'Mark',
        location: 'Jasper, GA',
        date: 'Nov 23, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/181768/92489884_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'John',
        location: 'Lancaster, PA',
        date: 'Nov 23, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/181768/92420497_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Phillip',
        location: 'Lafollette, Tennessee',
        date: 'Nov 23, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/181768/92476211_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Anita',
        location: 'Tonawanda, New York',
        date: 'Nov 23, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Mark',
        location: 'Jasper, GA',
        date: 'Nov 12, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/179380/90657715_1_x.jpg?height=94&quality=70&version=1',
        message: 'Thanks; good transaction',
        short_msg: 'Nice coin, fair price.',
        userName: 'Frank',
        location: 'Newmarket, CA',
        date: 'Nov 01, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message: 'Another Great Auction. Coins Since 1994 is the best.',
        short_msg: 'Another Great Auction',
        userName: 'Jimmy',
        location: 'Lumberton, Texas',
        date: 'Oct 22, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Michael',
        location: 'Mingo, Iowa',
        date: 'Oct 18, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message: '# 1.',
        short_msg: 'Great service. Items were as described.',
        userName: 'Gregory',
        location: 'Metuchen, New Jersey',
        date: 'Oct 12, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/215274/110354201_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'glenn',
        location: 'Denver, CO',
        date: 'Sep 13, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/212029/108636534_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Richard',
        location: 'Birdsboro, Pennsylvania',
        date: 'Sep 10, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/215274/110573078_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Steve',
        location: 'Casper, Wyoming',
        date: 'Sep 03, 2021',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6607/215274/110354261_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Robert',
        location: 'Oregon City, OR',
        date: 'Sep 02, 2021',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6607/212029/109395950_1_x.jpg?height=94&quality=70&version=1',
        message: 'They reached out to me and made the situation right with me.',
        short_msg: 'Last Experience Not as Good as Prior Experiences',
        userName: 'Renato',
        location: 'Elk Grove, CA',
        date: 'Aug 31, 2021',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6607/212029/108277556_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Robert',
        location: 'Oregon City, OR',
        date: 'Aug 31, 2021',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6607/212029/109560911_1_x.jpg?height=94&quality=70&version=1',
        message:
            'After a slight mix up in shipping Ken went the extra mile to straighten everything out... A-1',
        short_msg: 'Ken is great to deal with',
        userName: 'Nickerson',
        location: 'Port St Lucie., Florida',
        date: 'Aug 31, 2021',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6607/212029/109560911_1_x.jpg?height=94&quality=70&version=1',
        message: 'Have been dealing with C/94 for quite a few years and haven’t had any problems.',
        short_msg: 'Great selection of items.',
        userName: 'Gregory',
        location: 'Metuchen, New Jersey',
        date: 'Aug 26, 2021',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6607/212029/108754200_1_x.jpg?height=94&quality=70&version=1',
        message: 'All Good! A + thanks!',
        short_msg: 'Beautiful Coin Well Wrapped',
        userName: 'Laurie',
        location: 'Sudbury, Massachusetts',
        date: 'Aug 25, 2021',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6607/206357/104889630_1_x.jpg?height=94&quality=70&version=1',
        message: 'great product, great price',
        short_msg: 'service',
        userName: 'Cresent',
        location: 'Scarborough, CA',
        date: 'Aug 19, 2021',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6607/212029/109221140_1_x.jpg?height=94&quality=70&version=1',
        message:
            'I bought from these guys before. I want to apologize for my previous review. You work hard to get good credit so you can make card purchases. Never thought about all the times they got stiffed, The manager took time to call me to explain their reluctance towards credit card payments. Needless to say we worked it out got great ms 70 coins just like described. Will buy again …. Ty!',
        short_msg: 'Always Great Coins!',
        userName: 'Payless',
        location: 'Lake Worth, FL',
        date: 'Aug 19, 2021',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6607/212029/109221140_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Edward',
        location: 'Larksville, Pennsylvania',
        date: 'Aug 04, 2021',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6607/206357/106156737_1_x.jpg?height=94&quality=70&version=1',
        message:
            'I’ve been buying coins for 2 decades, and have dealt with dozens of Auction Houses. Ken and his team are the fairest, and offer top-notch customer service coupled with a super-reasonable buyers premium. Bid on items at the next auction and you will not be disappointed!',
        short_msg: 'None Better',
        userName: 'Eric',
        location: 'Omaha, NE',
        date: 'Aug 03, 2021',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6607/209083/107543303_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Items purchased were received quickly, in good condition, and as they were described in the listings. Thank you.',
        short_msg: 'Easy & convenient transaction.',
        userName: 'James',
        location: 'Walterville, OR',
        date: 'Aug 03, 2021',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6607/209083/106396048_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Very good experience. Recommendable, no bull ****. Straight forward beginning to end. You have my bid!!!',
        short_msg: 'Get what you pay for!',
        userName: 'Joseph',
        location: 'Holbrook, MA',
        date: 'Jul 27, 2021',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6607/209083/107543297_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Tommy',
        location: 'Norristown, PA',
        date: 'Jul 20, 2021',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6607/201701/102206040_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Items are as described. Low premiums. Fast delivery. I’ve purchased quite a bit of items from C94 haven’t had any complaints or problems.',
        short_msg: 'Great experience no problems.',
        userName: 'Gregory',
        location: 'Metuchen, New Jersey',
        date: 'Jul 14, 2021',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6607/206357/105987024_1_x.jpg?height=94&quality=70&version=1',
        message: 'Very nice coins and service!',
        short_msg: 'coins "as advertise:.',
        userName: 'Richard',
        location: 'Olympia, WA',
        date: 'Jul 08, 2021',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6607/204222/105110305_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Richard',
        location: 'Sioux Falls, South Dakota',
        date: 'Jul 07, 2021',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6607/204222/104297167_1_x.jpg?height=94&quality=70&version=1',
        message: ' ',
        short_msg: ' ',
        userName: 'Cresent',
        location: 'Scarborough, CA',
        date: 'Jul 04, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/206357/105987041_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Beautiful items! cant wait for a chance to get more of the 1/4 oz. indian head silver rounds. the 1 oz. coins were great too!',
        short_msg: 'superb items. cant wait for next auction!',
        userName: 'glenn',
        location: 'Denver, CO',
        date: 'Jun 29, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/204222/103538011_1_x.jpg?height=94&quality=70&version=1',
        message: ' ',
        short_msg: ' ',
        userName: 'Eric',
        location: 'Silver City, NM',
        date: 'Jun 26, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: 'These guys try to be a cut above and it shows!',
        short_msg: 'Very customer-oriented',
        userName: 'Amy',
        location: 'Juneau, Alaska',
        date: 'Jun 25, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/204222/104412659_1_x.jpg?height=94&quality=70&version=1',
        message:
            'This is a great organization that is a stand up organization that delivers as promised in a timely fashion.',
        short_msg: 'This organization is fast and efficient.',
        userName: 'Donn',
        location: 'Hillsboro, MO',
        date: 'Jun 24, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: ' ',
        short_msg: ' ',
        userName: 'Robert',
        location: 'Oregon City, OR',
        date: 'Jun 03, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/179380/90655713_1_x.jpg?height=94&quality=70&version=1',
        message: ' ',
        short_msg: ' ',
        userName: 'Robert',
        location: 'Oregon City, OR',
        date: 'Jun 03, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/201701/102205966_1_x.jpg?height=94&quality=70&version=1',
        message: ' ',
        short_msg: ' ',
        userName: 'Donald',
        location: 'New Bern, North Carolina',
        date: 'Jun 01, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: ' ',
        short_msg: ' ',
        userName: 'Robin',
        location: 'Dallas, Texas',
        date: 'May 28, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: ' ',
        short_msg: ' ',
        userName: 'don',
        location: 'Sutherlin, OR',
        date: 'May 26, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: 'very helpful if needed',
        short_msg: 'no reserve other than low starting bid',
        userName: 'don',
        location: 'Sutherlin, OR',
        date: 'Apr 22, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: 'great service',
        short_msg: 'acceptable premiums',
        userName: 'nick',
        location: 'Mokena, IL',
        date: 'Apr 20, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: ' ',
        short_msg: ' ',
        userName: 'JAMES',
        location: 'Dry Ridge, KY',
        date: 'Apr 20, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: ' ',
        short_msg: ' ',
        userName: 'Donald',
        location: 'New Bern, North Carolina',
        date: 'Apr 20, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: ' ',
        short_msg: ' ',
        userName: 'Andre',
        location: 'Mcminnville, OR',
        date: 'Apr 20, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: ' ',
        short_msg: ' ',
        userName: 'John',
        location: 'Lancaster, Pennsylvania',
        date: 'Apr 20, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message:
            'I bought coins from Chevrolet auction sites and coins and auctions since 1994 is by far the best auction house out there',
        short_msg: 'The best auction house out there for coins',
        userName: 'Mark',
        location: 'Portland, Oregon',
        date: 'Apr 20, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: ' ',
        short_msg: ' ',
        userName: 'Albert',
        location: 'Vancouver, Washington',
        date: 'Apr 20, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: ' ',
        short_msg: ' ',
        userName: 'don',
        location: 'Sutherlin, OR',
        date: 'Mar 19, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: ' ',
        short_msg: ' ',
        userName: 'Jeff',
        location: 'Mineral, Virginia',
        date: 'Mar 17, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message:
            'Honest, competitive, on time, knowledgeable, years in business, good selection and availability, class act',
        short_msg: 'LCD 👍',
        userName: 'Adelbert',
        location: 'Vancouver, Washington',
        date: 'Mar 17, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: '# 1.',
        short_msg: 'Great service. Items were as described.',
        userName: 'Gregory',
        location: 'Metuchen, New Jersey',
        date: 'Oct 12, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message: 'Top notch operation',
        short_msg: 'A +',
        userName: 'JAMES',
        location: 'Dry Ridge, KY',
        date: 'Aug 14, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message: 'fast shipping, coin packed with care',
        short_msg: 'another good experience',
        userName: 'Michael',
        location: 'Mingo, Iowa',
        date: 'Jul 31, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message: 'fast shipping, coins packed with a lot of care',
        short_msg: 'another outstanding experience',
        userName: 'Michael',
        location: 'Mingo, Iowa',
        date: 'Jul 31, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Robert',
        location: 'Oregon City, OR',
        date: 'Jul 25, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message:
            'Based on this singular experience it’s hard to endorse or condemn. But this one experience was as professional and satisfying as any I’ve ever had and everyone was exceptionally nice and the transaction exactly as promised.',
        short_msg: 'Class operation',
        userName: 'Jeffrey',
        location: 'New York, New York',
        date: 'Jul 22, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message: 'Great',
        short_msg: 'Nice Coins',
        userName: 'Denny',
        location: 'Bremen, Georgia',
        date: 'Jul 21, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message:
            'Huge auction and had all the things that I’m collecting. They always seem to auction off the best stuff. From Morgans to Gold, it blows me away that it’s always the items I’m searching for. I’ll be back for the next one!',
        short_msg: 'Yep, Happy, Happy, Happy',
        userName: 'chris',
        location: 'Seattle, Washington',
        date: 'Jul 21, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message:
            'Been doing this awhile now. Ken and Tiffany go out of their way to make every auction run smooth like butter. From start to finish, they always take good care of me.',
        short_msg: 'Buy! Buy! Buy! Don’t hesitate with this crew. Top notch!',
        userName: 'chris',
        location: 'Seattle, Washington',
        date: 'Jul 21, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message:
            'Great service and fast shipping with care. Good people to deal with!! Will buy again. Thank You. A + + + Seller and 5 stars *****',
        short_msg: 'Buying coins.',
        userName: 'Peter',
        location: 'New Milford, United States Minor Outlying Islands',
        date: 'Jul 21, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Carlyle',
        location: 'Powellsville, North Carolina',
        date: 'Jul 21, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message:
            'I’ve enjoyed collecting coins and silver and was pleased to meet Ken and his team! They have been excellent to work with and always have what I’m looking for at the right price. The speed of service and the great options I have with this shop is why I will be a customer for file Helpful Report',
        short_msg: 'Shop here for file!',
        userName: 'Nicholas',
        location: 'Bonners Ferry, ID',
        date: 'Jul 21, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message:
            'This has been the best experience for auction and purchase of coins! I love working with the team here, and they have always made sure to get competitive prices and the best service in the PDX area!',
        short_msg: 'The best place to buy',
        userName: 'Nicholas',
        location: 'Bonners Ferry, ID',
        date: 'Jul 21, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message: 'very good auction house willing to go the extra step in helping their customers.',
        short_msg: 'very helpful',
        userName: 'Bruce',
        location: 'Buffalo, Wyoming',
        date: 'Mar 09, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message:
            'I guess I’d think of myself as semi-pro when it comes to auctions and coins. I’ve been doing this quite awhile. Coins Since 1994 has become by go to auction for a couple reasons. First and foremost, they get great stuff! Ken seems to find consignors with top notch coins and currency. Second, they actually take decent photos. That is one of my pet peeves about auction houses. When there isn’t a good photo, I’m likely to not even bid. Ken’s crew takes high resolution, detailed photos and you can see exactly what you’ll be getting. It’s much appreciated. One more point... I’ve spent a few bucks at this auction house and over that time they’ve taken the time to get to know me and treat me like a valued customer. That I appreciate most. Chris',
        short_msg: 'Absolutely Fantastic Auction Company!',
        userName: 'chris',
        location: 'Seattle, Washington',
        date: 'Mar 09, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message: 'Awesome guys to work with.',
        short_msg: 'Awesome folks.',
        userName: 'S',
        location: 'Portland, Oregon',
        date: 'Mar 09, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'James',
        location: 'Whittier, CA',
        date: 'Mar 09, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message: 'CAN’T ASK FOR A MORE PERSONABLE AUCTION HOUSE',
        short_msg: 'JUST LIKE FAMILY',
        userName: 'Robert',
        location: 'Levittown, Pennsylvania',
        date: 'Feb 20, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/153683/77887225_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Great communication and the company went out of their way to resend items to me (they corrected a postal service error, not their error). Very satisfied with overall service (packing etc) and no hesitation to be a repeat customer.',
        short_msg: 'Customer service',
        userName: 'Paul',
        location: 'Iqaluit, Canada',
        date: 'Feb 20, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/156192/79053834_1_x.jpg?height=94&quality=70&version=1',
        message:
            'I purchased these Peace dollars from Coins & Auctions Since 1994. I received my items quickly and the coins look even better in person!! Great auction house to buy from! I will be back to purchase more coins for my collection! Highly Recommended!!',
        short_msg: 'Best Auction House!!',
        userName: 'Hoang',
        location: 'Vancouver, Washington',
        date: 'Feb 19, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message:
            'The coins I purchased were exactly as represented in the photos and description. Looking forward to doing business with Coins and Auctions in the future.',
        short_msg: 'Great service',
        userName: 'Randy',
        location: 'Phoenix, Arizona',
        date: 'Feb 15, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message:
            'yes good to work with... will be doing more down the road... thanks again for the coins',
        short_msg: 'Work great with them',
        userName: 'Joseph',
        location: 'Kalamazoo, MI',
        date: 'Feb 11, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Brett',
        location: 'Germantown, MD',
        date: 'Feb 10, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'James',
        location: 'Whittier, CA',
        date: 'Feb 10, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message:
            'Received everything in timely fashion. Shipping costs were reasonable. Items as described. That’s all you can ask.',
        short_msg: 'As described items. Reasonable shippingng',
        userName: 'William',
        location: 'Wenonah, New Jersey',
        date: 'Feb 10, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message: 'very fair and honest auction house. will deal with again. 5 ******',
        short_msg: 'Wonderful to deal with, will buy from them again',
        userName: 'John',
        location: 'Warren, Ohio',
        date: 'Feb 10, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Mark',
        location: 'Jasper, GA',
        date: 'Feb 10, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message:
            'I have been in Contact with Coins and Auctions several times!!! The amount of Knowledge, and dedication they provide has helped me become a stronger and more knowledgeable coin collector! Thanks for the help! My questions were answered in a timely manner, and Through the knowledge I have gained, I now am sending my own purchased coins out for Grading! I am more confident about buying coins, and Their Auctions are The best, with the lowest buyers premium of any Live auction on the Market! Thanks Ken!!!! I highly recommend Coins and Auction since 1994 if your a beginner buyer or a more experienced coin buyer, they have something for everybody’s budget. Thanks Coins and Auction since 1994!!!!!',
        short_msg: 'If you need help... Call them!!!',
        userName: 'Mark',
        location: 'Angola, New York',
        date: 'Feb 10, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message: 'Great friendly service',
        short_msg: 'Great friendly service',
        userName: 'Willard',
        location: 'Oakland, California',
        date: 'Feb 10, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Richard',
        location: '1ndian Harbor Beach, Florida',
        date: 'Feb 10, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message: 'Accurate description and quick shipping, thank you',
        short_msg: 'Fast shipping',
        userName: 'Terry',
        location: 'Lansing, Michigan',
        date: 'Feb 10, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Don',
        location: 'Yachats, Oregon',
        date: 'Feb 10, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/151221/76508287_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Richard',
        location: 'Sioux Falls, South Dakota',
        date: 'Jan 21, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/155816/78628122_1_x.jpg?height=94&quality=70&version=1',
        message: 'Wow, great experience. I will go through them again!',
        short_msg: 'Excellent Auction House',
        userName: 'Kristen',
        location: 'Orinda, California',
        date: 'Jan 03, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/156192/79184499_1_x.jpg?height=94&quality=70&version=1',
        message:
            'This company has helped me find and get items finish collections, always there for you. Excellent staff, and owner!',
        short_msg: 'Willingness to help',
        userName: 'Tim',
        location: 'Washougal, WA',
        date: 'Jan 03, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/156192/79184501_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Mark',
        location: 'Jasper, GA',
        date: 'Jan 03, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message: 'pleasure to work with',
        short_msg: 'very down to earth, easy to talk to',
        userName: 'Dennis',
        location: 'Ephrata, Pennsylvania',
        date: 'Dec 31, 2019',
        stars: '5',
    },

    {
        img_url: '',
        message:
            'Great auction site and very fast shipping! thank you. also great coins! thank you.',
        short_msg: 'great auction site fast shipping!',
        userName: 'Allen',
        location: 'Meridian, Idaho',
        date: 'Dec 31, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/156192/79064099_1_x.jpg?height=94&quality=70&version=1',
        message: 'Fair Price. Fair shipping. Good service',
        short_msg: 'Fair Price. Fair shipping. Good service',
        userName: 'William',
        location: 'Wenonah, New Jersey',
        date: 'Dec 30, 2019 ',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/155816/79117951_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Chaya',
        location: 'Cary, North Carolina',
        date: 'Dec 30, 2019 ',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/155816/78744891_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Mark',
        location: 'Angola, New York',
        date: 'Dec 30, 2019 ',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/156192/79017565_1_x.jpg?height=94&quality=70&version=1',
        message:
            'in my opinion coins and auctions is top 5 on liveauctioneers because of the professional team they have and organized system... I just wished they would set live auctions more often',
        short_msg: 'excellent dealer',
        userName: '',
        location: '',
        date: 'Dec 30, 2019 ',
        stars: '5',
    },

    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'James',
        location: 'Whittier, CA',
        date: 'Dec 22, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/153123/78045567_1_x.jpg?height=94&quality=70&version=1',
        message: 'quality products',
        short_msg: 'reliable company',
        userName: 'James',
        location: 'Dry Ridge, KY',
        date: 'Dec 03, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/153683/77887214_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Geoffrey',
        location: 'New York, New York',
        date: 'Dec 03, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/153123/77980025_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Steve',
        location: 'Casper, Wyoming',
        date: 'Dec 02, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/153683/77887226_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Hakop',
        location: 'Los Angeles, California',
        date: 'Dec 02, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/153123/78015216_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'James',
        location: 'Whittier, CA',
        date: 'Dec 02, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/151221/76508238_1_x.jpg?height=94&quality=70&version=1',
        message: 'Just perfect and as expected.',
        short_msg: 'Excellent auctioneers',
        userName: 'Ronald',
        location: 'Chico, CA',
        date: 'Nov 05, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/151221/76901453_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'S',
        location: 'Portland, Oregon',
        date: 'Nov 05, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/151221/76508257_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Arthur',
        location: 'Beach Lake, PA',
        date: 'Nov 04, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/151221/76864964_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Ken was great. His prices were fair and he is very knowledgeable. I am looking forward to seeing him soon. Major',
        short_msg: 'They were great',
        userName: 'Rick',
        location: 'Vancouver, Washington',
        date: 'Nov 04, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/151221/76901396_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Mark',
        location: 'Angola, New York',
        date: 'Nov 04, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/151221/76864951_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Stuart',
        location: 'Miami, FL',
        date: 'Nov 04, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/151221/76474940_1_x.jpg?height=94&quality=70&version=1',
        message: 'This company goes above and beyond to help you!',
        short_msg: 'Great company!',
        userName: 'Tim',
        location: 'Washougal, WA',
        date: 'Nov 04, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/151221/76864948_1_x.jpg?height=94&quality=70&version=1',
        message: 'Great auction, great quality',
        short_msg: 'Right info',
        userName: 'Greg',
        location: 'Farmersburg, Indiana',
        date: 'Oct 23, 2019',
        stars: '5',
    },

    {
        img_url: '',
        message: 'I am a very happy customer',
        short_msg: 'I am a happy customer',
        userName: 'Hallping',
        location: 'Flushing, New York',
        date: 'Oct 08, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/148920/75840374_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Steve',
        location: 'Casper, Wyoming',
        date: 'Oct 08, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/148920/75829955_1_x.jpg?height=94&quality=70&version=1',
        message:
            'real good auction house to work with... thanks for all you done.. will be doing more coins down the road... thanks again',
        short_msg: 'real good auction house to work with',
        userName: 'Joseph',
        location: 'Kalamazoo, MI',
        date: 'Oct 08, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/148920/75829966_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Dean',
        location: 'Saint Paul, MN',
        date: 'Oct 07, 2019',
        stars: '5',
    },

    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Lewis',
        location: 'Bonita Springs, Florida',
        date: 'Oct 07, 2019',
        stars: '5',
    },

    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Steve',
        location: 'Casper, Wyoming',
        date: 'Aug 29, 2019',
        stars: '5',
    },

    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Jean-Haulis',
        location: 'Ste-anne-de-bellevue, Canada',
        date: 'Aug 29, 2019',
        stars: '5',
    },

    {
        img_url: '',
        message:
            'No issues at all. The owner called about an item after the auction. Very personalble and honest.',
        short_msg: 'A Business You can Trust',
        userName: 'Michael',
        location: 'Saddlebrooke, Arizona',
        date: 'Aug 27, 2019',
        stars: '5',
    },

    {
        img_url: '',
        message:
            'I did not buy a lot but you where prompted and did what you said you where going to do. Dan',
        short_msg: 'Letting me know right away what I owe',
        userName: 'Dan',
        location: 'Houston, Texas',
        date: 'Aug 26, 2019',
        stars: '5',
    },

    {
        img_url: '',
        message: 'Very well packaged Good seller',
        short_msg: 'Vendor worth recommending',
        userName: 'Bogdan',
        location: 'Oswego, IL',
        date: 'Aug 26, 2019',
        stars: '5',
    },

    {
        img_url: '',
        message:
            'I kept getting invoiced from them and I didn’t know why, so I called and told them I hadn’t purchased anything from them. They took me at my word, no questions asked, apologized for the inconvenience, and deleted the invoice. However, why this happened kept bugging me, so I decided to do some research. I came to Live Auctioneers, and just for sh * ts and giggles, I clicked the "Won Lots" link. And there was an autographed baseball. I remembered bidding on it, but I have no recollection of bidding the winning amount. So I called them back, apologized profusely, we had a good laugh over it, and they re-invoiced me, again no questions asked, and I have the ball. Dave over there was a pleasure to deal with and gets five ***** for his handling of it.',
        short_msg: 'Willing to work with you.',
        userName: 'Michael',
        location: 'Dover, New Hampshire',
        date: 'Aug 26, 2019',
        stars: '5',
    },

    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Arleen',
        location: 'Brecksville, Ohio',
        date: 'Aug 22, 2019',
        stars: '5',
    },

    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'James',
        location: 'Allenwood, New Jersey',
        date: 'Jul 26, 2019',
        stars: '5',
    },

    {
        img_url: '',
        message:
            'Gold! Silver! Ancients! They seem to get the best of the best for auction. One of my favorite places to stock up on the coins I collect.',
        short_msg: 'Favorite place for sweet coins!',
        userName: 'chris',
        location: 'Seattle, Washington',
        date: 'Jul 25, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/144649/73256864_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Donald',
        location: 'Dallas, Texas',
        date: 'Jul 24, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/144649/73256864_1_x.jpg?height=94&quality=70&version=1',
        message: 'Give them chance, you will be pleasantly surprized',
        short_msg: 'Prompt, reputable auction house',
        userName: 'Terry',
        location: 'Berlin, Connecticut',
        date: 'Jul 22, 2019',
        stars: '5',
    },

    {
        img_url: '',
        message: ' ',
        short_msg: ' ',
        userName: 'James',
        location: 'Gresham, OR',
        date: 'Mar 17, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: ' ',
        short_msg: ' ',
        userName: 'Andre',
        location: 'Mcminnville, OR',
        date: 'Mar 16, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: ' ',
        short_msg: ' ',
        userName: 'Steve',
        location: 'Casper, Wyoming',
        date: 'Mar 16, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: 'good service easy to work with',
        short_msg: 'cost is important very marketable',
        userName: 'nick',
        location: 'Mokena, IL',
        date: 'Mar 16, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: 'Accurate and easy follow thru',
        short_msg: 'Accurate as described',
        userName: 'Nickerson',
        location: 'Port St Lucie., Florida',
        date: 'Mar 16, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: ' ',
        short_msg: ' ',
        userName: 'John',
        location: 'Lancaster, PA',
        date: 'Mar 16, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: ' ',
        short_msg: ' ',
        userName: 'Alber',
        location: 'Pompano Beach, Florida',
        date: 'Mar 16, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/190797/97126137_1_x.jpg?height=94&quality=70&version=1',
        message: 'every thing went well',
        short_msg: 'smooth operation',
        userName: 'nick',
        location: 'Mokena, IL',
        date: 'Feb 23, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/190797/96516958_1_x.jpg?height=94&quality=70&version=1',
        message: ' ',
        short_msg: ' ',
        userName: 'David',
        location: 'Virginia Beach, Virginia',
        date: 'Feb 17, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/190797/96993442_1_x.jpg?height=94&quality=70&version=1',
        message: ' ',
        short_msg: ' ',
        userName: 'Steve',
        location: 'Casper, Wyoming',
        date: 'Feb 17, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/190797/96808871_1_x.jpg?height=94&quality=70&version=1',
        message: ' ',
        short_msg: ' ',
        userName: 'William',
        location: 'Wenonah, New Jersey',
        date: 'Feb 17, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/190797/96500495_1_x.jpg?height=94&quality=70&version=1',
        message:
            'They are quick and gracious with a thank you and informative about the payment and delivery.',
        short_msg: 'Honest and reliable',
        userName: 'Adelbert',
        location: 'Austin, Texas',
        date: 'Feb 16, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/190797/96777724_1_x.jpg?height=94&quality=70&version=1',
        message: ' ',
        short_msg: ' ',
        userName: 'Daniel',
        location: 'Palm Springs, CA',
        date: 'Feb 16, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/190797/96991988_1_x.jpg?height=94&quality=70&version=1',
        message: ' ',
        short_msg: ' ',
        userName: 'John',
        location: 'Lancaster, PA',
        date: 'Feb 16, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/190797/96993435_1_x.jpg?height=94&quality=70&version=1',
        message: ' ',
        short_msg: ' ',
        userName: 'Alber',
        location: 'Pompano Beach, Florida',
        date: 'Feb 16, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/190797/96777727_1_x.jpg?height=94&quality=70&version=1',
        message: 'from start to finish',
        short_msg: 'great experience',
        userName: 'Renato',
        location: 'Elk Grove, CA',
        date: 'Feb 16, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/190797/96517023_1_x.jpg?height=94&quality=70&version=1',
        message: ' ',
        short_msg: ' ',
        userName: 'JAMES',
        location: 'Dry Ridge, KY',
        date: 'Feb 16, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/190797/97147791_1_x.jpg?height=94&quality=70&version=1',
        message: ' ',
        short_msg: ' ',
        userName: 'June',
        location: 'Greenwood, Nebraska',
        date: 'Feb 16, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/190797/97147791_1_x.jpg?height=94&quality=70&version=1',
        message: 'Great variation from low end to high. Everything is as described.',
        short_msg: 'Great selection and price.',
        userName: 'Gregory',
        location: 'Metuchen, New Jersey',
        date: 'Feb 16, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/179380/90612594_1_x.jpg?height=94&quality=70&version=1',
        message: ' ',
        short_msg: ' ',
        userName: 'Joe',
        location: 'Apple Valley, California',
        date: 'Jan 28, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/181768/91457732_1_x.jpg?height=94&quality=70&version=1',
        message: ' ',
        short_msg: ' ',
        userName: 'Joe',
        location: 'Apple Valley, California',
        date: 'Jan 22, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/188080/94944554_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Great variety of lots and great service. I highly recommend Coins and Auctions to collectors.',
        short_msg: 'Very pleased',
        userName: 'John',
        location: 'Lancaster, PA',
        date: 'Jan 19, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/188080/94944613_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Wonderful experience from end to end. Lots of choice products, good prices, easy to communicate with & just so nice + helpful. Not to mention: really FAST delivery!',
        short_msg: 'Great Experience & Communication.',
        userName: 'Brian',
        location: 'Topsfield, Massachusetts',
        date: 'Jan 10, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/186088/93565220_1_x.jpg?height=94&quality=70&version=1',
        message: ' ',
        short_msg: ' ',
        userName: 'JAMES',
        location: 'Dry Ridge, KY',
        date: 'Jan 08, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/188080/94610595_1_x.jpg?height=94&quality=70&version=1',
        message: ' ',
        short_msg: ' ',
        userName: 'JAMES',
        location: 'Dry Ridge, KY',
        date: 'Jan 08, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/188080/94935294_1_x.jpg?height=94&quality=70&version=1',
        message: ' ',
        short_msg: ' ',
        userName: 'Steve',
        location: 'Casper, Wyoming',
        date: 'Jan 07, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/188080/94995686_1_x.jpg?height=94&quality=70&version=1',
        message: 'Items were shipped to me within a week of my payment.',
        short_msg: 'All items accurately described.',
        userName: 'Lonnie',
        location: 'Lansing, Michigan',
        date: 'Jan 07, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/188080/94610585_1_x.jpg?height=94&quality=70&version=1',
        message:
            'best buyer rates, and all other concerns bidders have, many auctions your bidding against the auction house',
        short_msg: 'compared to other auction sites they are by far best in all categories',
        userName: 'don',
        location: 'Sutherlin, OR',
        date: 'Jan 06, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/188080/94949534_1_x.jpg?height=94&quality=70&version=1',
        message:
            'I would buy from these guys every time they have an auction on Live Auctioneer. They are by far the best coin sellers on this site.. By far....',
        short_msg: 'Love these guys. Great choice in coins, great premium.',
        userName: 'Charles',
        location: 'Dallas, TX',
        date: 'Jan 06, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: ' ',
        short_msg: ' ',
        userName: 'Ivan',
        location: 'Vancouver, Washington',
        date: 'Jul 21, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message: ' ',
        short_msg: ' ',
        userName: 'Fred',
        location: 'Wickenburg, Arizona',
        date: 'Jul 17, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message:
            'I’ve won two coins here, and the goods were in my hand faster then anyone else. I’ve had 3 phone conversations with two different people and they were both a learning expierence, the firet one I was told to get some books and learn. I did and am slowly learning. That was the best advice I could have gotten. Thanks 1994. John C.',
        short_msg: 'Doesn’t get much better',
        userName: 'John',
        location: 'Canby, Oregon',
        date: 'Jul 14, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message: ' ',
        short_msg: ' ',
        userName: 'John Carlos',
        location: 'Lombard, IL',
        date: 'Jul 13, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message: ' ',
        short_msg: ' ',
        userName: 'Elliot',
        location: 'Tokeland, Washington',
        date: 'Jul 13, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message:
            'Hey, you can’t go wrong with these guys and gals. Top notch crew. I know them pretty well at this point after a dozen or so auctions, and for some reason I keep coming back. So that should tell you they are the best in the biz! Chris',
        short_msg: 'Buy! Buy! Buy!',
        userName: 'chris',
        location: 'Seattle, Washington',
        date: 'Jul 13, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message: ' ',
        short_msg: ' ',
        userName: 'Dwain',
        location: 'Ontario, CA',
        date: 'Jun 26, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message: ' ',
        short_msg: ' ',
        userName: 'Dwain',
        location: 'Ontario, CA',
        date: 'Jun 26, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message: ' ',
        short_msg: ' ',
        userName: 'Russell',
        location: 'Buckhannon, West Virginia',
        date: 'Jun 15, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message: 'fast shipping, coins packed with care',
        short_msg: 'another good experience',
        userName: 'Michael',
        location: 'Mingo, Iowa',
        date: 'Jun 13, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message: 'Painless transaction, wish they were all this way!',
        short_msg: 'Nice coin with FAST and secure shipping',
        userName: 'James',
        location: 'Bedford, Texas',
        date: 'Jun 10, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message: ' ',
        short_msg: ' ',
        userName: 'James',
        location: 'Whittier, CA',
        date: 'Jun 10, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message: 'Outstanding auction on every account. I am a fan now.',
        short_msg: 'They don’t get better than this.',
        userName: 'Jimmy',
        location: 'Lumberton, Texas',
        date: 'Jun 10, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message:
            'By far, my favorite auction company for coins and bullion. Combination of Quality coins/variety, fair pricing and Ken, the owner is of the highest Integrity in the coin business.',
        short_msg: 'Great auction company!',
        userName: 'Steve',
        location: 'La Jolla, CA',
        date: 'Jun 10, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message: ' ',
        short_msg: ' ',
        userName: 'John',
        location: 'Lancaster, PA',
        date: 'Jun 10, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message: ' ',
        short_msg: ' ',
        userName: 'Baker',
        location: 'Houston, Texas',
        date: 'Jun 10, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message:
            'It is simple! Coins and Auctions Since 1994 is The very best Auction House on Line! You pay the LOWEST buyers premium on your purchase! Ken and his staff are the best out There! The more you purchase can drop your buyers premium. Who else does that??? Love you Coins and Auctions!',
        short_msg: 'THE VERY BEST!!!!!',
        userName: 'Mark',
        location: 'Kent, New York',
        date: 'Jun 10, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message:
            'Everything was described accurately, I received exactly what I expected to get. One of the few I am very satisfied with. Thank you for their honesty and integrity.',
        short_msg: 'One of the GOOD ones!',
        userName: 'Joshua',
        location: 'Freeport, Illinois',
        date: 'May 23, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message: ' ',
        short_msg: ' ',
        userName: 'Melissa',
        location: 'Salt Lake City, Utah',
        date: 'May 15, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message: ' ',
        short_msg: ' ',
        userName: 'griffin',
        location: 'Rochester, New York',
        date: 'May 13, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message:
            'I purchased a gold coin and some silver from Coins & Auctions Since 1994. I had some questions and gave them a call. They answered their phone right away! Not only were they helpful and answered all my questions, but they treated me as if I was family! Thank you Coins & Auctions Since 1994 Staffs! You just gained a loyal customer for all my coins needs.',
        short_msg: 'Great customer service! Treated me like family',
        userName: 'Hoang',
        location: 'Vancouver, Washington',
        date: 'May 12, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message:
            'This is by far the best valued auction house available. Hammer prices are untouchable and the products are of premium quality. This is a very well run operation and Ken was great to work. This is a first class family operation with exceptional customer service. Look forward to the next auction!',
        short_msg: 'First Class Operation',
        userName: 'Peter',
        location: 'Imperial Beach, California',
        date: 'May 12, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message:
            'Not the first time I’ve bought coins from this auction house and definitely not my last! I’ve gotten really good deals and best of all, they give out discounts on their auction! I’ve saved money by buying more than 5 items and getting a discounted rate and FREE Shipping! Can’t beat that and with low premiums compared to others. Will be following and will be buying more from them.',
        short_msg: 'My favorite auction house!',
        userName: 'Mike',
        location: 'Chicago, Illinois',
        date: 'May 12, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message: ' ',
        short_msg: ' ',
        userName: 'Deena',
        location: 'Baltimore, Maryland',
        date: 'May 12, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message: ' ',
        short_msg: ' ',
        userName: 'Michael',
        location: 'Harrison Twp, Michigan',
        date: 'May 12, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message: 'They waste no time shipping items out',
        short_msg: 'Nice auction house to work withThey waste',
        userName: 'Bruce',
        location: 'Buffalo, Wyoming',
        date: 'May 11, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message: ' ',
        short_msg: ' ',
        userName: 'R',
        location: 'Edwardsburg, MI',
        date: 'May 11, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message: 'Received coin quickly.... good deal.',
        short_msg: 'Coin was a good deal.',
        userName: 'Raymond',
        location: 'Jacksonville, Florida',
        date: 'May 11, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message: 'Nice item and great experience!',
        short_msg: 'Nice experience.',
        userName: 'Pat',
        location: 'Pinehurst, North Carolina',
        date: 'May 11, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message: ' ',
        short_msg: ' ',
        userName: 'Steve',
        location: 'Casper, Wyoming',
        date: 'May 11, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message: ' ',
        short_msg: ' ',
        userName: 'Scott',
        location: 'Washington, District Of Columbia',
        date: 'May 11, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message:
            'Always willing to go the extra mile to help you out, if you miss out on something they’re always willing to help you out for the next auction or see if they can find what you missed out on. A great company with great people working to do great works for anybody they need.',
        short_msg: 'Coins and auctions since 1994',
        userName: 'Tim',
        location: 'Washougal, WA',
        date: 'May 11, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message: ' ',
        short_msg: ' ',
        userName: 'Al',
        location: 'Tallahassee, Florida',
        date: 'May 11, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message:
            'my coins were packed with care, fast shipping, they were very accurate with the grading of the coins',
        short_msg: 'A very good experience',
        userName: 'Michael',
        location: 'Mingo, Iowa',
        date: 'Apr 08, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message: ' ',
        short_msg: ' ',
        userName: 'James',
        location: 'Whittier, CA',
        date: 'Apr 07, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message: ' ',
        short_msg: ' ',
        userName: 'John',
        location: 'Canby, Oregon',
        date: 'Apr 06, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message: ' ',
        short_msg: ' ',
        userName: 'Ivan',
        location: 'Vancouver, Washington',
        date: 'Apr 06, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message: 'good group to work with',
        short_msg: 'Times and dates',
        userName: 'Bruce',
        location: 'Buffalo, Wyoming',
        date: 'Apr 06, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message: 'I’m very pleased with Coins and ’Auctions since 1994.',
        short_msg: 'Fast delivery and products as advertised.',
        userName: 'Robert',
        location: 'Bellingham, Washington',
        date: 'Apr 06, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message: ' ',
        short_msg: ' ',
        userName: 'JAMES',
        location: 'Dry Ridge, KY',
        date: 'Apr 06, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message: ' ',
        short_msg: ' ',
        userName: 'Tim',
        location: 'San Diego, California',
        date: 'Mar 13, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message:
            'Hammer prices seemed realistic (other coin specialty auctions seem to get unreasonably high prices). Shipped coins promptly after check cleared and packaging was very secure. Will watch their future auctions.',
        short_msg: 'Good 1st time experience.',
        userName: 'Mike',
        location: 'York, Pennsylvania',
        date: 'Mar 12, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message:
            'bidding goes a million miles an hour and doesn’t always follow their established bid increments. I thought I was bidding $22 and ended up buying coins at $28 and $30. no buyers premium, so that’s a huge plus.',
        short_msg: 'pay close attention',
        userName: 'Jeff',
        location: 'Granite City, Illinois',
        date: 'Mar 12, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message: 'Top notch operation. Quality products. A + + + + + + + + + + + +',
        short_msg: 'Awesome customer service',
        userName: 'JAMES',
        location: 'Dry Ridge, KY',
        date: 'Mar 10, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message: ' ',
        short_msg: ' ',
        userName: 'Richard',
        location: 'Sioux Falls, South Dakota',
        date: 'Mar 10, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message: 'Great to work with',
        short_msg: 'Very good service',
        userName: 'Willard',
        location: 'Oakland, California',
        date: 'Mar 09, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message:
            'Great variety of coins with detailed photos and descriptions. Excellent customer service as well. Will bid again.',
        short_msg: 'Honest sellers',
        userName: 'Al',
        location: 'San Jose, California',
        date: 'Mar 09, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message: ' ',
        short_msg: ' ',
        userName: 'Steve',
        location: 'Casper, Wyoming',
        date: 'Mar 09, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message:
            'Coins & Auctions Since 1994 is the best Auction House in the World. They have fantastic rates, fast shipping and you always get exactly what you bid on. When you call them they know you by name and their customer service is the best in the industry. Absolutely Honest and that is so rare today. Ken and his team are a breath of fresh air in the auction industry and I will continue to buy from them for a long time to come. They get 5 stars and are just super folks to deal with buying or selling!',
        short_msg: 'The Best!',
        userName: 'Jeffrey',
        location: 'Carrollton, Texas',
        date: 'Mar 09, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message:
            'The best auction site I have dealt with hands down, compared to several I have tried this is always the best!!!',
        short_msg: 'Great people!',
        userName: 'Tim',
        location: 'Washougal, WA',
        date: 'Mar 09, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/141612/71813944_1_x.jpg?height=94&quality=70&version=1',
        message: ' ',
        short_msg: ' ',
        userName: 'Paul',
        location: 'Grayland, WA',
        date: 'Jun 17, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/141612/71954059_1_x.jpg?height=94&quality=70&version=1',
        message: ' ',
        short_msg: ' ',
        userName: 'Leonard',
        location: 'Fresno, California',
        date: 'Jun 11, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/141613/71814882_1_x.jpg?height=94&quality=70&version=1',
        message: ' ',
        short_msg: ' ',
        userName: 'Catherine',
        location: 'Rochester Hills, Michigan',
        date: 'Jun 10, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/141613/71814942_1_x.jpg?height=94&quality=70&version=1',
        message: ' ',
        short_msg: ' ',
        userName: 'Meredith',
        location: 'Ivry-sur-le-lac, Canada',
        date: 'Jun 10, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/141613/71922558_1_x.jpg?height=94&quality=70&version=1',
        message: '0',
        short_msg: 'consideration of the customer',
        userName: 'Chad',
        location: 'W. Los Angeles, California',
        date: 'Jun 10, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/141612/72035392_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Looking for a top notch outfit to buy coins from? Coins and Auctions Since 1994 has the good! Auctions are crazy competitive these days for high level coins. I found good deals on great coins and the service and communication was well beyond what you’d expect from an auction house. Thanks and I’ll be back for more! Chris',
        short_msg: 'Top Notch Coins and Top Notch Service!',
        userName: 'chris',
        location: 'Seattle, Washington',
        date: 'Jun 10, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/141612/71813899_1_x.jpg?height=94&quality=70&version=1',
        message: 'Very good experience',
        short_msg: 'A very accurate description of the auctioned item. Very good pictures',
        userName: 'Paul',
        location: 'Fairfield, California',
        date: 'Jun 10, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/141612/71813790_1_x.jpg?height=94&quality=70&version=1',
        message: 'Very professional company. Had no problems',
        short_msg: 'Just making sure i get what i pay for',
        userName: 'Randy',
        location: 'Kanab, Utah',
        date: 'Jun 10, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/141613/71814954_1_x.jpg?height=94&quality=70&version=1',
        message: 'very satisfied with the goods and their service.',
        short_msg: 'Got what you bid for',
        userName: 'Ling',
        location: 'Balwyn, Australia',
        date: 'Jun 05, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6607/141612/72035408_1_x.jpg?height=94&quality=70&version=1',
        message: ' ',
        short_msg: ' ',
        userName: 'Arleen',
        location: 'Brecksville, Ohio',
        date: 'May 28, 2019',
        stars: '5',
    },

    {
        img_url: '',
        message: ' ',
        short_msg: ' ',
        userName: 'Joseph',
        location: 'Kalamazoo, MI',
        date: 'May 14, 2019',
        stars: '5',
    },

    {
        img_url: '',
        message: ' ',
        short_msg: ' ',
        userName: 'Steve',
        location: 'Casper, Wyoming',
        date: 'May 13, 2019',
        stars: '5',
    },

    {
        img_url: '',
        message: ' ',
        short_msg: ' ',
        userName: 'Dean',
        location: 'Saint Paul, MN',
        date: 'May 13, 2019',
        stars: '5',
    },

    {
        img_url: '',
        message: ' ',
        short_msg: ' ',
        userName: 'Jonathon',
        location: 'Warrenville, IL',
        date: 'May 13, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6607/137422/69785977_1_x.jpg?height=94&quality=70&version=1',
        message: ' ',
        short_msg: ' ',
        userName: 'Ray',
        location: 'Milton, Delaware',
        date: 'Apr 28, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6607/137422/69785750_1_x.jpg?height=94&quality=70&version=1',
        message: ' ',
        short_msg: ' ',
        userName: 'Keith',
        location: 'Littleton, Colorado',
        date: 'Apr 08, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6607/137422/69786018_1_x.jpg?height=94&quality=70&version=1',
        message: ' ',
        short_msg: ' ',
        userName: ' ',
        location: ' ',
        date: 'Apr 08, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6607/137422/70074308_1_x.jpg?height=94&quality=70&version=1',
        message: ' ',
        short_msg: ' ',
        userName: 'David',
        location: 'Eugene, Oregon',
        date: 'Apr 08, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6607/137422/69785894_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Will do business with him again!!!! Great seller. Fast shipping, professional, and the chain looks better than it did in the picture!!!!!',
        short_msg: 'Excelent seller to do business with, fast shipping!',
        userName: 'Laylah',
        location: 'Baltimore, Maryland',
        date: 'Apr 04, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6607/226850/118027881_1_x.jpg?height=94&quality=70&version=1',
        message:
            'I love all of the coins that I purchased but you really have to take a good look at what you are bidding on, I was not aware that all coins do not have dates on them but I got what I bid on and I am very happy. Living and learning',
        short_msg:
            'I am very new to coin collecting and I was a little taken aback that not all coins have dates',
        userName: 'Ronald',
        location: 'Daytona Beach, FL',
        date: 'Dec 13, 2021',
        stars: '4',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6607/186088/93565343_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Great items and auction, just need to include their paypal link with email. I always have to ask for the link and there’s a delay getting it. Then they get on me for not paying promptly.',
        short_msg: 'Need to include payment links',
        userName: 'Robert',
        location: 'Oregon City, OR',
        date: 'Jun 03, 2021',
        stars: '4',
    },
    {
        img_url: '',
        message: 'The items were described accurately.',
        short_msg: 'Received the items described.',
        userName: 'Robert',
        location: 'Hutchinson, Kansas',
        date: 'Apr 20, 2021',
        stars: '4',
    },
    {
        img_url: '',
        message:
            'With all the new taxes, rates charged for credit cards and shipping and handling and % added are way to high! I think 17 and 20 percent are to much Even 15 % is a little much.',
        short_msg: 'to many chargers on top of price',
        userName: ' ',
        location: ' ',
        date: 'Aug 10, 2020',
        stars: '4',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6607/156192/78825812_1_x.jpg?height=94&quality=70&version=1',
        message: ' ',
        short_msg: ' ',
        userName: 'Anthony',
        location: 'Brooklyn, New York',
        date: 'Apr 10, 2020',
        stars: '4',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6607/153123/77936366_1_x.jpg?height=94&quality=70&version=1',
        message:
            'buying raw coins without physically inspecting them is a gamble-you can not judge whether a coin has been cleaned by looking at photos, only by physically handling the piece. had i had the opportunity to view them on site, i would have bid differently. i guessed wrong this time.',
        short_msg: 'Coins Cleaned',
        userName: 'Don',
        location: 'Yachats, Oregon',
        date: 'Dec 19, 2019',
        stars: '4',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6607/153123/77936355_1_x.jpg?height=94&quality=70&version=1',
        message: 'I always look.',
        short_msg: 'Coin arrived as expected.',
        userName: 'Robert',
        location: 'Hutchinson, Kansas',
        date: 'Dec 02, 2019',
        stars: '4',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6607/141612/71813959_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Like the headline says i do not even remember bidding on this item. I do not believe i would have gone that high on a 1 oz of gold. I do like the auction site but i am very careful on what i bid on. It can change from $2 to $1500 in a second and by the time you pressed the button you have bid $1500 on an item you only meant to bid $2 on. Like i said i have learned the process now. It is unlike another auction house i deal with. You just have to be very careful.',
        short_msg: 'i dont even remember bidding on this item',
        userName: 'Darrell',
        location: 'Suwanee, Georgia',
        date: 'Jun 10, 2019',
        stars: '4',
    },
    {
        img_url: '',
        message:
            'Watch how you bid on all these auctions, remember the House % and then the S & H costs. Sometimes the auction you are bidding in don’t handle the S & H and you got to do the leg work, phone calls etc. I also find cheaper prices shopping around if you have the time. Have a nice day',
        short_msg: 'Cost',
        userName: 'Ed',
        location: 'Eagle, Idaho',
        date: 'May 13, 2019',
        stars: '4',
    },
    {
        img_url: '',
        message:
            'Definitely would not do business with again, Ken says one thing, does another, their slogan "all about relationships" is a joke',
        short_msg: 'Does not respond, or follow up as they say they will',
        userName: 'Jerry',
        location: 'Lincoln, Nebraska',
        date: 'Mar 17, 2021',
        stars: '3',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6607/141612/71813751_1_x.jpg?height=94&quality=70&version=1',
        message: ' ',
        short_msg: ' ',
        userName: 'Theodore',
        location: 'Kanab, UT',
        date: 'Jun 10, 2019',
        stars: '3',
    },
    {
        img_url: '',
        message:
            'This item still is not mark payed! The owner or auctioneer complained about me paying with a debit card. I sent them 3 emails about marking paid do other auctioneers can see it. Terrible customer service. Good item description!',
        short_msg: 'Man was real nasty about me paying with debit card!',
        userName: 'Payless',
        location: 'Lake Worth, FL',
        date: 'Aug 16, 2020',
        stars: '2',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6607/153123/77437343_1_x.jpg?height=94&quality=70&version=1',
        message:
            'The proof Franklin (65) is more like a (if it is proof) MS60 and the Morgan is if lucky a good 4',
        short_msg: 'Bad grading',
        userName: 'Theodore',
        location: 'Kanab, UT',
        date: 'Dec 02, 2019',
        stars: '2',
    },
    {
        img_url: '',
        message:
            'Item had lots of damage. Although described as needs restoration, Photographs should have been better. Seemed to hide the severity of the damage.',
        short_msg: 'Photo and description was vague',
        userName: 'Paul',
        location: 'Hartsville, Pennsylvania',
        date: 'Nov 29, 2019',
        stars: '2',
    },
    {
        img_url: '',
        message:
            'Nice person spoke on the with him I needed a Pay Pal invoice he did not know that he had advertised Pay Pal as an option of payment. Never received an invoice to pay thru pay pal. I sent emails to everyone on his website with no response not interested anymore. Great guy just a misunderstanding.',
        short_msg: 'Contact',
        userName: 'James',
        location: 'Warwick, Rhode Island',
        date: 'Aug 20, 2019',
        stars: '2',
    },
    {
        img_url: '',
        message:
            'In the way they laid out this lot, and in the coin we purchased. Things like putting an empty box with the lid shut out that was labeled "Florida hoard double eagle," w which should have contained a 1909S double eagle in it. Or putting what looked like gold bars but covered them just enough with other items so that you could not see that they were fake. Or claiming and labeling a 1884CC MS64/65 PL/DMPL When after having it checked was barely an MS62 VERY DISHONEST.',
        short_msg: 'They were very Deceiving',
        userName: 'Elizabeth',
        location: 'Colorado Springs, Colorado',
        date: 'Apr 02, 2021',
        stars: '1',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6607/186088/93917721_1_x.jpg?height=94&quality=70&version=1',
        message:
            'I paid for my item in a timely manner and received a receipt yet this action house filed a claim against me preventing me from being able to bid on other auctions on this platform. Their actions not only effects me but effected other auction houses from potentially obtaining the most money from the highest bidder by taking bidders such as myself out of the auction arena.',
        short_msg: 'Never again',
        userName: 'Jason',
        location: 'Billings, Montana',
        date: 'Jan 27, 2021',
        stars: '1',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6607/156192/79160020_1_x.jpg?height=94&quality=70&version=1',
        message:
            'very dissatisfied in the response to my concerns about there over grading of the coins, When I gave them a low rating they said "if you change your rating we will by the coins back plus 10 %" well I told them I had sent the coins off to PCGS to have them graded and the 1884-S the listed it at AU-58 + came back AU-50!! well below there listing so a potential $500 dollar coin became a $200 dollar coin, below what I paid for it!! If it would of been an AU-58 + it would of been worth my investment and time of submitting it for grading!! So since we did not agree on buying the coins back for the price paid and grading fees they just blacklisted me from there auctions in my opinion the are over grading the coins they put up for auction to make money off the avid collector who is trying to invest in there own future watch out for coin auctions since 1994 they are crooks!!!!!!!!!!!!!',
        short_msg: 'over rate the coins descertion and or grading',
        userName: 'Theodore',
        location: 'Kanab, UT',
        date: 'Feb 25, 2020',
        stars: '1',
    },
    {
        img_url: '',
        message: ' ',
        short_msg: ' ',
        userName: 'Lawrence',
        location: 'Carnation, Washington',
        date: 'Aug 26, 2019',
        stars: '1',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6607/137422/69785727_1_x.jpg?height=94&quality=70&version=1',
        message:
            'The coin which I bought at auction with coins and auctions was just as described with fast shipping, and I spoke to them by phone, they were very knowledgeable and mannerly. Good experience.',
        short_msg: ' ',
        userName: 'Joe',
        location: 'Naas, Ireland',
        date: 'Apr 02, 2019',
        stars: '1',
    },
]

export const removeHTMLTags = (data) => {
    let tempEle = document.createElement('span')
    tempEle.innerHTML = data
    let convertedText = tempEle.textContent || tempEle.innerText || ''
    return convertedText
}
