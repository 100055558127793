import React from 'react'
import PrimaryButton from '../../atoms/PrimaryButton'
import Timer from '../Timer'
import './ProductCard.css'
import { onImageError, setDecimalPoint } from '../../../Utils/Utility'
import { Link, useHistory } from 'react-router-dom'
import { currencyFormat } from '../../../common/components'
import { Chip } from '@material-ui/core'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import FavoriteCheckbox from '../../atoms/FavoriteCheckbox'

function Gridview(props) {
    const history = useHistory()

    return (
        <div className="gridViewCnt" key={props.id}>
            <div className="cards lotCardWrpr">
                {props.card_type === 'lotView' && (
                    <div className="favBox">
                        <span
                            name={props.prodid}
                            onClick={(e) => props.onFavClick(props.prodid)}
                            className="material-icons Favicon"
                        >
                            {'favorite'}
                        </span>
                    </div>
                    // <FavoriteCheckbox
                    //     watchlisted={props.data.watchlisted}
                    //     project_id={props.data.id}
                    //     dummy="dummy"
                    //     onchangedata={props.favour}
                    //     index={props.index}
                    //     favIdChild={props.data.id}
                    // />
                )}
                {props.card_type === 'buynowview' && (
                    <div className="favBox">
                        <span
                            name={props.prodid}
                            onClick={(e) => props.onFavClick(props.proid, props.isFavBuynow)}
                            className="material-icons Favicon"
                        >
                            {props.isFavBuynow === 1 ? 'favorite' : 'favorite_border'}
                        </span>
                    </div>
                )}
                {props.redirectUrl ? (
                    <Link to={props.redirectUrl}>
                        <LazyLoadImage
                            className="cardImage"
                            src={props.imgSrc}
                            onError={onImageError}
                            onClick={props.onClick ? props.onClick : null}
                            effect="blur"
                            placeholderSrc="/images/imageLoading.svg"
                            height="100%"
                            width="100%"
                            alt="Next Lot"
                        />
                    </Link>
                ) : (
                    <LazyLoadImage
                        className="cardImage"
                        src={props.imgSrc}
                        onError={onImageError}
                        onClick={(e) => props.onClick(e, props.lotData)}
                        effect="blur"
                        placeholderSrc="/images/imageLoading.svg"
                        height="100%"
                        width="100%"
                        alt="Next Lot"
                    />
                )}
                {props.lotData.viewers ? (
                    <p className="viewsCountValue">Viewers: {props.lotData.viewers}</p>
                ) : (
                    ''
                )}
                <div className="pdtContent">
                    {props.redirectUrl ? (
                        <Link to={props.redirectUrl}>
                            <p
                                className="pdtName mb-2"
                                onClick={props.onClick ? props.onClick : null}
                                dangerouslySetInnerHTML={{
                                    __html: props.Name,
                                }}
                            >
                                {/* {props.Name} */}
                            </p>
                        </Link>
                    ) : (
                        <p
                            className="pdtName mb-2"
                            onClick={(e) => props.onClick(e, props.lotData)}
                            dangerouslySetInnerHTML={{
                                __html: props.Name,
                            }}
                        >
                            {/* {props.Name} */}
                        </p>
                    )}
                    {props.lotId ? (
                        <Chip label={<p className="m-0">Lot Id: {props.lotId}</p>} />
                    ) : (
                        <div style={{ height: '32px' }}></div>
                    )}
                    {props.closedAuction ? (
                        <>
                            {/* <p className="currPrice pb-3">
                                Sold Price: <span> $ {setDecimalPoint(props.currentPrice, 2)}</span>
                            </p> */}
                            {props.is_fav ? (
                                <PrimaryButton
                                    cstmClass="w-100 mt-0"
                                    className="viewAuctionBtn"
                                    onClick={props.onClick ? props.onClick : null}
                                >
                                    {props.label}
                                </PrimaryButton>
                            ) : (
                                <PrimaryButton
                                    cstmClass="w-100 mt-0"
                                    className="viewAuctionBtn"
                                    onClick={() =>
                                        history.push(encodeURI(`/auction/${props.id}?page=1`))
                                    }
                                >
                                    {props.label}
                                </PrimaryButton>
                            )}
                        </>
                    ) : (
                        <>
                            {(props.lowest || props.highest) && (
                                <p className="mt-3">
                                    Est: {currencyFormat(props.lowest) || 100} -{' '}
                                    {currencyFormat(props.highest) || 1000}
                                </p>
                            )}
                            <p
                                className="pdtDescrp mt-4"
                                dangerouslySetInnerHTML={{ __html: props.descrp }}
                            ></p>
                            {/* <Timer
                                productTime={{ start: props.startTime, end: props.endTime }}
                                timerBasic={props.timerBasic}
                            /> */}
                            {props.lotBidding ? (
                                <>
                                    {props.market_status === 'unsold' ||
                                    props.market_status === 'closed' ? (
                                        <p className="currPrice pb-1">Lot Passed</p>
                                    ) : (
                                        <p className="currPrice pb-1">
                                            {props.market_status === 'sold'
                                                ? 'Sold Price'
                                                : 'Buynow Price'}{' '}
                                            <span> $ {setDecimalPoint(props.bprice, 2)}</span>
                                        </p>
                                    )}
                                    {/* {props.lotBidding} */}
                                </>
                            ) : props.is_fav ? (
                                <p className="currPrice pb-1">
                                    {props.market_status === 'sold' ? 'Sold Price' : 'Buynow Price'}{' '}
                                    <span> $ {setDecimalPoint(props.bprice, 2)}</span>
                                </p>
                            ) : (
                                ''
                            )}
                            <PrimaryButton
                                cstmClass="w-100 mt-0"
                                className="viewAuctionBtn"
                                onClick={(e) => props.onClick(e, props.lotData)}
                            >
                                {'Buy Now'}
                            </PrimaryButton>
                            {/* {props.highbid_user_id &&
                                props.userDetails &&
                                props.highbid_user_id == props.userDetails.userdetails.id && (
                                    <h5 className="winningLabel">
                                        <b>You're Winning!</b>
                                    </h5>
                                )} */}
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Gridview
