import React, { useState, useEffect, useRef } from 'react'
import Filter from '../../components/molecules/Filter/Filter'
import { Button } from '@material-ui/core'
import clsx from 'clsx'
import Commondropdown from '../../components/atoms/DropDown/commondropdown'
import GridViewBuynow from '../../components/molecules/ProductCard/GridViewBuynow'
import './Search.css'
import ListView from '../../components/molecules/ProductCard/ListView'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import qs from 'qs'
import getLotdata from '../../Utils/API/lotsearch'
import getAuctionLots from '../../Utils/API/auctionLotSearch'
import { makeStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import Pagination from '@material-ui/lab/Pagination'
import { getPaginationCount, onImageError, setDecimalPoint } from '../../Utils/Utility'
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import CustomInput from '../../components/atoms/Inputs/CustomInput'
import PrimaryButton from '../../components/atoms/PrimaryButton'
import { findIndex } from 'lodash'
import Timer from '../../components/molecules/Timer'
import moment from 'moment'
import { ToastContainer, toast } from 'react-toastify'
import commonBidding from '../../Utils/API/nomalBidding'
import isAuthenticate from '../../Utils/Utility/auth'
import axios from '../../Utils/Utility/axiosconfig'
import Popover from '@material-ui/core/Popover'
import ProductViewSlider from '../../components/organisms/ProdutViewSlider'
import BidHistory from '../../components/organisms/BidHistory'
import updateFav from '../../Utils/API/fav'
import { handleRedirectInternal } from '../../common/components'
import { socket } from '../../common/socket'
import ReadMore from '../../components/atoms/ReadMore'
import Loaders from '../../components/molecules/Loader'
const useStyles = makeStyles({
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
})

function Search(props) {
    const [view, setView] = useState(true)
    const [auctionSearch, setAuctionSearch] = useState([])
    const [totalCount, setTotalCount] = useState('')
    const [auctionLots, setAuctionLots] = useState([])
    const [lotBody, setLotBody] = useState(undefined)
    const [body, setBody] = useState(undefined)
    const [amount, setAmount] = useState({})
    const [anchorEl, setAnchorEl] = useState(null)
    const [popText, setPopText] = useState('')
    const [historyId, setHistoryId] = useState(undefined)
    const [lotData, setLotData] = useState({})
    const [data, setData] = useState({})
    const [loading, setLoading] = useState(true)
    const open = Boolean(anchorEl)
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    })
    const { id } = useParams()
    const classes = useStyles()
    const history = useHistory()
    let queryValues = new URLSearchParams(props.location.search)
    const FilterValue = [
        {
            label: 'Category',
            categoryName: 'Category 1',
        },
        {
            label: 'Sub Category',
            categoryName: 'Category 1',
        },
        {
            label: 'Location',
            categoryName: 'Category 1',
        },
        {
            label: 'Miles Away',
            categoryName: 'Category 1',
        },
    ]
    const searchResultCount = [
        { id: '10', data: '10' },
        { id: '20', data: '20' },
        { id: '30', data: '30' },
    ]

    const sortBy = [
        {
            id: 2,
            data: 'Recently listed',
        },
        {
            id: 3,
            data: 'Price low to high',
        },
        {
            id: 11,
            data: 'Price high to low',
        },
    ]

    useEffect(() => {
        if (body !== undefined) {
            getAuctionLotsInfo(body)
        }
    }, [body])

    useEffect(() => {
        console.log(props.location.search, 'this is search location')
        setLoading(true)
        let query = qs.parse(props.location.search, { ignoreQueryPrefix: true })
        setBody({
            from: 'buynow',
            getbidding: true,
            orderby: '2',
            page: 1,
            sh_limit: 10,
            viewers: 1,
            searchbar: query.search ? query.search : '',
        })
    }, [props.location.search])

    // async function getData(body) {
    //     console.log('this isasdioadoisadio', body)
    //     console.log(body.searchquery, body.searchquery.length, body.searchbar.length, 'thi si body')
    //     if (body.searchquery.length !== 0) {
    //         let data = await getAuctionLots(body)
    //         if (data && data.error === false) {
    //             if (
    //                 data.result &&
    //                 Array.isArray(data.result.listOfProjects) &&
    //                 data.result.listOfProjects.length !== 0
    //             ) {
    //                 console.log(data, 'this is body')
    //             } else {
    //                 console.log('errr')
    //             }
    //         }
    //     } else if (body.searchbar.length === 0) {
    //         let { data, count } = await getLotdata(body)
    //         setAuctionSearch(Array.isArray(data) ? data : [])
    //         setTotalCount(count)
    //     }
    // }

    const getAuctions = async (body) => {
        let { data, count } = await getLotdata(body)
        setAuctionSearch(Array.isArray(data) ? data : [])
        setTotalCount(count)
        setAuctionLots([])
    }

    useEffect(() => {
        if (!isAuthenticate()) {
            handleRedirectInternal(history, `login`)
        }
    }, [])

    const getAuctionLotsInfo = async (body) => {
        let { data } = await axios.post('mobileapi/mobilesearch', body)
        if (data && data.success === 'yes') {
            if (data.results && Array.isArray(data.results) && data.results.length !== 0) {
                setAuctionLots((pre) => [...data.results])
                setTotalCount(data.total_pagecnt)
            } else {
                setAuctionLots([])
            }
            setLoading(false)
        }
    }
    // const handlech = (data, det) => {
    //     let query = new URLSearchParams(props.location.search)
    //     query.set(data, det.toString())
    //     history.push({ pathname: '/search', search: '?' + query.toString() })
    // }

    const handleChange = (e) => {
        let query = new URLSearchParams(props.location.search)
        query.set(e.target.name, e.target.value)
        history.push({ pathname: '/search', search: '?' + query.toString() })
    }

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return
        }

        setState({ ...state, [anchor]: open })
    }

    const list = (anchor) => (
        <div
            className={clsx(classes.list, {
                [classes.fullList]: anchor === 'top' || anchor === 'bottom',
            })}
            role="presentation"
        >
            <List classname="mobFilter">
                {Array.isArray(props.category.category) &&
                    props.category.category.map((data, index) => (
                        <Filter
                            title={data.name}
                            label={data.filters}
                            key={index}
                            sub_cat_id={body !== undefined ? body.sub_cat_id : []}
                            cat_id={body !== undefined ? body.custom_field_3 : []}
                        />
                    ))}
            </List>
        </div>
    )

    const handleOnChange = (e) =>
        !isNaN(e.target.value)
            ? setAmount({ ...amount, [e.target.name]: e.target.value })
            : setAmount({ ...amount })

    const onKeyPress = (e, id) => {
        if (e.key === 'Enter') {
            let productPosition = findIndex(auctionLots, { id: id })
            if (productPosition !== -1) {
                let productPosition = findIndex(auctionLots, { id: id })
                let product = auctionLots[productPosition]
                handleClick(
                    {
                        id: product.id,
                        wsprice: amount[product.id],
                        bid_increment: product.incrementamt,
                    },
                    parseFloat(product.next_bid),
                )
            }
        }
    }

    const handleClick = async (body, nPrice) => {
        console.log(props)

        if (isAuthenticate()) {
            // let card_added = JSON.parse(localStorage.getItem('userData')).card_paymentid
            // if (card_added == '' || card_added == undefined) {
            //     console.log('card is not there')
            //     toast.warning(`Kindly add card details to Bid`)
            //     return
            // }
            if (moment(props.serverTime).isBefore(moment(lotData.date_added))) {
                toast.warning('Auction is not started!')
                console.log('this si returend ')
                return false
            }
            console.log('this si afeter returend ')

            if (nPrice > body.wsprice) {
                toast.warning(`Enter $${nPrice} or more`)
            } else {
                await commonBidding({
                    ...body,
                    email: props.userDetails.userdetails.email,
                    userid: props.userDetails.id,
                })
                setAmount({ ...amount, [body.id]: '' })
            }
        } else {
            history.push({ pathname: '/login', state: props.location })
        }
    }
    const handlePopoverOpen = (event, text) => {
        setPopText(text)
        setAnchorEl(event.currentTarget)
    }

    const handlePopoverClose = () => {
        setAnchorEl(null)
    }
    const handleHistory = (val) => setHistoryId(val)
    const getLotData = async (idVal) => {
        let { data } = await axios.post('mobileapi/getauctionlotdetails', { auctionid: idVal })
        if (data && data.success === true) {
            setLotData(data.data.auction_details)
            return data
        }
    }

    const addFav = async (id, isFav, no = 0) => {
        if (isAuthenticate()) {
            if (no == 0) {
                let data = await updateFav(
                    { id: id, userid: props.userDetails.userdetails.id },
                    isFav,
                )
            } else {
                let data = 0
            }
            if (data || no == 1) {
                let position = findIndex(auctionLots, { id: id })
                if (position !== -1) {
                    let dataFav = auctionLots[position]
                    dataFav = { ...dataFav, watch: isFav === 0 ? 1 : 0 }
                    auctionLots[position] = dataFav
                    if (Object.keys(data).length !== 0) {
                        setData(dataFav)
                    }
                    setAuctionLots([...auctionLots])
                }
            }
        } else {
            history.push({ pathname: '/login', state: props.location })
        }
    }

    const toggleDrawerSearch = (event, data) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return
        }
        if (Object.keys(data) !== 0) {
            setData(data)
        } else {
            setData({})
        }
    }

    const handleBidding = async (data) => {
        let { datas } = await getLotData(data.lotof)
        if (datas && datas.success === true) {
            handleClick(
                {
                    id: data.id,
                    wsprice: amount[data.id],
                    bid_increment: data.incrementamt,
                },
                parseFloat(data.next_bid),
            )
        }
    }
    const lotDetailsRef = useRef(auctionLots)

    useEffect(() => {
        lotDetailsRef.current = auctionLots
    })

    useEffect(() => {
        socket.on('product_view_count', function (data) {
            var lot_detail = lotDetailsRef.current

            let productPosition = findIndex(lot_detail, { id: data.project_id })

            if (productPosition !== -1) {
                var pro_count = parseInt(lot_detail[productPosition].viewers) + parseInt(1)
                lot_detail[productPosition].viewers = pro_count
                setAuctionLots([...lot_detail])
            }
        })
        return () => {
            socket.off('product_view_count', function (data) {
                var lot_detail = lotDetailsRef
                let productPosition = findIndex(lot_detail, { id: data.project_id })
                if (productPosition !== -1) {
                    var pro_count = parseInt(lot_detail[productPosition].viewers) + parseInt(1)
                    lot_detail[productPosition].viewers = pro_count
                    setAuctionLots([...lot_detail])
                }
            })
        }
    }, [])

    return (
        <>
            {/* <div className="filterMb">
                {['left'].map((anchor) => (
                    <React.Fragment key={anchor}>
                        <Button onClick={toggleDrawer(anchor, true)}>
                            <span className="material-icons">tune</span> Filter
                        </Button>
                        <Drawer
                            anchor={anchor}
                            open={state[anchor]}
                            onClose={toggleDrawer(anchor, false)}
                        >
                            {list(anchor)}
                        </Drawer>
                    </React.Fragment>
                ))}
            </div> */}
            {loading ? (
                <div className="mnHtcontnr">
                    <div className="liveLotsLoader">
                        <Loaders name="live_auction" isLoading={loading} />
                    </div>
                </div>
            ) : (
                <div className="SearchCnt d-flex customContainer">
                    <div className="filterWrp">
                        <div className="title">Filter</div>
                        {Array.isArray(props.category.category) &&
                            props.category.category.map((data, index) => (
                                <Filter
                                    title={data.description}
                                    label={data.filters}
                                    disabled_filter={1}
                                    key={index}
                                    sub_cat_id={body !== undefined ? body.sub_cat_id : []}
                                    cat_id={body !== undefined ? body.custom_field_3 : []}
                                />
                            ))}
                    </div>
                    <div className="searchPdtCnt">
                        <div className="searchTopCnt d-flex align-items-center justify-content-between">
                            {totalCount !== '' && (
                                <p className="result">Showing {totalCount} results</p>
                            )}
                            <div className="topcntSearchWrp d-flex">
                                <Button onClick={() => setView(true)}>
                                    <span class={`material-icons ${view ? 'active' : ''}`}>
                                        apps
                                    </span>
                                </Button>
                                <Button onClick={() => setView(false)}>
                                    <span class={`material-icons ${!view ? 'active' : ''}`}>
                                        format_list_bulleted
                                    </span>
                                </Button>
                                <div className="resultPrePage d-flex align-items-center">
                                    <p>Results per page :</p>

                                    <Commondropdown
                                        selected={body ? body.sh_limit : '10'}
                                        values={searchResultCount}
                                        name="limit"
                                        handleChange={(e) =>
                                            setBody({ ...body, page: 1, sh_limit: e.target.value })
                                        }
                                    />
                                </div>
                                <div className="resultPrePage d-flex align-items-center">
                                    <p>Sort By :</p>
                                    <Commondropdown
                                        selected={body ? body.orderby : sortBy[0]['id']}
                                        values={sortBy}
                                        name="orderby"
                                        handleChange={(e) =>
                                            setBody({ ...body, page: 1, orderby: e.target.value })
                                        }
                                    />
                                </div>

                                {/* <div className="resultPrePage d-flex align-items-center">
                                <p>Sort By :</p>
                                <Commondropdown
                                    selected={body ? body.orderby : sortBy[0]['id']}
                                    values={sortBy}
                                    name="orderby"
                                    handleChange={handleChange}
                                />
                            </div> */}
                            </div>
                        </div>
                        <div className="srchVwDtlsCntnr">
                            {auctionLots.length ? (
                                <>
                                    {view ? (
                                        <div className="lotGrid customContainer mt-4">
                                            {auctionLots.map((data, index) => (
                                                <GridViewBuynow
                                                    card_type={'buynowview'}
                                                    imgSrc={
                                                        data.file_name
                                                            ? `${
                                                                  process.env
                                                                      .REACT_APP_PRODUCT_IMG +
                                                                  data?.file_name
                                                              }`
                                                            : '/images/pdtimg.png'
                                                    }
                                                    proid={data.id}
                                                    isFavBuynow={data.watch}
                                                    Name={data.title}
                                                    currentPrice={data.wprice}
                                                    bprice={data.bprice}
                                                    label={'View Auction'}
                                                    timerBasic={true}
                                                    onClick={(e, data) => {
                                                        toggleDrawerSearch(e, data)
                                                    }}
                                                    lotData={data}
                                                    id={data.lotof}
                                                    lotId={data.sku}
                                                    startTime={data.date_added}
                                                    endTime={data.date_closed}
                                                    onFavClick={addFav}
                                                    lotBidding={
                                                        <div className="placeBid gridViewbtn">
                                                            <CustomInput
                                                                placeholder={`Enter bid`}
                                                                label={`Enter bid`}
                                                                variant="outlined"
                                                                onChange={handleOnChange}
                                                                pattern="^\d*(.\d{0,2})?$"
                                                                name={String(data.id)}
                                                                value={amount[data.id] || ''}
                                                                onKeyPress={(e) =>
                                                                    onKeyPress(e, data.id)
                                                                }
                                                            />

                                                            <PrimaryButton
                                                                label="Place Max Bid"
                                                                disabled={
                                                                    isAuthenticate() ===
                                                                        undefined ||
                                                                    amount[data.id] === undefined ||
                                                                    amount[data.id] === ''
                                                                }
                                                                onClick={(e) => {
                                                                    handleBidding(data)
                                                                }}
                                                            />
                                                        </div>
                                                    }
                                                    highbid_user_id={data.highbid_user_id}
                                                    userDetails={props.userDetails}
                                                />
                                            ))}
                                        </div>
                                    ) : (
                                        <div className="lotPdtWrp  table-responsive">
                                            <table className="table tableCnt buyNowTable">
                                                <thead className="tableHeader">
                                                    <tr>
                                                        <th>Lot #</th>
                                                        <th>Image</th>
                                                        <th>Viewers</th>
                                                        <th className="tableTitle">Title</th>
                                                        <th className="tableDescription">
                                                            Description
                                                        </th>
                                                        {/* <th>
                                                                Closing <br /> At
                                                            </th> */}
                                                        <th>Buynow Price</th>
                                                        <th>Action</th>
                                                        {/* <th>
                                                        No .Of <br /> Bids
                                                    </th> */}
                                                        {/* <th>Action</th> */}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {auctionLots.map((data, index) => (
                                                        <tr key={data.id}>
                                                            {console.log(data, 'checkData')}
                                                            <td className="slNo">
                                                                <span
                                                                    className="cursor"
                                                                    onClick={(e) => {
                                                                        toggleDrawerSearch(e, data)
                                                                    }}
                                                                >
                                                                    {data.sku ? data.sku : data.id}
                                                                </span>
                                                            </td>
                                                            <td className="lotImg">
                                                                <div
                                                                    className="imgBtn"
                                                                    style={{ position: 'relative' }}
                                                                >
                                                                    <div className="list-view-box">
                                                                        <span
                                                                            name={data.id}
                                                                            onClick={(e) =>
                                                                                addFav(
                                                                                    data.id,
                                                                                    data.watch,
                                                                                )
                                                                            }
                                                                            className="material-icons Favicon"
                                                                        >
                                                                            {data.watch === 1
                                                                                ? 'favorite'
                                                                                : 'favorite_border'}
                                                                        </span>
                                                                    </div>
                                                                    <img
                                                                        onClick={(e) => {
                                                                            toggleDrawerSearch(
                                                                                e,
                                                                                data,
                                                                            )
                                                                        }}
                                                                        alt={data.title}
                                                                        src={
                                                                            process.env
                                                                                .REACT_APP_PRODUCT_IMG +
                                                                            data?.file_name
                                                                        }
                                                                        onError={onImageError}
                                                                    />
                                                                </div>
                                                            </td>
                                                            <td>{data.viewers}</td>
                                                            <td className="tableTitle">
                                                                <a
                                                                    className="cursor"
                                                                    onClick={(e) => {
                                                                        toggleDrawerSearch(e, data)
                                                                    }}
                                                                >
                                                                    {data.title}
                                                                </a>
                                                            </td>
                                                            <td className="tableDescription">
                                                                <div className="onclisk">
                                                                    <ReadMore
                                                                        data={data.description}
                                                                        className="description"
                                                                        limit={200}
                                                                        label="Show"
                                                                        showOriginalOnExpand={true}
                                                                    />
                                                                </div>
                                                            </td>
                                                            {/* <td className="lotTime">
                                                                    <Timer
                                                                        productTime={{
                                                                            start: moment()
                                                                                .subtract(1, 'hour')
                                                                                .format(),
                                                                            end: data.date_closed,
                                                                        }}
                                                                    />
                                                                </td> */}
                                                            <td className="CurrentPrice">
                                                                $ {setDecimalPoint(data.bprice, 2)}
                                                            </td>
                                                            <td className="tableAct">
                                                                {' '}
                                                                <PrimaryButton
                                                                    cstmClass="w-100 mt-0"
                                                                    className="viewAuctionBtn"
                                                                    onClick={(e) =>
                                                                        toggleDrawerSearch(e, data)
                                                                    }
                                                                >
                                                                    {'Buy Now'}
                                                                </PrimaryButton>
                                                            </td>
                                                            {/* <td className="bids">
                                                            {data.nobids} */}
                                                            {/* <a
                                                                        onClick={(e) =>
                                                                            handleHistory(data.id)
                                                                        }
                                                                    >
                                                                        {data.nobids}
                                                                    </a> */}
                                                            {/* </td> */}
                                                            {/* <td>
                                                                    <div className="placeBid d-flex justify-content-end align-items-end">
                                                                        <CustomInput
                                                                            placeholder={`Enter $${setDecimalPoint(
                                                                                parseFloat(
                                                                                    data.next_bid,
                                                                                ),
                                                                                2,
                                                                            )} or more`}
                                                                            variant="standard"
                                                                            onChange={
                                                                                handleOnChange
                                                                            }
                                                                            pattern="^\d*(.\d{0,2})?$"
                                                                            name={String(data.id)}
                                                                            value={
                                                                                amount[data.id] ||
                                                                                ''
                                                                            }
                                                                            onKeyPress={(e) =>
                                                                                onKeyPress(
                                                                                    e,
                                                                                    data.id,
                                                                                )
                                                                            }
                                                                        />
                                                                        <PrimaryButton
                                                                            label="Place Max Bid"
                                                                            disabled={
                                                                                isAuthenticate() ===
                                                                                    undefined ||
                                                                                amount[data.id] ===
                                                                                    undefined ||
                                                                                amount[data.id] ===
                                                                                    ''
                                                                            }
                                                                            onClick={(e) =>
                                                                                handleClick(
                                                                                    {
                                                                                        id: data.id,
                                                                                        wsprice:
                                                                                            amount[
                                                                                                data
                                                                                                    .id
                                                                                            ],
                                                                                        bid_increment:
                                                                                            data.incrementamt,
                                                                                    },
                                                                                    parseFloat(
                                                                                        data.next_bid,
                                                                                    ),
                                                                                )
                                                                            }
                                                                        />
                                                                    </div>
                                                                </td> */}
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    )}
                                </>
                            ) : (
                                <div className="noPdtsFnd text-center">
                                    <span className="material-icons">search</span>
                                    <p>No Products Found</p>
                                </div>
                            )}
                        </div>
                        <div className="pageNo">
                            {totalCount !== '' &&
                                Object.keys(body).length !== 0 &&
                                auctionLots.length > 0 && (
                                    <Pagination
                                        count={Math.ceil(
                                            parseInt(totalCount) / parseInt(body.sh_limit),
                                        )}
                                        onChange={(e, value) => {
                                            setBody({
                                                ...body,
                                                page: value,
                                            })
                                            window.scrollTo(0, 0)
                                        }}
                                        page={parseInt(body.page)}
                                    />
                                )}
                        </div>
                    </div>
                </div>
            )}
            <Popover
                id="mouse-over-popover"
                className={classes.popover}
                classes={{
                    paper: classes.paper,
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <Typography>{popText}</Typography>
            </Popover>

            <ProductViewSlider
                onCloseClick={(e) => toggleDrawerSearch(e, {})}
                data={data}
                isOpen={Object.keys(data) != 0}
                addFav={addFav}
                amount={amount}
                handleOnChange={handleOnChange}
                onKeyPress={onKeyPress}
                handleClick={handleClick}
                handleHistory={handleHistory}
            />
            <BidHistory
                open={historyId !== undefined}
                productId={historyId}
                onClose={handleHistory}
            />
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        category: state.category ? state.category : {},
        userDetails: state.User ? state.User : {},
        bidding: state.bidding ? state.bidding : {},
        serverTime: state.serverTime ? state.serverTime.time : '',
    }
}

export default connect(mapStateToProps, null)(Search)
