const cartLotReducer = (
    state = {
        cart_details: [],
        cart_count: 0,
    },
    action,
) => {
    console.log(
        'pppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppp1111',
        action.list,
    )
    switch (action.type) {
        case 'CARTLIST':
            return {
                ...state,
                cart_details: action.list,
                cart_count: action?.list?.length > 0 ? action.list.length : 0,
            }
        default:
            return state
    }
}

export default cartLotReducer
