import React, { useState, useEffect } from 'react'
import Filter from '../../components/molecules/Filter/Filter'
import { Button } from '@material-ui/core'
import clsx from 'clsx'
import Commondropdown from '../../components/atoms/DropDown/commondropdown'
import GridView from '../../components/molecules/ProductCard/GridView'
import './Search.css'
import ListView from '../../components/molecules/ProductCard/ListView'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import qs from 'qs'
import getLotdata from '../../Utils/API/lotsearch'
import getAuctionLots from '../../Utils/API/auctionLotSearch'
import { makeStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import Pagination from '@material-ui/lab/Pagination'
import { getPaginationCount, onImageError, setDecimalPoint } from '../../Utils/Utility'
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import CustomInput from '../../components/atoms/Inputs/CustomInput'
import PrimaryButton from '../../components/atoms/PrimaryButton'
import { findIndex } from 'lodash'
import Timer from '../../components/molecules/Timer'
import moment from 'moment'
import { ToastContainer, toast } from 'react-toastify'
import commonBidding from '../../Utils/API/nomalBidding'
import isAuthenticate from '../../Utils/Utility/auth'
import axios from '../../Utils/Utility/axiosconfig'
import Popover from '@material-ui/core/Popover'
import ProductViewSlider from '../../components/organisms/ProdutViewSlider'
import BidHistory from '../../components/organisms/BidHistory'
import updateFav from '../../Utils/API/fav'
import { handleRedirectInternal } from '../../common/components'
import Loaders from '../../components/molecules/Loader'
const useStyles = makeStyles({
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
})

function Search(props) {
    const [view, setView] = useState(true)
    const [auctionSearch, setAuctionSearch] = useState([])
    const [totalCount, setTotalCount] = useState('')
    const [auctionLots, setAuctionLots] = useState([])
    const [lotBody, setLotBody] = useState(undefined)
    const [body, setBody] = useState(undefined)
    const [amount, setAmount] = useState({})
    const [anchorEl, setAnchorEl] = useState(null)
    const [popText, setPopText] = useState('')
    const [historyId, setHistoryId] = useState(undefined)
    const [lotData, setLotData] = useState({})
    const [data, setData] = useState({})
    const [Loading, setLoading] = useState(true)
    const open = Boolean(anchorEl)
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    })
    const { id } = useParams()
    const classes = useStyles()
    const history = useHistory()
    let queryValues = new URLSearchParams(props.location.search)
    let searchQry = qs.parse(props.location.search, { ignoreQueryPrefix: true })
    const FilterValue = [
        {
            label: 'Category',
            categoryName: 'Category 1',
        },
        {
            label: 'Sub Category',
            categoryName: 'Category 1',
        },
        {
            label: 'Location',
            categoryName: 'Category 1',
        },
        {
            label: 'Miles Away',
            categoryName: 'Category 1',
        },
    ]
    const searchResultCount = [
        { id: '10', data: '10' },
        { id: '20', data: '20' },
        { id: '30', data: '30' },
    ]

    const searchResultCountround = [
        { id: '10', data: '10' },
        { id: '20', data: '20' },
        { id: '100', data: '100' },
        { id: '5000', data: 'See All' },
    ]

    const sortBy = [
        { id: '2', data: 'Recent' },
        { id: '3', data: 'Oldest' },
    ]

    useEffect(() => {
        if (body !== undefined) {
            let query = qs.parse(props.location.search, { ignoreQueryPrefix: true })
            console.log('query in api call', query)
            if (props.location.search && query.search) {
                getAuctionLotsInfo(body)
            } else {
                getAuctions(body)
            }
        }
    }, [body])

    useEffect(() => {
        console.log(props.location.search, 'this is search location')
        let searchquery = qs.parse(props.location.search, { ignoreQueryPrefix: true })
        if (props.location.search && searchquery.search) {
            let query = qs.parse(props.location.search, { ignoreQueryPrefix: true })
            console.log(query, query.search, 'this is search query for the search page')
            if (query.search !== '') {
                console.log('if condition one')
                setLoading(true)
                setBody({
                    limit: query.limit ? query.limit.toString() : '100',
                    lotof: '0',
                    orderby: query.orderby ? query.orderby.toString() : '2',
                    page: query.page || 1,
                    order: 1,
                    searchbar_with_lot_number: query.search || '',
                    lot_num_fill: !parseInt(query.ty) ? 1 : 0,
                })
            } else {
                console.log('executed else statement', query.page, query.orderby)
                setBody({
                    orderby: query.orderby ? query.orderby.toString() : '2',
                    lotof: '0',
                    page: query.page || 1,
                    searchbar: query.search || '',
                    limit: query.limit ? query.limit.toString() : '10',
                    custom_field_3: query.cat_id ? query.cat_id.split(',') : [],
                    sub_cat_id: query.sub_cat_id ? query.sub_cat_id.split(',') : [],
                })
            }
        } else {
            let query = qs.parse(props.location.search, { ignoreQueryPrefix: true })
            setBody({
                orderby: query.orderby ? query.orderby.toString() : '2',
                page: query.page || 1,
                searchbar: '',
                sh_limit: query.limit ? query.limit.toString() : '10',
                custom_field_3: [],
                sub_cat_id: [],
            })
        }
    }, [props.location.search])

    // async function getData(body) {
    //     console.log('this isasdioadoisadio', body)
    //     console.log(body.searchquery, body.searchquery.length, body.searchbar.length, 'thi si body')
    //     if (body.searchquery.length !== 0) {
    //         let data = await getAuctionLots(body)
    //         if (data && data.error === false) {
    //             if (
    //                 data.result &&
    //                 Array.isArray(data.result.listOfProjects) &&
    //                 data.result.listOfProjects.length !== 0
    //             ) {
    //                 console.log(data, 'this is body')
    //             } else {
    //                 console.log('errr')
    //             }
    //         }
    //     } else if (body.searchbar.length === 0) {
    //         let { data, count } = await getLotdata(body)
    //         setAuctionSearch(Array.isArray(data) ? data : [])
    //         setTotalCount(count)
    //     }
    // }

    const getAuctions = async (body) => {
        let { data, count } = await getLotdata(body)
        setAuctionSearch(Array.isArray(data) ? data : [])
        setTotalCount(count)
        setAuctionLots([])
    }

    const getAuctionLotsInfo = async () => {
        body.need_auction_status = 1
        let data = await getAuctionLots(body)
        if (data && data.error === false) {
            if (
                data.result &&
                Array.isArray(data.result.listOfProjects) &&
                data.result.listOfProjects.length !== 0
            ) {
                console.log(data.result, 'this is lot query')
                setAuctionLots((pre) => [...data.result.listOfProjects])
                setTotalCount(data.result.totalCount)
            } else {
                setAuctionLots([])
                setTotalCount('')
            }
        }
        setLoading(false)
    }

    useEffect(() => {
        let query = qs.parse(props.location.search, { ignoreQueryPrefix: true })
        console.log(
            'this is lot query body?.limit)',
            'totalCount ---->',
            totalCount,
            'body?.limit ---->',
            Number(body?.limit),
            'body?.page ---->',
            Number(body?.page),
            getPaginationCount(totalCount, body?.limit),
            Number(query.page),
        )
        if (totalCount != '') {
            if (getPaginationCount(totalCount, body?.limit) < Number(body?.page)) {
                let query = new URLSearchParams(props.location.search)
                query.set('page', 1)
                history.push({
                    pathname: window.location.pathname,
                    search: '?' + query.toString(),
                })
            }
        }
    }, [auctionLots])
    // const handlech = (data, det) => {
    //     let query = new URLSearchParams(props.location.search)
    //     query.set(data, det.toString())
    //     history.push({ pathname: '/search', search: '?' + query.toString() })
    // }

    const handleChange = (e) => {
        let query = new URLSearchParams(props.location.search)
        query.set(e.target.name, e.target.value)
        query.set('page', 1)
        history.push({ pathname: '/search', search: '?' + query.toString() })
    }

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return
        }

        setState({ ...state, [anchor]: open })
    }

    const list = (anchor) => (
        <div
            className={clsx(classes.list, {
                [classes.fullList]: anchor === 'top' || anchor === 'bottom',
            })}
            role="presentation"
        >
            <List classname="mobFilter">
                {Array.isArray(props.category.category) &&
                    props.category.category.map((data, index) => (
                        <Filter
                            title={data.name}
                            label={data.filters}
                            key={index}
                            sub_cat_id={body !== undefined ? body.sub_cat_id : []}
                            cat_id={body !== undefined ? body.custom_field_3 : []}
                        />
                    ))}
            </List>
        </div>
    )

    const handleOnChange = (e) =>
        !isNaN(e.target.value)
            ? setAmount({ ...amount, [e.target.name]: e.target.value })
            : setAmount({ ...amount })

    const onKeyPress = (e, id) => {
        if (e.key === 'Enter') {
            let productPosition = findIndex(auctionLots, { id: id })
            if (productPosition !== -1) {
                let productPosition = findIndex(auctionLots, { id: id })
                let product = auctionLots[productPosition]
                handleClick(
                    {
                        id: product.id,
                        wsprice: amount[product.id],
                        bid_increment: product.incrementamt,
                    },
                    parseFloat(product.next_bid),
                )
            }
        }
    }

    const handleClick = async (body, nPrice) => {
        console.log(props)

        if (isAuthenticate()) {
            // let card_added = JSON.parse(localStorage.getItem('userData')).card_paymentid
            // if (card_added == '' || card_added == undefined) {
            //     console.log('card is not there')
            //     toast.warning(`Kindly add card details to Bid`)
            //     return
            // }
            if (moment(props.serverTime).isBefore(moment(lotData.date_added))) {
                toast.warning('Auction is not started!')
                console.log('this si returend ')
                return false
            }
            console.log('this si afeter returend ')

            if (nPrice > body.wsprice) {
                toast.warning(`Enter $${nPrice} or more`)
            } else {
                await commonBidding({
                    ...body,
                    email: props.userDetails.userdetails.email,
                    userid: props.userDetails.id,
                })
                setAmount({ ...amount, [body.id]: '' })
            }
        } else {
            history.push({ pathname: '/login', state: props.location })
        }
    }
    const handlePopoverOpen = (event, text) => {
        setPopText(text)
        setAnchorEl(event.currentTarget)
    }

    const handlePopoverClose = () => {
        setAnchorEl(null)
    }
    const handleHistory = (val) => setHistoryId(val)
    const getLotData = async (idVal) => {
        let { data } = await axios.post('mobileapi/getauctionlotdetails', { auctionid: idVal })
        if (data && data.success === true) {
            setLotData(data.data.auction_details)
            return data
        }
    }

    useEffect(() => {
        if (Object.keys(props.bidding.bidding).length !== 0) {
            let bidding = props.bidding.bidding
            // console.log('bidding===>', bidding)
            let productPosition = findIndex(auctionLots, { id: bidding.id })
            if (productPosition !== -1) {
                let product = auctionLots[productPosition]
                // console.log('bidding.bpop_cbiddern 2222---', bidding.bpop_cbidder)
                // console.log(
                //     'props.userDetails.userdetails.id 2222-------------',
                //     props.userDetails.userdetails.id,
                // )
                if (bidding.bpop_cbidder == props.userDetails.userdetails.id) {
                    if (bidding.status == 'failed') {
                        bidding.error && toast.warning(bidding.error)
                    } else if (bidding.status == 'success') {
                        product['wprice'] = bidding.bpop_wprice
                        // console.log('bidding.bpop_higher ---------------', bidding.bpop_higher)
                        // console.log(
                        //     'props.userDetails.userdetails.id ------------',
                        //     props.userDetails.userdetails.id,
                        // )
                        if (bidding.bpop_higher == props.userDetails.userdetails.id) {
                            product['next_bid'] = bidding.bpop_nextbid
                        } else {
                            product['next_bid'] = bidding.bpop_nextbid_org
                        }
                        product['nobids'] = bidding.bpop_bidcount
                        product['increment'] = bidding.bpop_cuser_increment
                        if (product.userBidCount === undefined || bidding.userBidCount === 0) {
                            product['userBidCount'] = 1
                        }
                        auctionLots[productPosition] = product
                        Object.keys(data).length !== 0 && setData({ ...product })
                        setAuctionLots([...auctionLots])
                        if (bidding.bpop_error) {
                            toast.success(bidding.bpop_error)
                        }
                        if (bidding.bpop_bidstatus) {
                            toast.info(bidding.bpop_bidstatus)
                        }
                    }
                } else {
                    console.log('in else part')
                    if (bidding.status !== 'failed') {
                        if (product.userBidCount !== undefined && product.userBidCount !== 0) {
                            // console.log('bidding.bpop_higher 1111------------', bidding.bpop_higher)
                            // console.log(
                            //     'props.userDetails.userdetails.id 1111================',
                            //     props.userDetails.userdetails.id,
                            // )
                            if (bidding.bpop_higher != props.userDetails.userdetails.id) {
                                // console.log('bidding.bpop_wprice ========', bidding.bpop_wprice)
                                // console.log('product.next_bid =============', product.next_bid)
                                if (Number(bidding.bpop_wprice) >= Number(product.next_bid)) {
                                    product['next_bid'] = bidding.bpop_nextbid_org
                                    // product['next_bid'] = bidding.bpop_wprice_morehigh
                                }
                                product['wprice'] = bidding.bpop_wprice
                                auctionLots[productPosition] = product
                                product['nobids'] = bidding.bpop_bidcount
                                product['increment'] = bidding.bpop_increment
                                Object.keys(data).length !== 0 && setData({ ...product })
                                setAuctionLots([...auctionLots])
                                toast.info('You are losing')
                            } else {
                                product['wprice'] = bidding.bpop_wprice
                                if (bidding.bpop_higher == props.userDetails.userdetails.id) {
                                    product['next_bid'] =
                                        bidding.bpop_wprice_morehigh + bidding.bpop_increment
                                } else {
                                    product['next_bid'] = bidding.bpop_nextbid_org
                                }
                                product['nobids'] = bidding.bpop_bidcount
                                product['increment'] = bidding.bpop_increment
                                auctionLots[productPosition] = product
                                Object.keys(data).length !== 0 && setData({ ...product })
                                setAuctionLots([...auctionLots])
                            }
                        } else {
                            // product['next_bid'] = bidding.bpop_nextbid_org
                            // console.log('bidding.bpop_higher ------------', bidding.bpop_higher)
                            // console.log(
                            //     'props.userDetails.userdetails.id================',
                            //     props.userDetails.userdetails.id,
                            // )
                            if (bidding.bpop_higher == props.userDetails.userdetails.id) {
                                product['next_bid'] =
                                    bidding.bpop_wprice_morehigh + bidding.bpop_increment
                            } else {
                                product['next_bid'] = bidding.bpop_nextbid_org
                            }
                            product['wprice'] = bidding.bpop_wprice
                            product['nobids'] = bidding.bpop_bidcount
                            product['increment'] = bidding.bpop_increment
                            auctionLots[productPosition] = product
                            Object.keys(data).length !== 0 && setData({ ...product })
                            setAuctionLots([...auctionLots])
                        }
                    }
                }
            }
        }
    }, [props.bidding])
    const addFav = async (id, isFav) => {
        if (isAuthenticate()) {
            let data = await updateFav({ id: id, userid: props.userDetails.userdetails.id }, isFav)
            if (data) {
                let position = findIndex(auctionLots, { id: id })
                if (position !== -1) {
                    let dataFav = auctionLots[position]
                    dataFav = { ...dataFav, isFav: isFav === 0 ? 1 : 0 }
                    auctionLots[position] = dataFav
                    if (Object.keys(data).length !== 0) {
                        setData(dataFav)
                    }
                    setAuctionLots([...auctionLots])
                }
            }
        } else {
            history.push({ pathname: '/login', state: props.location })
        }
    }

    const toggleDrawerSearch = (event, data) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return
        }
        if (Object.keys(data) !== 0) {
            setData(data)
        } else {
            setData({})
        }
    }

    const handleBidding = async (data) => {
        let { datas } = await getLotData(data.lotof)
        if (datas && datas.success === true) {
            handleClick(
                {
                    id: data.id,
                    wsprice: amount[data.id],
                    bid_increment: data.incrementamt,
                },
                parseFloat(data.next_bid),
            )
        }
    }

    return (
        <>
            {/* <div className="filterMb">
                {['left'].map((anchor) => (
                    <React.Fragment key={anchor}>
                        <Button onClick={toggleDrawer(anchor, true)}>
                            <span className="material-icons">tune</span> Filter
                        </Button>
                        <Drawer
                            anchor={anchor}
                            open={state[anchor]}
                            onClose={toggleDrawer(anchor, false)}
                        >
                            {list(anchor)}
                        </Drawer>
                    </React.Fragment>
                ))}
            </div> */}
            <div className="SearchCnt d-flex customContainer">
                <div className="filterWrp">
                    <div className="title">Filter</div>
                    {Array.isArray(props.category.category) &&
                        props.category.category.map((data, index) => (
                            <Filter
                                title={data.description}
                                label={data.filters}
                                disabled_filter={1}
                                key={index}
                                sub_cat_id={body !== undefined ? body.sub_cat_id : []}
                                cat_id={body !== undefined ? body.custom_field_3 : []}
                            />
                        ))}
                </div>
                <div className="searchPdtCnt">
                    <div className="searchTopCnt d-flex align-items-center justify-content-between">
                        {/* {totalCount !== '' && ( */}
                        {props.location.search && searchQry?.search ? (
                            <p className="result">
                                Showing{' '}
                                {auctionLots.length ? <>{totalCount != '' ? totalCount : 0}</> : 0}{' '}
                                results
                            </p>
                        ) : (
                            <p className="result">
                                Showing{' '}
                                {auctionSearch.length ? (
                                    <>{totalCount != '' ? totalCount : 0}</>
                                ) : (
                                    0
                                )}{' '}
                                results
                            </p>
                        )}

                        {/* )} */}
                        <div className="topcntSearchWrp d-flex">
                            <Button onClick={() => setView(true)}>
                                <span class={`material-icons ${view ? 'active' : ''}`}>apps</span>
                            </Button>
                            <Button onClick={() => setView(false)}>
                                <span class={`material-icons ${!view ? 'active' : ''}`}>
                                    format_list_bulleted
                                </span>
                            </Button>
                            <div className="resultPrePage d-flex align-items-center">
                                <p>Results per page :</p>
                                {props.location.search && searchQry?.search ? (
                                    <Commondropdown
                                        selected={
                                            body ? body.limit : searchResultCountround[0]['id']
                                        }
                                        values={searchResultCountround}
                                        name="limit"
                                        handleChange={handleChange}
                                    />
                                ) : (
                                    <Commondropdown
                                        selected={body ? body.sh_limit : searchResultCount[0]['id']}
                                        values={searchResultCount}
                                        name="limit"
                                        handleChange={handleChange}
                                    />
                                )}
                            </div>
                            {console.log('props.location.search', props.location.search)}
                            {props.location.search && searchQry.search ? (
                                <></>
                            ) : (
                                <div className="resultPrePage d-flex align-items-center">
                                    <p>Sort By :</p>
                                    <Commondropdown
                                        selected={body ? body.orderby : sortBy[0]['id']}
                                        values={sortBy}
                                        name="orderby"
                                        handleChange={handleChange}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="srchVwDtlsCntnr">
                        {props.location.search && searchQry?.search ? (
                            <>
                                {Loading ? (
                                    <div className="mnHtcontnr">
                                        <div className="liveLotsLoader">
                                            <Loaders name="live_auction" isLoading={Loading} />
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        {auctionLots.length ? (
                                            <>
                                                {view ? (
                                                    <div className="lotGrid customContainer mt-4">
                                                        {auctionLots.map((data, index) => (
                                                            <GridView
                                                                imgSrc={
                                                                    data.avatar_vc
                                                                        ? `${
                                                                              process.env
                                                                                  .REACT_APP_PRODUCT_IMG +
                                                                              data?.lotImages[0]
                                                                                  ?.file_name
                                                                          }`
                                                                        : '/images/pdtimg.png'
                                                                }
                                                                Name={data.title}
                                                                con_check={data.con_check}
                                                                currentPrice={data.wprice}
                                                                label={'View Auction'}
                                                                timerBasic={true}
                                                                bidding_hide={data.bidding_hide}
                                                                onClick={() => {
                                                                    handleRedirectInternal(
                                                                        history,
                                                                        data.con_check
                                                                            ? `lotview/${
                                                                                  data.lotof
                                                                              }/${data.id}/${
                                                                                  props.userDetails
                                                                                      .userdetails
                                                                                      .id || 0
                                                                              }?page=${parseInt(
                                                                                  body.page,
                                                                              )}&scrollLocation=${
                                                                                  window.pageYOffset
                                                                              }`
                                                                            : `timedlotView/${
                                                                                  data.lotof
                                                                              }/${data.id}/${
                                                                                  props.userDetails
                                                                                      .userdetails
                                                                                      .id || 0
                                                                              }?page=${parseInt(
                                                                                  body.page,
                                                                              )}&scrollLocation=${
                                                                                  window.pageYOffset
                                                                              }`,
                                                                    )
                                                                }}
                                                                id={data.lotof}
                                                                lotId={data.sku}
                                                                startTime={data.date_added}
                                                                endTime={data.date_closed}
                                                                lotBidding={
                                                                    <div className="placeBid gridViewbtn">
                                                                        <CustomInput
                                                                            placeholder={`Enter bid`}
                                                                            label={`Enter bid`}
                                                                            variant="outlined"
                                                                            onChange={
                                                                                handleOnChange
                                                                            }
                                                                            pattern="^\d*(.\d{0,2})?$"
                                                                            name={String(data.id)}
                                                                            value={
                                                                                amount[data.id] ||
                                                                                ''
                                                                            }
                                                                            onKeyPress={(e) =>
                                                                                onKeyPress(
                                                                                    e,
                                                                                    data.id,
                                                                                )
                                                                            }
                                                                        />
                                                                        <PrimaryButton
                                                                            label="Place Max Bid"
                                                                            disabled={
                                                                                isAuthenticate() ===
                                                                                    undefined ||
                                                                                amount[data.id] ===
                                                                                    undefined ||
                                                                                amount[data.id] ===
                                                                                    ''
                                                                            }
                                                                            onClick={(e) => {
                                                                                handleBidding(data)
                                                                            }}
                                                                        />
                                                                    </div>
                                                                }
                                                                highbid_user_id={
                                                                    data.highbid_user_id
                                                                }
                                                                userDetails={props.userDetails}
                                                            />
                                                        ))}
                                                    </div>
                                                ) : (
                                                    <div className="lotPdtWrp customContainer">
                                                        <table className="table tableCnt">
                                                            <thead className="tableHeader">
                                                                <tr>
                                                                    <th>Lot #</th>
                                                                    <th>Image</th>
                                                                    <th>Title</th>
                                                                    <th>Description</th>
                                                                    {/* <th>
                                                                Closing <br /> At
                                                            </th> */}
                                                                    <th>
                                                                        Current <br /> Bid
                                                                    </th>
                                                                    <th>
                                                                        No .Of <br /> Bids
                                                                    </th>
                                                                    {/* <th>Action</th> */}
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {auctionLots.map((data, index) => (
                                                                    <tr key={data.id}>
                                                                        <td className="slNo">
                                                                            <span
                                                                                className="cursor"
                                                                                onClick={() => {
                                                                                    handleRedirectInternal(
                                                                                        history,
                                                                                        data.con_check
                                                                                            ? `lotview/${
                                                                                                  data.lotof
                                                                                              }/${
                                                                                                  data.id
                                                                                              }/${
                                                                                                  props
                                                                                                      .userDetails
                                                                                                      .userdetails
                                                                                                      .id ||
                                                                                                  0
                                                                                              }?page=${parseInt(
                                                                                                  body.page,
                                                                                              )}&scrollLocation=${
                                                                                                  window.pageYOffset
                                                                                              }`
                                                                                            : `timedlotView/${
                                                                                                  data.lotof
                                                                                              }/${
                                                                                                  data.id
                                                                                              }/${
                                                                                                  props
                                                                                                      .userDetails
                                                                                                      .userdetails
                                                                                                      .id ||
                                                                                                  0
                                                                                              }?page=${parseInt(
                                                                                                  body.page,
                                                                                              )}&scrollLocation=${
                                                                                                  window.pageYOffset
                                                                                              }`,
                                                                                    )
                                                                                }}
                                                                            >
                                                                                {data.sku}
                                                                            </span>
                                                                        </td>
                                                                        <td className="lotImg">
                                                                            <div
                                                                                className="imgBtn"
                                                                                onClick={() => {
                                                                                    handleRedirectInternal(
                                                                                        history,
                                                                                        data.con_check
                                                                                            ? `lotview/${
                                                                                                  data.lotof
                                                                                              }/${
                                                                                                  data.id
                                                                                              }/${
                                                                                                  props
                                                                                                      .userDetails
                                                                                                      .userdetails
                                                                                                      .id ||
                                                                                                  0
                                                                                              }?page=${parseInt(
                                                                                                  body.page,
                                                                                              )}&scrollLocation=${
                                                                                                  window.pageYOffset
                                                                                              }`
                                                                                            : `timedlotView/${
                                                                                                  data.lotof
                                                                                              }/${
                                                                                                  data.id
                                                                                              }/${
                                                                                                  props
                                                                                                      .userDetails
                                                                                                      .userdetails
                                                                                                      .id ||
                                                                                                  0
                                                                                              }?page=${parseInt(
                                                                                                  body.page,
                                                                                              )}&scrollLocation=${
                                                                                                  window.pageYOffset
                                                                                              }`,
                                                                                    )
                                                                                }}
                                                                            >
                                                                                <img
                                                                                    alt={data.title}
                                                                                    src={
                                                                                        process.env
                                                                                            .REACT_APP_PRODUCT_IMG +
                                                                                        data
                                                                                            ?.lotImages[0]
                                                                                            ?.file_name
                                                                                    }
                                                                                    onError={
                                                                                        onImageError
                                                                                    }
                                                                                />
                                                                            </div>
                                                                        </td>
                                                                        <td className="cat">
                                                                            <a
                                                                                className="cursor"
                                                                                onClick={() => {
                                                                                    handleRedirectInternal(
                                                                                        history,
                                                                                        data.con_check
                                                                                            ? `lotview/${
                                                                                                  data.lotof
                                                                                              }/${
                                                                                                  data.id
                                                                                              }/${
                                                                                                  props
                                                                                                      .userDetails
                                                                                                      .userdetails
                                                                                                      .id ||
                                                                                                  0
                                                                                              }?page=${parseInt(
                                                                                                  body.page,
                                                                                              )}&scrollLocation=${
                                                                                                  window.pageYOffset
                                                                                              }`
                                                                                            : `timedlotView/${
                                                                                                  data.lotof
                                                                                              }/${
                                                                                                  data.id
                                                                                              }/${
                                                                                                  props
                                                                                                      .userDetails
                                                                                                      .userdetails
                                                                                                      .id ||
                                                                                                  0
                                                                                              }?page=${parseInt(
                                                                                                  body.page,
                                                                                              )}&scrollLocation=${
                                                                                                  window.pageYOffset
                                                                                              }`,
                                                                                    )
                                                                                }}
                                                                            >
                                                                                {data.title}
                                                                            </a>
                                                                        </td>
                                                                        <td className="decs">
                                                                            <div className="onclisk">
                                                                                <Typography
                                                                                    aria-owns={
                                                                                        open
                                                                                            ? 'mouse-over-popover'
                                                                                            : undefined
                                                                                    }
                                                                                    aria-haspopup="true"
                                                                                    onMouseEnter={(
                                                                                        e,
                                                                                    ) =>
                                                                                        handlePopoverOpen(
                                                                                            e,
                                                                                            data.description,
                                                                                        )
                                                                                    }
                                                                                    onMouseLeave={
                                                                                        handlePopoverClose
                                                                                    }
                                                                                >
                                                                                    {' '}
                                                                                    {
                                                                                        <p
                                                                                            className="listPdtDescrp"
                                                                                            dangerouslySetInnerHTML={{
                                                                                                __html: data.description,
                                                                                            }}
                                                                                        ></p>
                                                                                    }{' '}
                                                                                </Typography>
                                                                                <b
                                                                                    onMouseEnter={(
                                                                                        e,
                                                                                    ) =>
                                                                                        handlePopoverOpen(
                                                                                            e,
                                                                                            data.description,
                                                                                        )
                                                                                    }
                                                                                    onMouseLeave={
                                                                                        handlePopoverClose
                                                                                    }
                                                                                >
                                                                                    read more
                                                                                </b>
                                                                            </div>
                                                                        </td>
                                                                        {/* <td className="lotTime">
                                                                    <Timer
                                                                        productTime={{
                                                                            start: moment()
                                                                                .subtract(1, 'hour')
                                                                                .format(),
                                                                            end: data.date_closed,
                                                                        }}
                                                                    />
                                                                </td> */}
                                                                        <td className="CurrentPrice">
                                                                            ${' '}
                                                                            {setDecimalPoint(
                                                                                data.wprice,
                                                                                2,
                                                                            )}
                                                                        </td>
                                                                        <td className="bids">
                                                                            {data.nobids}
                                                                            {/* <a
                                                                        onClick={(e) =>
                                                                            handleHistory(data.id)
                                                                        }
                                                                    >
                                                                        {data.nobids}
                                                                    </a> */}
                                                                        </td>
                                                                        {/* <td>
                                                                    <div className="placeBid d-flex justify-content-end align-items-end">
                                                                        <CustomInput
                                                                            placeholder={`Enter $${setDecimalPoint(
                                                                                parseFloat(
                                                                                    data.next_bid,
                                                                                ),
                                                                                2,
                                                                            )} or more`}
                                                                            variant="standard"
                                                                            onChange={
                                                                                handleOnChange
                                                                            }
                                                                            pattern="^\d*(.\d{0,2})?$"
                                                                            name={String(data.id)}
                                                                            value={
                                                                                amount[data.id] ||
                                                                                ''
                                                                            }
                                                                            onKeyPress={(e) =>
                                                                                onKeyPress(
                                                                                    e,
                                                                                    data.id,
                                                                                )
                                                                            }
                                                                        />
                                                                        <PrimaryButton
                                                                            label="Place Max Bid"
                                                                            disabled={
                                                                                isAuthenticate() ===
                                                                                    undefined ||
                                                                                amount[data.id] ===
                                                                                    undefined ||
                                                                                amount[data.id] ===
                                                                                    ''
                                                                            }
                                                                            onClick={(e) =>
                                                                                handleClick(
                                                                                    {
                                                                                        id: data.id,
                                                                                        wsprice:
                                                                                            amount[
                                                                                                data
                                                                                                    .id
                                                                                            ],
                                                                                        bid_increment:
                                                                                            data.incrementamt,
                                                                                    },
                                                                                    parseFloat(
                                                                                        data.next_bid,
                                                                                    ),
                                                                                )
                                                                            }
                                                                        />
                                                                    </div>
                                                                </td> */}
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                )}
                                            </>
                                        ) : (
                                            <div className="noPdtsFnd text-center">
                                                <span className="material-icons">search</span>
                                                <p>No Products Found</p>
                                            </div>
                                        )}
                                    </>
                                )}
                            </>
                        ) : (
                            <>
                                {auctionSearch.length ? (
                                    <>
                                        {view ? (
                                            <div className="searchResult">
                                                {auctionSearch.map((data, index) => (
                                                    <GridView
                                                        imgSrc={
                                                            process.env.REACT_APP_PRODUCT_IMG +
                                                            data.file_name
                                                        }
                                                        Name={data.ptitle}
                                                        descrp={data.description}
                                                        label="View Auction"
                                                        id={data.id}
                                                        startTime={data.date_added}
                                                        endTime={data.date_closed}
                                                        highbid_user_id={data.highbid_user_id}
                                                        userDetails={props.userDetails}
                                                        con_check={data.con_check}
                                                        bidding_hide={data.bidding_hide}
                                                    />
                                                ))}
                                            </div>
                                        ) : (
                                            <div className="searchList">
                                                {auctionSearch.map((data, index) => (
                                                    <ListView
                                                        imgSrc={
                                                            process.env.REACT_APP_PRODUCT_IMG +
                                                            data.file_name
                                                        }
                                                        category={''}
                                                        Name={data.ptitle}
                                                        Start={data.date_added}
                                                        End={data.date_closed}
                                                        descrp={data.description}
                                                        label="View Auction"
                                                        id={data.id}
                                                        con_check={data.con_check}
                                                    />
                                                ))}
                                            </div>
                                        )}
                                    </>
                                ) : (
                                    <div className="noPdtsFnd text-center">
                                        <span className="material-icons">search</span>
                                        <p>No Products Found</p>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                    <div className="pageNo">
                        {totalCount !== '' &&
                            Object.keys(body).length !== 0 &&
                            parseInt(body.limit) != 5000 && (
                                <Pagination
                                    count={
                                        props.location.search && searchQry.search
                                            ? getPaginationCount(totalCount, body.limit)
                                            : getPaginationCount(totalCount, body.sh_limit)
                                    }
                                    onChange={(e, value) => {
                                        let query = new URLSearchParams(props.location.search)
                                        query.set('page', value)
                                        history.push({
                                            pathname: props.location.pathname,
                                            search: '?' + query.toString(),
                                        })
                                        window.scrollTo(0, 0)
                                    }}
                                    page={parseInt(body.page)}
                                />
                            )}
                    </div>
                </div>
            </div>
            <Popover
                id="mouse-over-popover"
                className={classes.popover}
                classes={{
                    paper: classes.paper,
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <Typography>{popText}</Typography>
            </Popover>
            <ProductViewSlider
                onCloseClick={(e) => toggleDrawerSearch(e, {})}
                data={data}
                isOpen={Object.keys(data) != 0}
                addFav={addFav}
                amount={amount}
                handleOnChange={handleOnChange}
                onKeyPress={onKeyPress}
                handleClick={handleClick}
                handleHistory={handleHistory}
            />
            <BidHistory
                open={historyId !== undefined}
                productId={historyId}
                onClose={handleHistory}
            />
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        category: state.category ? state.category : {},
        userDetails: state.User ? state.User : {},
        bidding: state.bidding ? state.bidding : {},
        serverTime: state.serverTime ? state.serverTime.time : '',
    }
}

export default connect(mapStateToProps, null)(Search)
