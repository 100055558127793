import openSocket from 'socket.io-client'
// import openSocketForward from 'socket.io-client-latest'

//const socket = openSocket(process.env.REACT_APP_DOMAIN)
let socket = openSocket(process.env.REACT_APP_DOMAIN, {
    reconnection: true,
    reconnectionDelay: 500,
    reconnectionAttempts: 10,
    transports: ['websocket', 'polling'],
})

console.log('process.env.REACT_SELLER_DOMAIN', process.env, process.env.REACT_APP_SELLER_DOMAIN)

let socketSeller = openSocket(process.env.REACT_APP_SELLER_DOMAIN, {
    reconnection: true,
    reconnectionDelay: 500,
    reconnectionAttempts: 10,
    transports: ['websocket', 'polling'],
})
// let socketForward = openSocketForward(`${process.env.REACT_APP_DOMAIN}`, {
//     reconnection: true,
//     reconnectionDelay: 500,
//     reconnectionAttempts: 10,
//     transports: ['websocket', 'polling'],
// })
// let socketForward = openSocketForward(`${process.env.REACT_APP_FORWARD_DOMAIN}/`, {
//     transports: ['websocket'],
// })

// socketForward.on('connect', function () {
//     console.log('Socket Forward Connected')
// })

socket.on('connect', function () {
    console.log('Socket Connected')
})

socketSeller.on('connect', function () {
    console.log('Socket Connected socket seller')
})

function bidAddtime(cb) {
    socket.on('bidAddtime', (data) => {
        console.log('bid add time main socket listener', data)
        cb(data)
    })
    socketSeller.on('bidAddtime', (data) => {
        console.log('bid add time main socket listener', data)
        cb(data)
    })
}

function realClosedUpdates(cb) {
    socket.on('realclosedupdates', (data) => {
        cb(data)
    })
}

function refreshbuyer(cb) {
    socket.on('refreshbuyer', (data) => {
        cb(data)
    })
}
function refreshbuyerOff() {
    socket.off('refreshbuyer')
}
function bid_extended(cb) {
    socket.on('bid_extended', (data) => {
        cb(data)
    })
}
function bid_extendedOff() {
    socket.off('bid_extended')
}

function reInitializeSocket() {
    socket.close()
    socket = openSocket(`${process.env.REACT_APP_DOMAIN}/`)
}

// socketForward.on('disconnect', function (error) {
//     console.log('Socket Forward Disconnected', error)
// })

socket.on('disconnect', function (error) {
    console.log('Socket Disconnected', error)
})

export {
    bidAddtime,
    realClosedUpdates,
    refreshbuyer,
    socket,
    socketSeller,
    // socketForward,
    refreshbuyerOff,
    bid_extended,
    bid_extendedOff,
    reInitializeSocket,
}
