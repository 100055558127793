import React from 'react'
import SecondaryButton from '../../components/atoms/SecondaryButton/index'
import { isEmpty, findIndex } from 'lodash'
import moment from 'moment'
import './payment.css'

const Payment = (props) => {
    const data = ['$ 1069', '$ 1069', '$ 1069', '$ 1069', '$ 1069', '$1069']

    return (
        <div>
            <div className="payment--Table">
                <table className="table">
                    <thead className="thead-light">
                        <tr>
                            <th scope="col">ID</th>
                            <th scope="col">Auction Name</th>
                            <th scope="col">Category</th>
                            <th scope="col">Paid On</th>
                            <th scope="col">Price</th>
                            {/* <th scope="col">Action</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {!isEmpty(props.data) ? (
                            props.data.map((data) => (
                                <tr>
                                    <th scope="row">{data.transid}</th>
                                    <td>{data.title}</td>
                                    <td>
                                        {' '}
                                        {data.buynow_autype == 'buynowlisting' && (
                                            <div>Seller - BuyNow Listing</div>
                                        )}
                                        {data.buynow_autype == 'auction' && <div>Auction Won</div>}
                                        {data.buynow_autype == 'auctionlisting' && (
                                            <div>Seller - Auction Listing</div>
                                        )}
                                    </td>
                                    <td>{moment(data.pay_date).format('MM/DD/YYYY')}</td>
                                    <td>{'$ ' + data.amount}</td>
                                    {/* <td>
                                        <SecondaryButton label="View" />
                                    </td> */}
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colspan="6" className="border-none">
                                    <div className="card card-body text-center mt-2">
                                        <b>No Transaction Found</b>
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default Payment
