import React, { useState, useEffect } from 'react'
import CustomInput from '../../components/atoms/Inputs/CustomInput'
import PrimaryButton from '../../components/atoms/PrimaryButton/index'
import { Formik } from 'formik'
import * as yup from 'yup'
import axios from '../../Utils/Utility/axiosconfig'
import './Forgotpassword.css'
import Swal from 'sweetalert2'
import { useHistory } from 'react-router-dom'
var qs = require('qs')

const Forgotpassword = (props) => {
    const history = useHistory()
    const userid = qs.parse(props.location.search, { ignoreQueryPrefix: true }).id
    useEffect(() => {
        console.log(userid)
    })

    const forgotpassapi = async (data) => {
        try {
            const userid = qs.parse(props.location.search, { ignoreQueryPrefix: true }).id
            const res = await axios.post('mobileapi/change-password', {
                user_token: userid,
                password: data.password,
            })
            if (res.data.success === 'yes') {
                Swal.fire({
                    icon: 'success',
                    title: 'Password Changed Successfully',
                    timer: 1500,
                    showConfirmButton: false,
                    position: 'center',
                })
                history.push('/login')
            }
        } catch (error) {
            console.log(error)
        }
    }

    const forgotPasswordSchema = yup.object({
        password: yup.string().min(8, 'Minimum 8 characters').required('Password is required'),
        confirmpassword: yup
            .string()
            .min(8, 'Minimum 8 characters')
            .oneOf([yup.ref('password'), null], 'Password must match')
            .required('Confirm password is required'),
    })

    return (
        <div>
            <Formik
                initialValues={{ password: '', confirmpassword: '' }}
                validationSchema={forgotPasswordSchema}
                onSubmit={(values) => {
                    forgotpassapi(values)
                }}
            >
                {({ errors, touched, values, handleChange, handleSubmit, handleBlur }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="Banner-Forgotpassword">
                            <div className="forgotpassImg">
                                <img src="/images/pdtimg.png" />
                            </div>
                            <div className="forgotpassDetails custom-container-forgotPassword">
                                <img src="/images/coin-auction-logo.png" />
                                <h3>Forgot Password</h3>
                                <div className="forgPassInp">
                                    <CustomInput
                                        label="Password"
                                        variant="standard"
                                        name="password"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        type="password"
                                    />
                                    {errors.password && touched.password ? (
                                        <div className="forgotPassError">{errors.password}</div>
                                    ) : null}
                                    <CustomInput
                                        label="Confirm Password"
                                        variant="standard"
                                        name="confirmpassword"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        type="password"
                                    />
                                    {errors.confirmpassword && touched.confirmpassword ? (
                                        <div className="forgotPassError">
                                            {errors.confirmpassword}
                                        </div>
                                    ) : null}
                                </div>
                                <PrimaryButton label="Reset Password" type="submit" />
                            </div>
                        </div>
                    </form>
                )}
            </Formik>
        </div>
    )
}

export default Forgotpassword
