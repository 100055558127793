import React from 'react'
import './CheckoutCard.css'
import { currencyFormat } from '../../../common/components'
import Tooltip from '@material-ui/core/Tooltip'
function CheckoutCard(props) {
    return (
        <div className="cardHolder">
            <div className="checkoutCardCnt d-flex">
                <img
                    src={`${process.env.REACT_APP_PRODUCT_IMG + props.data.avatar}`}
                    onError={(e) => (e.target.src = '/images/pdtimg.png')}
                />
                <div className="checkoutCntWrp">
                    <div className="checkoutTitle">
                        <p className="checkoutPdtTitle">Title</p>
                        <p
                            className="checkoutPdtName"
                            dangerouslySetInnerHTML={{
                                __html: props.data.title,
                            }}
                        ></p>
                    </div>
                    <div className="checkoutPrice">
                        <p className="priceTitle">Amount</p>
                        <p className="checkoutPdtPrice">
                            {currencyFormat(parseFloat(props.data.amount))}
                        </p>
                    </div>
                    {parseInt(props.data.buyers_premium_percentage) != 0 ? (
                        <div className="checkoutPrice">
                            <p className="priceTitle">{`Buyer's Premium (${
                                props.data.buyers_premium_percentage + props.data.charge_percentage
                            } %)`}</p>
                            <p className="checkoutPdtPrice">
                                {' '}
                                {currencyFormat(
                                    parseFloat(props.data.buyers_premium + props.data.charge_fee),
                                )}
                            </p>
                        </div>
                    ) : (
                        ''
                    )}
                    {parseInt(props.data.sales_tax_percentage) != 0 ? (
                        <div className="checkoutPrice">
                            <p className="priceTitle">{`Sales Tax (${props.data.sales_tax_percentage} %)`}</p>
                            <p className="checkoutPdtPrice">
                                {' '}
                                {currencyFormat(parseFloat(props.data.sales_tax))}
                            </p>
                        </div>
                    ) : (
                        ''
                    )}
                    {props.data.transaction_label != '0%' ? (
                        <div className="checkoutPrice">
                            <div className="d-flex align-items-center">
                                <p className="priceTitle mr-2">{`Credit Card Fee (${props.data.transaction_label})`}</p>{' '}
                                {/* <Tooltip
                                    placement="top"
                                    title={
                                        <div
                                            style={{
                                                // width: '300px',
                                                // padding: '5px',
                                                fontSize: '16px',
                                                lineHeight: '24px',
                                            }}
                                        >
                                            Feeless ACH/Bank Transfer Also Available.
                                        </div>
                                    }
                                    arrow
                                >
                                    <span className="material-icons">info</span>
                                </Tooltip> */}
                            </div>
                            <p className="checkoutPdtPrice">
                                {' '}
                                {currencyFormat(parseFloat(props.data.transaction_fee))}
                            </p>
                        </div>
                    ) : (
                        ''
                    )}
                    {parseInt(props.data.shipping_fee) > 0 ? (
                        <div className="checkoutPrice">
                            <p className="priceTitle">{`Shipping Fee ${
                                props.data.shipping_type == 1 && props.data.hasshipping == 1
                                    ? `(${props.data.shipping_percentage} %)`
                                    : ''
                            }`}</p>
                            <p className="checkoutPdtPrice">
                                {' '}
                                {currencyFormat(
                                    parseFloat(
                                        props.data.hasshipping ? props.data.shipping_fee : 0,
                                    ),
                                )}
                            </p>
                        </div>
                    ) : (
                        <div className="checkoutPrice">
                            <p className="priceTitle">{`Shipping
                               `}</p>
                            <p className="checkoutPdtPrice">{`FREE`}</p>
                        </div>
                    )}
                    <div className="checkoutPrice">
                        <p className="priceTitle">{`Item Subtotal`}</p>
                        <p className="checkoutPdtPrice">
                            {' '}
                            {currencyFormat(parseFloat(props.data.total_payable_amount))}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CheckoutCard
