import React from 'react'
import { Button } from '@material-ui/core'
import PrimaryButton from '../../components/atoms/PrimaryButton/index'
import './paymentCards.css'

const PaymentCards = (props) => {
    return (
        <div className="parentCard">
            <>
                <div className="cardBackground">
                    {props.value.brand == 'Visa' ? (
                        <img src="/images/Visa.png" alt="cards" />
                    ) : props.value.brand == 'American Express' ? (
                        <img src="/images/Americanexpress.svg" alt="cards" />
                    ) : (
                        <img src="/images/Master.png" alt="cards" />
                    )}
                    <div className="cardsData">
                        <div className="cardcontentwrap">
                            <h4>*&emsp;*&emsp;*</h4>
                            <p>
                                Expires :
                                {String(props.value.exp_month).length < 2
                                    ? `0` + props.value.exp_month + '/' + props.value.exp_year
                                    : props.value.exp_month + '/' + props.value.exp_year}
                            </p>
                            <p>
                                xxxx &emsp;-&emsp;xxxx&emsp;-&emsp;xxxx&emsp;-&emsp;
                                {props.value.last4}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-between">
                    {/* <PrimaryButton
                        label={
                            props.selectedcard.token_id === props.value.id
                                ? 'Primary Card'
                                : 'Make Primary'
                        }
                        type="button"
                        onClick={() => {
                            const mkprData = {
                                cardId: props.value.id,
                                card: `xxxx-xxxx-xxxx-${props.value.last4}`,
                                activate: 'activate',
                                expiration_month: props.value.exp_month,
                                year: props.value.exp_year,
                                month: props.value.exp_month,
                                cardType: props.value.brand,
                                mode: process.env.REACT_APP_PAYMENTMODE,
                                id: props.selectedcard.id,
                            }

                            if (props.pr) {
                                props.pr(mkprData)
                            }
                        }}
                    /> */}

                    <button
                        onClick={() => {
                            props.del(props.value.id)
                        }}
                        className="btn card-del deleteCard material-icons"
                    >
                        delete
                    </button>
                </div>
            </>
        </div>
    )
}

export default PaymentCards
