import axios from '../Utils/Utility/axiosconfig'
import isAuthenticate from '../Utils/Utility/auth'

export const UserUnpaidAction = (list) => ({
    type: 'USER_UNPAID',
    list,
})

export const UserUnpaid = (id) => {
    return async (dispatch) => {
        try {
            console.log(isAuthenticate(), '===')
            if (isAuthenticate()) {
                const res = await axios.get(
                    `mobileapi/userDashboardCount/${id}?date_added=${new Date(
                        '12/16/2023 00:00:00',
                    )}`,
                )
                if (res.data.success === 'yes') {
                    dispatch(UserUnpaidAction(res.data))
                } else {
                    return `something went wrong`
                }
            }
        } catch (error) {
            console.log(error)
        }
    }
}
