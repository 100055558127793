import React from 'react'
import FavoriteCheckbox from '../../components/atoms/FavoriteCheckbox/index'
import { useHistory } from 'react-router-dom'
import { handleRedirectInternal } from '../../common/components'
import Gridview from '../../components/molecules/ProductCard/GridView'
import './Favouritecard.css'

const Favouritecard = (props) => {
    const history = useHistory()
    return (
        <div className="Favflex">
            <div className="fawWrap">
                <div className="Favbox-Favflex">
                    <FavoriteCheckbox
                        watchlisted={props.data.watchlisted}
                        project_id={props.data.id}
                        dummy="dummy"
                        onchangedata={props.favour}
                        index={props.index}
                        favIdChild={props.data.id}
                    />
                </div>
                <div className="grdViewFav">
                    <Gridview
                        imgSrc={
                            props.data.avatar
                                ? `${process.env.REACT_APP_PRODUCT_IMG + props.data.avatar}`
                                : props.data.avatar_vc
                                ? `${process.env.REACT_APP_PRODUCT_IMG + props.data.avatar_vc}`
                                : '/images/pdtimg.png'
                        }
                        Name={props.data.title}
                        // descrp={props.data.category_id}
                        lotId={props.data.sku}
                        label={'View Lot'}
                        id={props.data.lotof}
                        startTime={props.data.date_added}
                        endTime={props.data.date_closed}
                        descrp={props.data.description}
                        is_fav={true}
                        onClick={() => {
                            if (props.data.con_check) {
                                handleRedirectInternal(
                                    history,
                                    `lotview/${props.data.lotof}/${props.data.id}/${
                                        props.userDetails.userdetails.id || 0
                                    }`,
                                )
                            } else {
                                handleRedirectInternal(
                                    history,
                                    `timedlotView/${props.data.lotof}/${props.data.id}/${
                                        props.userDetails.userdetails.id || 0
                                    }`,
                                )
                            }
                        }}
                        con_check={props.data.con_check}
                        bidding_hide={props.data.bidding_hide}
                    />
                </div>
            </div>
        </div>
    )
}

export default Favouritecard
