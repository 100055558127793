import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import CustomInput from '../../components/atoms/Inputs/CustomInput'
import PrimaryButton from '../../components/atoms/PrimaryButton/index'
import { Formik } from 'formik'
import * as yup from 'yup'
import valid from 'card-validator'
import axios from '../../Utils/Utility/axiosconfig'
import axiosmethod from 'axios'
import Swal from 'sweetalert2'
import isAuthenticated from '../../Utils/Utility/auth'
import './carddetails.css'
import Country from './Country.json'
import State from './State.json'
import cities from './cities.json'
import SimpleSelect from '../../components/atoms/DropDown/index'
import SimpleSelect1 from '../../components/atoms/DropDown/Dropdown1'
import SimpleSelect2 from '../../components/atoms/DropDown/Dropdown2'

const CardDetails = (props) => {
    useEffect(() => {
        if (isAuthenticated()) {
            history.push('/')
        }
    }, [])

    const [Id, setID] = useState('')
    const [sId, setStateId] = useState('')
    const [Countrycode, setCountrycode] = useState('')

    const cardDetailSchema = yup.object({
        cardnumber: yup
            .string()
            .test(
                'test-number',
                'Credit Card number is invalid',
                (value) => valid.number(value).isValid,
            )
            .required(),
        mm: yup
            .string()
            .typeError('Not a valid expiration date')
            .matches(/^(0?[1-9]|1[012])$/, 'Not a valid expiration date')
            .required('Expiration month is required'),
        yyyy: yup
            .string()
            .typeError('Not a valid expiration date')
            .matches(/^(20)\d{2}$/, 'Not a valid Expiration year')
            .required('Expiration year is required'),
        Name: yup.string().required(),
        cvv: yup.string().required(),
        zipcode: yup
            .string()
            .min(5, 'Invalid Zip Code')
            .max(6, 'Invalid Zip Code')
            .required('Zip Code Required'),
        addressline1: yup.string().required('Enter your Address1 ...!'),
        addressline2: yup.string(),
        city: yup.string(),
        state: yup.string().required('State is required'),
        country: yup.string().required('Country is required'),
    })

    const history = useHistory()

    const UserRegistration = async (data) => {
        try {
            const { cardnumber, mm, yyyy, Name, cvv, addressline1, city, state, zipcode, country } =
                data

            const det = history.location.state.personal

            // const formData = {
            //     cardNumber: cardnumber,
            //     card_name: Name,
            //     expiryMonth: mm,
            //     expiryYear: yyyy,
            //     cvv,
            //     amount: '0.50',
            //     email: det.email,
            //     mode: 'sandbox',
            // }

            const formData = {
                account_id: process.env.REACT_APP_AUCTIONPAYKEY,
                card_number: cardnumber,
                name: Name,
                card_exp_month: mm,
                card_exp_year: yyyy,
                card_cvc: cvv,
                card_name: Name,
                card_address_line1: addressline1,
                card_address_city: city,
                card_address_state: state,
                card_address_zip: zipcode,
                card_address_country: country,
                address: {
                    state: det.state,
                    city: det.city,
                    line1: det.address1,
                    line2: det.address2,
                    country: det.country,
                    postal_code: det.zip,
                },
                email: det.email,
                card_token: true,
            }

            // const resCard = await axios.post('auctionpay/validateCardStripe', formData)
            const resCard = await axiosmethod.post(
                `https://paymentapi.auctionsoftware.com/api/customer/create`,
                formData,
            )

            if (resCard.data.status != 'success') {
                return Swal.fire({
                    icon: 'error',
                    title: resCard.data.data.message,
                    timer: 1500,
                    showConfirmButton: false,
                    position: 'center',
                })
            }
            det.community = 'auction_io'
            det.card_paymentid = resCard.data.data.responseData.id
            const res = await axios.post('mobileapi/register', det)
            if (res.data.success === 'no') {
                return Swal.fire({
                    icon: 'error',
                    title: res.data.message,
                    timer: 1500,
                    showConfirmButton: false,
                    position: 'center',
                })
            }

            await history.push('/login')
            return Swal.fire({
                icon: 'success',
                title: 'Thanks for Registration',
                timer: 1500,
                showConfirmButton: false,
                position: 'center',
            })
        } catch (error) {
            console.log(error)
            Swal.fire({
                icon: 'error',
                title: 'Try again later!',
                timer: 1500,
                showConfirmButton: false,
                position: 'center',
            })
        }
    }

    return (
        <div>
            <Formik
                initialValues={{
                    cardnumber: '',
                    mm: '',
                    yyyy: '',
                    Name: '',
                    cvv: '',
                    zipcode: '',
                    addressline1: '',
                    addressline2: '',
                    country: '',
                    state: '',
                    city: '',
                }}
                validationSchema={cardDetailSchema}
                onSubmit={(values) => UserRegistration(values)}
            >
                {({ touched, errors, values, handleChange, handleSubmit, handleBlur }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="Banner-carddetails">
                            <div className="bannerImg">
                                <img src="/images/pdtimg.png" />
                            </div>
                            <div className="cardDetails-Data custom-container">
                                <div className="cardDetails-Logo">
                                    <img src="/images/coin-auction-logo.png" />
                                </div>
                                <h3>Card Details</h3>
                                <div className="cardInput">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <CustomInput
                                                label="Card Number"
                                                variant="standard"
                                                name="cardnumber"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.cardnumber}
                                                inputProps={{ maxLength: 16 }}
                                                helperText={
                                                    errors.cardnumber && touched.cardnumber
                                                        ? 'Incorrect Card Number'
                                                        : ''
                                                }
                                                type="number"
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <CustomInput
                                                label="Name On the Card"
                                                variant="standard"
                                                name="Name"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                helperText={
                                                    errors.Name && touched.Name
                                                        ? 'Please Enter your Name'
                                                        : ''
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <CustomInput
                                                label="MM"
                                                variant="standard"
                                                name="mm"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                inputProps={{ maxLength: 2 }}
                                                helperText={
                                                    errors.mm && touched.mm
                                                        ? 'Invalid Expiry Month'
                                                        : ''
                                                }
                                                type="number"
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <CustomInput
                                                label="YYYY"
                                                variant="standard"
                                                name="yyyy"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                inputProps={{ maxLength: 4 }}
                                                helperText={
                                                    errors.yyyy && touched.yyyy
                                                        ? 'Invalid Expiry Year'
                                                        : ''
                                                }
                                                type="number"
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <CustomInput
                                                label="CVV"
                                                variant="standard"
                                                name="cvv"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                inputProps={{ maxLength: 3 }}
                                                helperText={
                                                    errors.cvv && touched.cvv ? 'Invalid cvv' : ''
                                                }
                                                type="number"
                                            />
                                        </div>

                                        <div className="registration-inp col-6">
                                            <SimpleSelect
                                                dropCountr={Country}
                                                onChange={handleChange}
                                                countryID={(data) => setID(data)}
                                                ctrcode={(data) => setCountrycode(data)}
                                                value={values.country}
                                                name="country"
                                                label="Country"
                                            />

                                            {/* <DropDown
                                            variant="standard"
                                            label="Country"
                                            name="country"
                                            value={values.country}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            dropCountr={Country}
                                            countryID={(data) => setID(data)}
                                            ctrcode={(data) => setCountrycode(data)}
                                        /> */}
                                            {errors.country && touched.country ? (
                                                <div className="errorMessage-2 errorTxt">
                                                    {errors.country}
                                                </div>
                                            ) : null}
                                        </div>
                                        <div className="registration-inp col-6">
                                            <SimpleSelect1
                                                value={values.state}
                                                onChange={handleChange}
                                                countryID={(data) => setID(data)}
                                                ctrcode={(data) => setCountrycode(data)}
                                                name="state"
                                                label="State"
                                                dropState={State.filter(
                                                    (data) => data.country_id === Id.toString(),
                                                )}
                                                stateID={(data) => setStateId(data)}
                                            />
                                            {/* <DropDown
                                            variant="standard"
                                            label="State"
                                            name="state"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.state}
                                            dropState={State.filter(
                                                (data) => data.country_id === Id.toString(),
                                            )}
                                            stateID={(data) => setStateId(data)}
                                        /> */}
                                            {errors.state && touched.state ? (
                                                <div className="errorMessage-2 errorTxt">
                                                    {errors.state}
                                                </div>
                                            ) : null}
                                        </div>
                                        <div className="registration-inp col-6">
                                            {/* <DropDown
                                            variant="standard"
                                            label="City"
                                            name="city"
                                            value={values.city}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            dropCity={cities.filter(
                                                (data) => data.state_id === sId.toString(),
                                            )}
                                        /> */}
                                            <SimpleSelect2
                                                value={values.city}
                                                onChange={handleChange}
                                                name="city"
                                                label="City"
                                                dropCity={cities.filter(
                                                    (data) => data.state_id === sId.toString(),
                                                )}
                                            />
                                            {errors.city && touched.city ? (
                                                <div className="errorMessage-2 errorTxt">
                                                    {errors.city}
                                                </div>
                                            ) : null}
                                        </div>

                                        <div className="registration-inp col-6">
                                            <CustomInput
                                                variant="standard"
                                                label="Zip Code"
                                                name="zipcode"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.zipcode}
                                                inputProps={{ maxLength: 6 }}
                                            />
                                            {errors.zipcode && touched.zipcode ? (
                                                <div className="errorMessage-2 errorTxt">
                                                    {errors.zipcode}
                                                </div>
                                            ) : null}
                                        </div>
                                        <div className="registration-inp col-6">
                                            <CustomInput
                                                variant="standard"
                                                label="Address Line 1"
                                                name="addressline1"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.addressline1}
                                            />
                                            {errors.addressline1 && touched.addressline1 ? (
                                                <div className="errorMessage-1 errorTxt">
                                                    {errors.addressline1}
                                                </div>
                                            ) : null}
                                        </div>
                                        <div className="registration-inp col-6">
                                            <CustomInput
                                                variant="standard"
                                                label="Address Line 2"
                                                name="addressline2"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.addressline2}
                                            />
                                            {errors.addressline2 && touched.addressline2 ? (
                                                <div className="errorTxt">
                                                    {errors.addressline2}
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                                <PrimaryButton label="Sign Up" type="submit" />
                                {/* <a href="#">Sign in Instead</a> */}
                            </div>
                        </div>
                    </form>
                )}
            </Formik>
        </div>
    )
}

export default CardDetails
