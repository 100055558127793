import React from 'react'
import { Link } from 'react-router-dom'
import AboutSlider from '../../components/organisms/AboutSlider'
import VerticalSlider from '../../components/organisms/VerticalSlider'

const AboutUs = () => {
    return (
        <div className="aboutStaticPage">
            <div className="customContainer py-5">
                <div className="lvActnrRvw">
                    <div className="hdrWrpr">
                        <div className="d-flex align-items-center justify-content-between">
                            <h4>What our client says about us</h4>
                            <Link to="/allreviews" className="vwAllBtn">
                                View All
                            </Link>
                        </div>
                    </div>
                    <VerticalSlider />
                </div>
                {/* <div className="lvActnrRvw gglRvwWrpr">
                    <div className="hdrWrpr">
                        <h4>Google Reviews</h4>
                    </div>
                    <div className="noRecordsFound">No Reviews Found</div>
                </div> */}
            </div>
            <div className="contactWrapper">
                <h4 className="pt-4">Contact Us</h4>
                <div className="customContainer">
                    <div className="contactInfoSection pb-4">
                        <div className="lftAdrsDtls col-md-8 col-sm-12">
                            <div className="adrsWrp row">
                                <div className="infoClm col-md-4 col-12 location ">
                                    <span className="material-icons">location_on</span>
                                    {/* <img src="/images/location.png" className="mx-auto" />  */}
                                    <br />
                                    <div className="text-center">
                                        <p className="text-center">
                                            12014 SE Mill Plain Blvd
                                            <br /> Ste#220B <br /> Vancouver Wa 98684
                                        </p>
                                    </div>
                                </div>
                                <div className="infoClm col-md-4 col-12  phone ">
                                    <span className="material-icons">call</span>
                                    {/* <img src="/images/phone.png" className="mx-auto" />  */}
                                    <br />
                                    <div className="text-center">
                                        <p>Toll-free : (888) 655-COIN(2646)</p>
                                        <p>Text : (360) 281-5588</p>
                                        <p>Local : (360) 253-5565</p>
                                    </div>
                                </div>
                                <div className="infoClm mail  col-md-4 col-12 ">
                                    <span className="material-icons">mail</span>
                                    {/* <img src="/images/mail.png" className="mx-auto" />  */}
                                    <br />
                                    <div className="text-center">
                                        <p>
                                            <a href="mailto:ken@coinssince1994.com">
                                                Ken@coinssince1994.com
                                            </a>
                                        </p>
                                        <p>
                                            <a href="mailto:takumi@coinssince1994.com">
                                                Takumi@coinssince1994.com
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="lvActnrRvw col-md-4 col-sm-12">
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11161.97595185729!2d-122.5486834!3d45.6208118!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x822420effd3235f0!2sCoins%20%26%20Auctions%20Since%201994!5e0!3m2!1sen!2sus!4v1640628672034!5m2!1sen!2sus"
                                width="500"
                                height="400"
                                style={{ border: 0 }}
                                allowFullScreen=""
                                loading="lazy"
                            ></iframe>
                        </div>
                    </div>
                </div>
            </div>
            <div className="rvwWrpr vwCrsl mt-5">
                <div className="customContainer">
                    <h4>Gallery</h4>
                </div>
                <AboutSlider />
            </div>
        </div>
    )
}

export default AboutUs
