import React, { useState, useEffect } from 'react'
import CustomInput from '../../components/atoms/Inputs/CustomInput'
import clsx from 'clsx'
import PrimaryButton from '../../components/atoms/PrimaryButton'
import Timer from '../../components/molecules/Timer'
import {
    currencyFormat,
    handleRedirectInternal,
    useCustomMediaQuery,
} from '../../common/components'
import Chat from '../../components/organisms/Chat'
import './ProductView.css'
import axios from '../../Utils/Utility/axiosconfig'
import { useParams, useHistory, Link, useLocation } from 'react-router-dom'
import { socket, reInitializeSocket, socketSeller } from '../../common/socket'
import moment from 'moment-timezone'
import publicIp from 'public-ip'
// import ipLocation from 'iplocation'
import {
    onImageError,
    onImageErrorScroll,
    setDecimalPoint,
    getPaginationCount,
    getnearteImages,
} from '../../Utils/Utility'
import ProductViewSlider from '../../components/organisms/ProdutViewSlider'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Popover from '@material-ui/core/Popover'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import isAuthenticate from '../../Utils/Utility/auth'
import { connect, useDispatch, useSelector } from 'react-redux'
import updateFav from '../../Utils/API/fav'
import { findIndex } from 'lodash'
import Pagination from '@material-ui/lab/Pagination'
import qs from 'qs'
import preAuctionBid from '../../Utils/API/preAuctionBid'
import commonBidding from '../../Utils/API/nomalBidding'
import BidHistory from '../../components/organisms/BidHistory'
import { Button, Chip, Drawer, IconButton } from '@material-ui/core'
import Gridview from '../../components/molecules/ProductCard/GridView'
import Swal from 'sweetalert2'
import bidding from '../../Utils/API/bidding'
import Loaders from '../../components/molecules/Loader'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import MobileListCard from '../../components/molecules/MobileListCard'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import rapidSearch from '../../actions/rapidSearch'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import Popup from '../../components/organisms/Popup'
import CheckBox from '../../components/atoms/CheckBox/index'
import ReadMore from '../../components/atoms/ReadMore'

const useStyles = makeStyles((theme) => ({
    popover: {
        pointerEvents: 'none',
    },
    paper: {
        padding: theme.spacing(1),
    },
}))

function ProductView(props) {
    const [lotData, setLotData] = useState({})
    const [auctionData, setAuctionData] = useState([])
    const [isOpen, setOpen] = useState(false)
    const [data, setData] = useState({})
    const [body, setBody] = useState({})
    const [newBody, setnewBody] = useState({})
    const [anchorEl, setAnchorEl] = useState(null)
    const [popText, setPopText] = useState('')
    const [amount, setAmount] = useState({})
    const [auctionView, setAuctionView] = useState('Grid')
    const [historyId, setHistoryId] = useState(undefined)
    const open = Boolean(anchorEl)
    const { id } = useParams()
    const history = useHistory()
    const classes = useStyles()
    const [showTimer, setTimer] = useState(true)
    const [ipaddresss, setIPAddress] = useState('')
    const [tDrawer, setTDrawer] = useState(false)
    const [loading, setLoading] = useState(true)
    const [pageLotCount, setPageLotCount] = useState(20)
    const [sortbyorder, setSortByOrder] = useState('')
    const mobile = useMediaQuery('(max-width:700px)')
    const [bidUpdate, setbidUpdate] = useState('')
    const [showDescription, setShowDescription] = useState(false)
    const [closedData, setClosedData] = useState('')
    const [bidPriceData, setBidPriceData] = useState({})
    const [bidCancelData, setBidCancelData] = useState({})
    const [auctionClosedData, setAuctionClosedData] = useState({})
    const [isRegisterLiveAuctionEnabled, setIsRegisterLiveAuctionEnabled] = useState(false)
    const [scrollHeight, setScrollHeigth] = useState(0)
    const [lotText, setLotText] = useState('')
    const [lotText1, setLotText1] = useState('')
    const location = useLocation()
    const dispatch = useDispatch()
    const [searchText, setSearchText] = React.useState([])
    const [modalOpen, setmodalOpen] = useState(false)
    const [agreeterms, setAgreeterms] = useState(false)
    const terms_condition = useSelector((state) => state.storeDetails)
    const [lot_or_title, setLotorTitle] = useState(0)
    useEffect(() => {
        console.log(props.match.params, 'asdasddasd')
    }, [])

    // useEffect(() => {
    //     if (props.userDetails.userdetails.id !== undefined) {
    //         getAuctions({
    //             limit: 20,
    //             orserby: 2,
    //             page: 1,
    //             lotof: id,
    //             order: 1,
    //             userid: props.userDetails.userdetails.id,
    //         })
    //         console.log('api call 1')
    //         setLoading(true)
    //         console.log('initial load', props.userDetails.userdetails.id)
    //     }
    // }, [props.userDetails.userdetails.id])

    useEffect(() => {
        if (Object.keys(props.bidding.bidding).length !== 0) {
            let bidding = props.bidding.bidding
            // console.log('bidding===>', bidding)
            let productPosition = findIndex(auctionData, { id: bidding.id })
            if (productPosition !== -1) {
                let product = auctionData[productPosition]
                console.log('product valuessssss-------------->', product)
                // console.log('bidding.bpop_cbiddern 2222---', bidding.bpop_cbidder)
                // console.log(
                //     'props.userDetails.userdetails.id 2222-------------',
                //     props.userDetails.userdetails.id,
                // )
                if (bidding.bpop_cbidder == props.userDetails.userdetails.id) {
                    if (bidding.status == 'failed') {
                        bidding.error && toast.warning(bidding.error)
                    } else if (bidding.status == 'success') {
                        product['wprice'] = bidding.bpop_wprice
                        // console.log('bidding.bpop_higher ---------------', bidding.bpop_higher)
                        // console.log(
                        //     'props.userDetails.userdetails.id ------------',
                        //     props.userDetails.userdetails.id,
                        // )
                        if (bidding.bpop_higher == props.userDetails.userdetails.id) {
                            product['next_bid'] = bidding.bpop_nextbid
                        } else {
                            product['next_bid'] = bidding.bpop_nextbid_org
                        }
                        product['nobids'] = bidding.bpop_bidcount
                        product['increment'] = bidding.bpop_cuser_increment
                        if (product.userBidCount === undefined || bidding.userBidCount === 0) {
                            product['userBidCount'] = 1
                        }
                        product['highbid_user_id'] = bidding.bpop_higher
                        auctionData[productPosition] = product
                        Object.keys(data).length !== 0 && setData({ ...product })
                        setAuctionData([...auctionData])
                        if (bidding.bpop_error) {
                            toast.success(bidding.bpop_error)
                        }
                        // if (bidding.bpop_bidstatus) {
                        //     toast.info(bidding.bpop_bidstatus)
                        // }
                    }
                } else {
                    console.log('in else part')
                    if (bidding.status !== 'failed') {
                        if (product.userBidCount !== undefined && product.userBidCount !== 0) {
                            // console.log('bidding.bpop_higher 1111------------', bidding.bpop_higher)
                            // console.log(
                            //     'props.userDetails.userdetails.id 1111================',
                            //     props.userDetails.userdetails.id,
                            // )
                            if (bidding.bpop_higher != props.userDetails.userdetails.id) {
                                // console.log('bidding.bpop_wprice ========', bidding.bpop_wprice)
                                // console.log('product.next_bid =============', product.next_bid)
                                if (Number(bidding.bpop_wprice) >= Number(product.next_bid)) {
                                    product['next_bid'] = bidding.bpop_nextbid_org
                                    // product['next_bid'] = bidding.bpop_wprice_morehigh
                                }
                                product['wprice'] = bidding.bpop_wprice
                                auctionData[productPosition] = product
                                product['nobids'] = bidding.bpop_bidcount
                                product['increment'] = bidding.bpop_increment
                                product['highbid_user_id'] = bidding.bpop_higher
                                Object.keys(data).length !== 0 && setData({ ...product })
                                console.log('set Date for slider', data)
                                setAuctionData([...auctionData])
                                // toast.info('You are losing')
                            } else {
                                product['wprice'] = bidding.bpop_wprice
                                if (bidding.bpop_higher == props.userDetails.userdetails.id) {
                                    product['next_bid'] =
                                        bidding.bpop_wprice_morehigh + bidding.bpop_increment
                                } else {
                                    product['next_bid'] = bidding.bpop_nextbid_org
                                }
                                product['nobids'] = bidding.bpop_bidcount
                                product['increment'] = bidding.bpop_increment
                                product['highbid_user_id'] = bidding.bpop_higher
                                auctionData[productPosition] = product
                                Object.keys(data).length !== 0 && setData({ ...product })
                                setAuctionData([...auctionData])
                            }
                        } else {
                            // product['next_bid'] = bidding.bpop_nextbid_org
                            // console.log('bidding.bpop_higher ------------', bidding.bpop_higher)
                            // console.log(
                            //     'props.userDetails.userdetails.id================',
                            //     props.userDetails.userdetails.id,
                            // )
                            if (bidding.bpop_higher == props.userDetails.userdetails.id) {
                                product['next_bid'] =
                                    bidding.bpop_wprice_morehigh + bidding.bpop_increment
                            } else {
                                product['next_bid'] = bidding.bpop_nextbid_org
                            }
                            product['wprice'] = bidding.bpop_wprice
                            product['nobids'] = bidding.bpop_bidcount
                            product['increment'] = bidding.bpop_increment
                            product['highbid_user_id'] = bidding.bpop_higher
                            auctionData[productPosition] = product
                            Object.keys(data).length !== 0 && setData({ ...product })
                            setAuctionData([...auctionData])
                        }
                    }
                }
            }
        }
    }, [props.bidding])

    // useEffect(() => {
    //     setBody({
    //         ...body,
    //         limit: pageLotCount,
    //     })
    // }, [pageLotCount])

    let queryVw = qs.parse(props.location.search, { ignoreQueryPrefix: true })

    useEffect(() => {
        if (queryVw.perPage) {
            setPageLotCount(Number(queryVw.perPage))
            console.log(
                getPaginationCount(lotData?.lot_count, pageLotCount),
                Number(body.page),
                getPaginationCount(lotData?.lot_count, pageLotCount) < Number(body.page),
                'page count',
            )
            if (getPaginationCount(lotData?.lot_count, pageLotCount) < body.page) {
                let query = new URLSearchParams(props.location.search)
                query.set('page', 1)
                history.push({
                    pathname: props.location.pathname,
                    search: '?' + query.toString(),
                })
            }
        }
    }, [queryVw?.perPage])

    useEffect(() => {
        if (queryVw.lotSearch != '') {
            console.log('llllllllllllllllllllllllllllllllllll', queryVw)
            if (parseInt(queryVw.loc)) {
                console.log('wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww')
                setLotText1(queryVw.lotSearch)
            } else {
                setLotText(queryVw.lotSearch)
            }
        }
    }, [queryVw?.lotSearch])

    useEffect(() => {
        console.log(props.location.search, 'props.location.search')
        if (isAuthenticate() && !props.userDetails.userdetails.id) {
        } else {
            if (props.location.search != '') {
                console.log(
                    'if condition block executed',
                    pageLotCount,
                    !props.userDetails.userdetails.id && isAuthenticate(),
                )
                let query = qs.parse(props.location.search, { ignoreQueryPrefix: true })
                console.log('props.location.search query', query)
                if (query.perPage) {
                    if (pageLotCount == query.perPage) {
                        setTimeout(() => {
                            setBody({
                                limit: pageLotCount,
                                orserby: 2,
                                page: query.page || 1,
                                lotof: id,
                                order: 1,
                                userid: props.userDetails.userdetails.id
                                    ? props.userDetails.userdetails.id
                                    : '',
                                searchbar_with_lot_number: query.lotSearch ? query.lotSearch : '',
                                orderby: sortbyorder,
                                lot_num_fill: !parseInt(lot_or_title) ? 1 : 0,
                            })
                        }, 1000)
                    }
                } else {
                    setTimeout(() => {
                        setBody({
                            limit: pageLotCount,
                            orserby: 2,
                            page: query.page || 1,
                            lotof: id,
                            order: 1,
                            userid: props.userDetails.userdetails.id
                                ? props.userDetails.userdetails.id
                                : '',
                            searchbar_with_lot_number: query.lotSearch ? query.lotSearch : '',
                            orderby: sortbyorder,
                            lot_num_fill: !parseInt(lot_or_title) ? 1 : 0,
                        })
                    }, 1000)
                }

                if (query.scrollLocation) {
                    setScrollHeigth(query.scrollLocation)
                }
            } else {
                setBody({
                    limit: pageLotCount,
                    orserby: 2,
                    page: 1,
                    lotof: id,
                    order: 1,
                    userid: props.userDetails.userdetails.id
                        ? props.userDetails.userdetails.id
                        : '',
                    searchbar_with_lot_number: queryVw.lotSearch ? queryVw.lotSearch : '',
                    orderby: sortbyorder,
                    lot_num_fill: !parseInt(lot_or_title) ? 1 : 0,
                })
                history.push({ pathname: `${props.location.pathname}?page=1` })
            }
        }
    }, [props.location.search, props.userDetails.userdetails.id, pageLotCount])

    useEffect(() => {
        if (Object.keys(body).length !== 0) {
            getAuctions(body)
            setLoading(true)
            setnewBody(body)
        }
        console.log('body changed', body)
    }, [body])

    const handleHistory = (val) => setHistoryId(val)

    // const getLotData = async () => {
    //     let { data } = await axios.post('mobileapi/getauctionlotdetails', { auctionid: id })
    //     if (data && data.success === true) {
    //         data.data.auction_details.is_registered = data.data.is_registered
    //         console.log(
    //             'innnnnnnnnnnnnnnnnnnnnnnnn',
    //             new Date(),
    //             new Date(data.data.auction_details.date_added),
    //         )
    //         if (new Date(data.data.auction_details.date_added) >= new Date()) {
    //             data.data.auction_details.preview_date_reached = 1
    //         } else {
    //             data.data.auction_details.preview_date_reached = 0
    //         }
    //         if (data.data.is_registered_list.length > 0) {
    //             if (data.data.is_registered_list[0].status == 'active') {
    //                 data.data.auction_details.is_register_active = 0
    //             } else {
    //                 data.data.auction_details.is_register_active = 1
    //             }
    //         } else {
    //             data.data.auction_details.is_register_active = 1
    //         }

    //         setLotData(data.data.auction_details)
    //         setLoading(false)
    //     }
    // }

    const toggleDrawer = (event, data) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return
        }
        if (Object.keys(data) !== 0) {
            setData(data)
            setTDrawer(true)
        } else {
            setData({})
            setTDrawer(false)
        }
    }

    const getnerateImages = (array, auctionTitle) => {
        let product = array.map((data) => {
            let images = []
            let ava = ''
            if (data.lotImages.length !== 0) {
                ava = `${process.env.REACT_APP_PRODUCT_IMG}/${data.lotImages[0].file_name}}`
                images = data.lotImages.map((img) => {
                    let image = `${process.env.REACT_APP_PRODUCT_IMG}/${img.file_name}`
                    return { original: image, thumbnail: image }
                })
            } else {
                images = [{ original: '', thumbnail: '' }]
            }

            return { ...data, productImages: images, productAva: ava }
        })
        return product
    }

    const getAuctions = async (body) => {
        let { data } = await axios.post('api/lotSearch', body)
        let lotValues = data.response
        setLoading(false)
        if (data && data.status === 'success') {
            if (
                lotValues.results &&
                Array.isArray(lotValues.results) &&
                lotValues.results.length !== 0
            ) {
                let products = getnerateImages(lotValues.results, lotValues.auctionList.title)
                setAuctionData(products)
                setLoading(false)
            } else {
                setAuctionData([])
            }

            lotValues.auctionList.isRegistered = lotValues.isRegistered
            lotValues.auctionList.lot_count = lotValues.total_results
            lotValues.auctionList.current_projectid = lotValues.current_projectid
            if (new Date(lotValues.auctionList.date_added) >= new Date()) {
                lotValues.auctionList.preview_date_reached = 1
            } else {
                lotValues.auctionList.preview_date_reached = 0
            }
            if (lotValues.isRegistered_list.length > 0) {
                if (lotValues.isRegistered_list[0].status == 'active') {
                    lotValues.auctionList.is_register_active = 0
                } else {
                    lotValues.auctionList.is_register_active = 1
                }
            } else {
                lotValues.auctionList.is_register_active = 1
            }

            setLotData(lotValues.auctionList)
            setLoading(false)
        }
    }

    // useEffect(() => {
    //     async function fetchData() {
    //         const ipaddress = await publicIp.v4()
    //         const ipLoc = await ipLocation(ipaddress)
    //         console.log('ipLoc', ipLoc)
    //         setIPAddress(ipaddress)
    //     }
    //     fetchData()
    // }, [])

    const lotauctionreg = async () => {
        if (!isAuthenticate()) {
            return history.push('/login')
        }
        if (!agreeterms) {
            Swal.fire({
                icon: 'error',
                title: 'Please accept Terms and Conditions.',
                timer: 1500,
                showConfirmButton: false,
                position: 'center',
            })
            return false
        }
        var body = {
            auction_id: props.match.params.id,
            user_id: props.userDetails.userdetails.id,
            status: 'moderate',
            site_id: window.location.href.toString().includes('moblearn') ? 'AS0136' : 'AS0137',
            ipaddress: '',
            // ipaddress: ipaddresss,
        }

        let { data } = await axios.post('api/auctionreg', body)
        if (data && data.status === 'success') {
            Swal.fire({
                icon: 'success',
                title: 'Auction Registration Request Sent.',
                timer: 1500,
                showConfirmButton: false,
                position: 'center',
            })
            getAuctions({
                limit: 20,
                orserby: 2,
                page: 1,
                lotof: id,
                order: 1,
                userid: props.userDetails.userdetails.id,
            })
            console.log('api call 2')
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Error In Registration.',
                timer: 1500,
                showConfirmButton: false,
                position: 'center',
            })
        }
        setLoading(true)
    }

    const handlePopoverOpen = (event, text) => {
        setPopText(text)
        setAnchorEl(event.currentTarget)
    }

    const handlePopoverClose = () => {
        setAnchorEl(null)
    }

    const addFav = async (id, isFav) => {
        if (isAuthenticate()) {
            let data = await updateFav({ id: id, userid: props.userDetails.userdetails.id }, isFav)
            if (data) {
                let position = findIndex(auctionData, { id: id })
                if (position !== -1) {
                    let dataFav = auctionData[position]
                    dataFav = { ...dataFav, isFav: isFav === 0 ? 1 : 0 }
                    auctionData[position] = dataFav
                    if (Object.keys(data).length !== 0) {
                        setData(dataFav)
                    }
                    setAuctionData([...auctionData])
                }
            }
        } else {
            history.push({ pathname: '/login', state: props.location })
        }
    }

    const handleOnChange = (e) =>
        !isNaN(e.target.value)
            ? setAmount({ ...amount, [e.target.name]: e.target.value })
            : setAmount({ ...amount })

    const onKeyPress = (e, id) => {
        if (e.key === 'Enter') {
            let productPosition = findIndex(auctionData, { id: id })
            if (productPosition !== -1) {
                let productPosition = findIndex(auctionData, { id: id })
                let product = auctionData[productPosition]
                handleClick(
                    {
                        id: product.id,
                        wsprice: amount[product.id],
                        bid_increment: product.incrementamt,
                    },
                    parseFloat(product.next_bid),
                )
            }
        }
    }

    const handleClick = async (
        body,
        nPrice,
        auction_type,
        lot_id,
        incr_amt,
        crnt_amt,
        max_of_bd,
    ) => {
        if (isAuthenticate()) {
            if (lotData.con_check === 1) {
                if (parseInt(lotData.isRegistered) === 0) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Please Register Live Auction And Participate.',
                        timer: 2500,
                        showConfirmButton: false,
                        position: 'center',
                    })
                    //return window.scrollTo(0, 0)
                } else {
                    body.hard_bid = 0
                    body.auction_io = 1
                }
            }
            if (
                moment(props.serverTime).isBefore(moment(lotData.date_added)) &&
                auction_type !== 1
            ) {
                toast.warning('Auction is not started!')
                return false
            }

            if (nPrice > body.wsprice) {
                toast.warning(`Enter $${nPrice} or more`)
            } else {
                if (auction_type === 1) {
                    await preAuctionBid({
                        wsprice: parseInt(body.wsprice),
                        userid: props.userDetails.userdetails.id,
                        id: parseInt(lot_id),
                        lotid: parseInt(lot_id),
                        bidplan: 'auto',
                        auctionid: parseInt(id),
                    })
                    setAmount({ ...amount, [body.id]: '' })
                    // getAuctions(newBody)
                } else {
                    await commonBidding({
                        ...body,
                        email: props.userDetails.userdetails.email,
                        userid: props.userDetails.id,
                    })
                    setAmount({ ...amount, [body.id]: '' })
                    // getAuctions(newBody)
                }
            }
        } else {
            history.push({ pathname: '/login', state: props.location })
        }
    }

    // const bottomDrawer = () => { }

    useEffect(() => {
        socket.on('bidAddtime', (data) => {
            setBidPriceData(data)
        })
        socketSeller.on('bidAddtime', (data) => {
            setBidPriceData(data)
        })
    }, [])

    useEffect(() => {
        console.log(bidPriceData, 'this is the data from the scoedt')
        if (auctionData && auctionData.length && bidPriceData && bidPriceData.status !== 'failed') {
            // console.log('auction Data', auctionData)
            let newLots = [...auctionData]
            let lotIndex = newLots.findIndex(
                (ele) => parseInt(ele.id) === parseInt(bidPriceData.id),
            )
            if (lotIndex > -1) {
                console.log('lotIndex', lotIndex, bidPriceData, newLots[lotIndex])
                newLots[lotIndex].wprice = bidPriceData.bpop_wprice
                newLots[lotIndex].nobids = bidPriceData.bpop_bidcount
                newLots[lotIndex].highbid_user_id = bidPriceData.bpop_higher
                console.log(newLots, 'newLots')
                setAuctionData(newLots)
            }
        }
        console.log('bid added socket emitted')
    }, [bidPriceData])

    useEffect(() => {
        socket.on('bidderapproval', (data) => {
            console.log('bidders approval socket data', data)
            // updateRegisterStatus(data)
            setbidUpdate(data)
        })
    }, [])

    useEffect(() => {
        console.log('lot data inside use effect', lotData)
        updateRegisterStatus(bidUpdate)
    }, [bidUpdate])

    const updateRegisterStatus = (data) => {
        console.log('lot data -=-=======>', lotData, Object.keys(lotData).length)
        if (Object.keys(lotData)?.length > 0) {
            console.log('update register status', lotData)
            let lotCont = lotData
            let status = data.status
            let bidders = data.user_id
            let auction_id = data.auction_id
            console.log(
                'lotCont after socket emit',
                lotCont,
                status,
                bidders,
                auction_id,
                id,
                props.userDetails.userdetails.id,
            )
            if (data.auction_obj) {
                // let auctionIndex = auction_id.findIndex((ele) => ele == parseInt(id))
                // let index = bidders.findIndex((ele) => ele == props.userDetails?.userdetails?.id)
                // console.log('auction index values before all', index, auctionIndex)
                // if (index !== -1 && auctionIndex !== -1 && status === 'active') {
                //     console.log('auction index values active', index, auctionIndex)
                //     lotCont.is_register_active = 0
                //     setLotData(lotCont)
                // } else if (index !== -1 && auctionIndex !== -1 && status !== 'active') {
                //     console.log('auction index values suspend', index, auctionIndex)
                //     lotCont.is_register_active = 1
                //     setLotData(lotCont)
                // }

                let match_user = data.auction_obj.filter(
                    (ele) => ele.user_id == props.userDetails?.userdetails?.id,
                )
                console.log('match_user', match_user)
                let match_auction = []
                if (match_user.length > 0) {
                    match_auction = match_user.filter((ele) => ele.auction_id == parseInt(id))
                }
                console.log('match_auction', match_auction)
                if (match_auction.length > 0) {
                    if (status === 'active') {
                        lotCont.is_register_active = 0
                        setLotData(lotCont)
                    } else {
                        lotCont.is_register_active = 1
                        setLotData(lotCont)
                    }
                }
            }
        }
    }

    useEffect(() => {
        socket.on('cancelbidemitted', (data) => {
            console.log('cancel bid emitted data comee here in product view page', data)
            setBidCancelData(data)
        })
        socketSeller.on('cancelbidemitted', (data) => {
            console.log('cancel bid emitted data comee here in product view page', data)
            setBidCancelData(data)
        })
    }, [])

    useEffect(() => {
        if (
            auctionData &&
            auctionData.length
            // bidCancelData.prodata &&
            // Object.keys(bidCancelData.prodata).length > 0
        ) {
            let newLots = [...auctionData]
            let lotIndex = newLots.findIndex(
                (ele) => parseInt(ele.id) === parseInt(bidCancelData.project_id),
            )
            console.log('lotIndex retracted bid', lotIndex, bidCancelData, newLots[lotIndex])
            if (lotIndex > -1) {
                newLots[lotIndex].wprice = bidCancelData.bpop_wprice || newLots[lotIndex].wprice
                newLots[lotIndex].nobids = bidCancelData.bpop_bidcount || newLots[lotIndex].nobids
                newLots[lotIndex].highbid_user_id = bidCancelData.prodata
                    ? bidCancelData.prodata.user_id
                    : ''
                console.log(newLots, 'newLots')
                setAuctionData(newLots)
            }
        }
    }, [bidCancelData])

    useEffect(() => {
        socket.on('liveauctionclose', (data) => {
            setAuctionClosedData(data)
        })
        socketSeller.on('liveauctionclose', (data) => {
            setAuctionClosedData(data)
        })
    }, [])

    useEffect(() => {
        console.log(
            'Inside Auction Close123',
            parseInt(auctionClosedData.auctionid),
            parseInt(props.match.params.id),
        )
        if (parseInt(auctionClosedData.auctionid) === parseInt(props.match.params.id)) {
            console.log('Inside This Auction Close', newBody)
            setTimer(false)
            Swal.fire({
                icon: 'success',
                title: 'This Auction Has Been Ended By Seller Now.',
                //timer: 1500,
                showConfirmButton: true,
                position: 'center',
            }).then(function (data) {
                if (data.isConfirmed) {
                    return history.push('/search')
                }
            })
            console.log('live auction close socket emitted', newBody)
            getAuctions({
                limit: 20,
                orserby: 2,
                page: 1,
                lotof: id,
                order: 1,
            })
        }
    }, [auctionClosedData])

    // useEffect(() => {
    //     Swal.fire({
    //         icon: 'warning',
    //         title: 'This Auction Has Been Ended By Admin Now.Thanks For Participate The Auction.',
    //         //timer: 1500,
    //         showConfirmButton: true,
    //         position: 'center',
    //     }).then(function (data) {
    //         if (data.isConfirmed) {
    //             return history.push('/search')
    //         }
    //     })
    // }, [])

    const handleLotChange = (e) => {
        setPageLotCount(e.target.value)
        let query = new URLSearchParams(props.location.search)
        query.set('scrollLocation', 0)
        query.set('perPage', e.target.value)
        history.replace({
            pathname: props.location.pathname,
            search: '?' + query.toString(),
        })
    }

    const handleSortChange = (e) => {
        setSortByOrder(e.target.value)
        // setBody({
        //     limit: pageLotCount,
        //     orserby: 2,
        //     page: 1,
        //     lotof: id,
        //     order: 1,
        //     userid: props.userDetails.userdetails.id ? props.userDetails.userdetails.id : '',
        //     searchbar_with_lot_number: queryVw.lotSearch ? queryVw.lotSearch : '',
        //     orderby: e.target.value,
        // })
        let query = new URLSearchParams(props.location.search)
        query.set('scrollLocation', 0)
        query.set('page', 1)
        query.set('sortby', e.target.value)
        history.replace({
            pathname: props.location.pathname,
            search: '?' + query.toString(),
        })
    }

    useEffect(() => {
        socket.on('liveauctionAwarding', (data) => {
            if (data.site_id && data.site_id != process.env.REACT_APP_SITE_ID) {
                // skip if site_id not matching
                return false
            }

            console.log('closed auction emit product view page', data)
            setClosedData(data)
        })
        socketSeller.on('liveauctionAwarding', (data) => {
            if (data.site_id && data.site_id != process.env.REACT_APP_SITE_ID) {
                // skip if site_id not matching
                return false
            }

            console.log('closed auction emit product view page', data)
            setClosedData(data)
        })
    }, [])

    useEffect(() => {
        const newAuctionData = auctionData
        const currentActn = lotData
        const index = newAuctionData.findIndex((ele) => ele.id == closedData.lotid)
        console.log('new auction data assistance', auctionData, newAuctionData, index)
        if (index !== -1) {
            newAuctionData[index].market_status = 'sold'
            newAuctionData[index].date_closed = new Date()
            currentActn.current_projectid = Number(closedData.next_lot)
            currentActn && setLotData(currentActn)
            newAuctionData && setAuctionData(newAuctionData)
        }
        console.log(
            'new auction data assistance after assignment',
            auctionData,
            newAuctionData,
            index,
            currentActn,
        )
    }, [closedData])

    useEffect(() => {
        const intervalId = setInterval(() => {
            let now = moment()
            let AuctionStartDateTime = moment(lotData.date_added)
            let numberOfHours = moment.duration(AuctionStartDateTime.diff(now)).asHours()
            if (numberOfHours === 1) {
                //If total number of hours left for auction to start is 1, then enable Register For Auction and clear interval
                setIsRegisterLiveAuctionEnabled(true)
                clearInterval(intervalId)
            }
        }, 1000)
        return () => clearInterval(intervalId)
    }, [lotData])

    const onImageLoad = (e) => {
        window.scrollTo({ top: scrollHeight, bottom: 0, behavior: 'smooth' })
    }

    const onLotChange = (e) => {
        if (e.target.name == 'title') {
            setLotText1('')
            setLotorTitle(0)
            setLotText(e.target.value)
        } else {
            setLotText('')
            setLotorTitle(1)
            setLotText1(e.target.value)
        }
    }

    const onLotSubmit = (e) => {
        e.preventDefault()
        let query = new URLSearchParams(location.search)
        query.set('lotSearch', lotText ? lotText : lotText1)
        query.set('loc', lot_or_title)
        query.set('page', 1)
        query.set('scrollLocation', null)
        localStorage.setItem('scrollLocation', 0)
        history.push({
            pathname: props.location.pathname,
            search: '?' + query.toString(),
        })
    }

    // console.log('auctionDataauctionDataauctionDataauctionData', auctionData)

    useEffect(() => {
        console.log(localStorage.getItem('scrollLocation'), 'local storeage get item')
        let scrollValue = localStorage.getItem('scrollLocation')
        let queryVw = qs.parse(props.location.search, { ignoreQueryPrefix: true })
        let query = new URLSearchParams(props.location.search)
        if (scrollValue != null && scrollValue != 0) {
            if (scrollValue != queryVw.scrollLocation) {
                query.set('scrollLocation', scrollValue)
                history.replace({
                    pathname: props.location.pathname,
                    search: '?' + query.toString(),
                })
                localStorage.setItem('scrollLocation', 0)
            } else if (scrollValue == queryVw.scrollLocation) {
                query.set('scrollLocation', queryVw.scrollLocation)
                history.replace({
                    pathname: props.location.pathname,
                    search: '?' + query.toString(),
                })
                localStorage.setItem('scrollLocation', 0)
            } else {
                query.set('scrollLocation', 0)
                history.replace({
                    pathname: props.location.pathname,
                    search: '?' + query.toString(),
                })
            }
        }
    }, [])

    useEffect(() => {
        if (lotText != undefined && lotText != '') {
            dispatch(
                rapidSearch({
                    search_keyword: lotText,
                    status: 'open',
                    type: 'lots',
                    lotof: Number(id),
                    lot_search: lot_or_title,
                }),
            )
        }
    }, [lotText])

    useEffect(() => {
        if (props.searchText?.rapidSearch?.length > 0) {
            setSearchText(props.searchText.rapidSearch)
        }
    }, [props.searchText])

    const modalClose = () => {
        setmodalOpen(!modalOpen)
    }

    const handleChange = () => {
        setAgreeterms(!agreeterms)
    }

    return (
        <>
            {loading ? (
                <div className="mnHtcontnr">
                    <div className="liveLotsLoader">
                        <Loaders name="live_auction" isLoading={loading} />
                    </div>
                </div>
            ) : (
                <div className="productCntWrp customContainer">
                    <ToastContainer
                        autoClose={5000}
                        hideProgressBar={true}
                        pauseOnFocusLoss={false}
                        className="mb-3"
                    />
                    {Object.keys(lotData).length !== 0 && (
                        <div className="pdtVwRp px-5">
                            <div className="productTimer d-flex align-items-start justify-content-between">
                                <div className="pdtNmInfo text-left">
                                    <p className="productTitle mb-0">{lotData.title}</p>
                                    <small>Auction Id: {lotData.id}</small>
                                </div>
                                <div className="d-flex align-items-start timerdiV">
                                    <div
                                        className={`lotTimer ${
                                            lotData.con_check === 1 ? 'brdrRt' : ''
                                        } text-left`}
                                    >
                                        {showTimer ? (
                                            <>
                                                {/* <span>Time Left:</span>{' '} */}
                                                <Timer
                                                    productTime={{
                                                        start: lotData.date_added,
                                                        end: lotData.date_closed,
                                                    }}
                                                    timerBasic={true}
                                                />
                                            </>
                                        ) : (
                                            <span>Auction Closed</span>
                                        )}
                                    </div>
                                    {lotData.con_check === 1 && lotData.market_status != 'sold' && (
                                        <div className="vwLvActn">
                                            {parseInt(lotData.isRegistered) === 1 ? (
                                                <>
                                                    {new Date() > new Date(lotData.date_closed) ? (
                                                        ''
                                                    ) : (
                                                        <PrimaryButton
                                                            label={
                                                                <span className="d-flex align-items-center">
                                                                    <span className="material-icons mr-2 lvActnIcon pulse">
                                                                        radio_button_checked
                                                                    </span>
                                                                    <span>Enter Live Auction</span>
                                                                </span>
                                                            }
                                                            onClick={() =>
                                                                history.push(
                                                                    `/livelots/${lotData.id}/${props.userDetails.userdetails.id}`,
                                                                )
                                                            }
                                                        />
                                                    )}
                                                </>
                                            ) : (
                                                <>
                                                    <PrimaryButton
                                                        label={
                                                            <span className="d-flex align-items-center">
                                                                <span>
                                                                    CLICK HERE TO REGISTER FOR
                                                                    BIDDING
                                                                </span>
                                                            </span>
                                                        }
                                                        onClick={() => lotauctionreg()}
                                                        // disabled={!isRegisterLiveAuctionEnabled}
                                                    />{' '}
                                                    <div className="d-flex align-items-start">
                                                        <div className="checkTndC">
                                                            <CheckBox
                                                                name="agreeterms"
                                                                value={agreeterms}
                                                                onchange={handleChange}
                                                                checked={agreeterms}
                                                            />
                                                        </div>
                                                        <p
                                                            style={{
                                                                marginTop: '10px',
                                                                marginBottom: '5px',
                                                            }}
                                                        >
                                                            Please Accept{' '}
                                                            <a
                                                                style={{
                                                                    color: 'blue',
                                                                    cursor: 'pointer',
                                                                }}
                                                                onClick={(e) => modalClose()}
                                                            >
                                                                Terms & Conditions
                                                            </a>
                                                        </p>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                            {lotData.con_check === 1 && (
                                <>
                                    {parseInt(lotData.isRegistered) === 1 && (
                                        <>
                                            {parseInt(lotData.is_register_active) === 1 ? (
                                                <div className="lotInfoReg">
                                                    <span>
                                                        Registration Pending, We'll send you an
                                                        email once you've been approved to bid.
                                                    </span>
                                                </div>
                                            ) : (
                                                ''
                                            )}
                                        </>
                                    )}
                                </>
                            )}

                            <div className="productDescp d-flex px-0">
                                <LazyLoadImage
                                    src={process.env.REACT_APP_PRODUCT_IMG + lotData.avatar}
                                    onError={(e) => {
                                        onImageErrorScroll(e, scrollHeight)
                                    }}
                                    effect="blur"
                                    placeholderSrc="/images/imageLoading.svg"
                                    height="100%"
                                    className="auctionImage"
                                    afterLoad={onImageLoad}
                                />
                                <div className="pdtContent w-100">
                                    <div className="timeProduct d-flex justify-content-between align-items-center">
                                        <div className="catPdtTitle w-100 pr-5">
                                            {/* <p className="productTitle">{lotData.title}</p>
                                    <p className="productCat">{lotData.category}</p> */}
                                            <div className="dateTimeWrp pt-0 row">
                                                <div className="itemsWrp col-md-6">
                                                    <p className="itemTitle">Total Items :</p>
                                                    <p className="items">{lotData.lot_count}</p>
                                                </div>
                                                <div className="startDateCnt col-md-6">
                                                    {new Date() < new Date(lotData.date_added) ? (
                                                        <>
                                                            <p className="dateTitle">
                                                                Live Auction Starts In:
                                                            </p>
                                                            <p className="dateTime">
                                                                {moment(lotData.date_added)
                                                                    .tz('America/Los_Angeles')
                                                                    .format(
                                                                        'MM/DD/YYYY hh:mm A',
                                                                    )}{' '}
                                                                PST
                                                            </p>
                                                        </>
                                                    ) : (
                                                        ''
                                                    )}
                                                    {lotData.market_status != 'open' ? (
                                                        <>
                                                            <p className="dateTitle">
                                                                Live Auction Date:
                                                            </p>
                                                            <p className="dateTime">
                                                                {moment(lotData.date_added)
                                                                    .tz('America/Los_Angeles')
                                                                    .format(
                                                                        'MM/DD/YYYY hh:mm A',
                                                                    )}{' '}
                                                                PST
                                                            </p>
                                                        </>
                                                    ) : (
                                                        ''
                                                    )}
                                                </div>
                                                {/* <div className="EndDateCnt">
                                            <p className="dateTitle">Auction End Date :</p>
                                            <p className="enddateTime">
                                                {moment(lotData.date_closed).format(
                                                    'MM/DD/YYYY HH:mm A',
                                                )}
                                            </p>
                                        </div> */}
                                            </div>
                                        </div>
                                    </div>
                                    <p className="descrTitle">Description :</p>
                                    <ReadMore
                                        data={lotData.description}
                                        className="description"
                                        limit={200}
                                        label="Show"
                                    />
                                    {/* <p className="description d-flex align-items-start">
                                        {(lotData.description &&
                                            lotData.description?.length < 30) ||
                                        showDescription ? (
                                            <>
                                                <span
                                                    dangerouslySetInnerHTML={{
                                                        __html: lotData.description,
                                                    }}
                                                ></span>
                                                {lotData.description?.length > 30 && (
                                                    <span
                                                        className="ml-3 cursorDecoy shwMrBtn pt-1"
                                                        onClick={() => setShowDescription(false)}
                                                    >
                                                        <b>
                                                            <u>Show Less</u>
                                                        </b>
                                                    </span>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                {lotData.description && (
                                                    <span
                                                        dangerouslySetInnerHTML={{
                                                            __html: lotData.description.slice(
                                                                0,
                                                                30,
                                                            ),
                                                        }}
                                                    ></span>
                                                )}
                                                ...
                                                <span
                                                    className="ml-3 cursorDecoy shwMrBtn pt-1"
                                                    onClick={() => setShowDescription(true)}
                                                >
                                                    <b>
                                                        <u>Show More</u>
                                                    </b>
                                                </span>
                                            </>
                                        )}
                                    </p> */}
                                </div>
                            </div>
                        </div>
                    )}
                    {/* {console.log('auctiondatavalue-------->', auctionData)} */}
                    <div className="pdtVwCntWrp customContainer d-flex align-items-center justify-content-between mb-4">
                        <form className="" onSubmit={onLotSubmit}>
                            <div className="lotNoSearchContain d-flex align-items-center justify-content-start">
                                <div className="lotNoSearchWrapper">
                                    <Autocomplete
                                        freeSolo
                                        options={searchText}
                                        // value={lotText}
                                        inputValue={lotText}
                                        getOptionLabel={(option) =>
                                            option.sku
                                                ? `${option.title} (${option.sku})`
                                                : option.title
                                        }
                                        onChange={(event, newValue) => {
                                            console.log('newValue', newValue?.title, newValue)
                                            setLotText1('')
                                            setLotText(
                                                newValue?.title != undefined &&
                                                    newValue?.title != null
                                                    ? newValue.title
                                                    : newValue != null
                                                    ? newValue
                                                    : '',
                                            )
                                            setLotorTitle(0)
                                        }}
                                        onInputChange={(event, newInputValue, reason) => {
                                            if (reason === 'clear') {
                                                setLotText('')
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant={'outlined'}
                                                name="title"
                                                placeholder="Search Title"
                                                value={lotText}
                                                onChange={onLotChange}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    onKeyDown: (e) => {
                                                        if (e.key === 'Enter') {
                                                            e.stopPropagation()
                                                        }
                                                    },
                                                }}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="lotNoSearchWrapper">
                                    <Autocomplete
                                        freeSolo
                                        options={[]}
                                        // value={lotText}
                                        inputValue={lotText1}
                                        getOptionLabel={(option) =>
                                            option.sku
                                                ? `${option.title} (${option.sku})`
                                                : option.title
                                        }
                                        onChange={(event, newValue) => {
                                            console.log('newValue', newValue?.title, newValue)
                                            setLotorTitle(1)
                                            setLotText1(
                                                newValue?.title != undefined &&
                                                    newValue?.title != null
                                                    ? newValue.title
                                                    : newValue != null
                                                    ? newValue
                                                    : '',
                                            )
                                        }}
                                        onInputChange={(event, newInputValue, reason) => {
                                            if (reason === 'clear') {
                                                setLotText1('')
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                name="lotNumber"
                                                variant={'outlined'}
                                                placeholder="Search lot Number"
                                                value={lotText1}
                                                onChange={onLotChange}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    onKeyDown: (e) => {
                                                        if (e.key === 'Enter') {
                                                            e.stopPropagation()
                                                        }
                                                    },
                                                }}
                                            />
                                        )}
                                    />
                                    <IconButton type="submit">
                                        <span className="material-icons">search</span>
                                    </IconButton>
                                </div>
                            </div>
                        </form>
                        <div className="sortingBox d-flex align-items-center justify-content-end flex-wrap">
                            <div className="d-flex align-items-center">
                                <label className="mb-0 mr-2">Results per page: </label>
                                <select
                                    name="pageLtCnt"
                                    onChange={handleLotChange}
                                    value={pageLotCount}
                                >
                                    <option value={20}>20</option>
                                    <option value={60}>60</option>
                                    <option value={100}>100</option>
                                    {/* <option value={2000}>See All</option> */}
                                </select>
                            </div>
                            <div className="sortItems d-flex align-items-center ml-2">
                                <label className="mb-0 mr-2">Sort By: </label>
                                <select
                                    name="pageLtCnt"
                                    onChange={handleSortChange}
                                    value={sortbyorder}
                                >
                                    <option value={''}>None</option>
                                    <option value={1}>Newest Upload</option>
                                    {/* <option value={2}>Lowest Estimate</option>
                                    <option value={3}>Highest Estimate</option> */}
                                    <option value={4}>Lowest Bid</option>
                                    <option value={5}>Highest Bid</option>
                                    <option value={6}>Lowest Bid Count</option>
                                    <option value={7}>Highest Bid Count</option>
                                </select>
                            </div>
                            <div className="gridListToggle ml-3 mb-0 mt-0">
                                <Button
                                    className={auctionView === 'Grid' ? 'active' : ''}
                                    onClick={() => setAuctionView('Grid')}
                                >
                                    <span className="material-icons">apps</span>GRID
                                </Button>
                                <Button
                                    className={auctionView === 'List' ? 'active' : ''}
                                    onClick={() => setAuctionView('List')}
                                >
                                    <span className="material-icons">view_list</span>LIST
                                </Button>
                            </div>
                        </div>
                    </div>

                    {auctionData.length !== 0 && auctionView === 'Grid' ? (
                        <div className="lotGrid customContainer">
                            {auctionData.map((data, index) => (
                                <Gridview
                                    imgSrc={
                                        data.avatar
                                            ? `${
                                                  process.env.REACT_APP_PRODUCT_IMG +
                                                  data?.lotImages[0]?.file_name
                                              }`
                                            : '/images/pdtimg.png'
                                    }
                                    Name={data.title}
                                    currentPrice={data.wprice}
                                    offerPrice={data.current_max_offer_bid}
                                    label={'View Auction'}
                                    timerBasic={true}
                                    bidding_hide={data.bidding_hide}
                                    // onClick={() => {
                                    //     window.open(
                                    //         `/lotview/${id}/${data.id}/${
                                    //             props.userDetails.userdetails.id || 0
                                    //         }?page=${parseInt(body.page)}&scrollLocation=${
                                    //             window.pageYOffset
                                    //         }&perPage=${pageLotCount}`,
                                    //         '_blank',
                                    //     )
                                    // }}
                                    onClick={() => (
                                        localStorage.setItem('scrollLocation', window.pageYOffset),
                                        handleRedirectInternal(
                                            history,
                                            `lotview/${id}/${data.id}/${
                                                props.userDetails.userdetails.id || 0
                                            }`,
                                            `?page=${parseInt(body.page)}&scrollLocation=${
                                                window.pageYOffset
                                            }&perPage=${pageLotCount}`,
                                        )
                                    )}
                                    redirectUrl={`/lotview/${id}/${data.id}/${
                                        props.userDetails.userdetails.id || 0
                                    }?page=${parseInt(body.page)}&scrollLocation=${
                                        window.pageYOffset
                                    }&perPage=${pageLotCount}`}
                                    // onClick={(e) => toggleDrawer(e, data)}
                                    id={data.lotof}
                                    prodId={data.id}
                                    isFav={data.isFav}
                                    onFavClick={() => addFav(data.id, data.isFav)}
                                    lotId={data.sku}
                                    startTime={data.date_added}
                                    endTime={data.date_closed}
                                    lowest={data.lowest}
                                    highest={data.highest}
                                    market_status={data.market_status}
                                    card_type="lotView"
                                    con_check={1}
                                    lotBidding={
                                        new Date() < new Date(lotData.date_added) ? (
                                            <div className="placeBid gridViewbtn">
                                                <CustomInput
                                                    placeholder={`Enter bid`}
                                                    label={`Enter bid`}
                                                    variant="outlined"
                                                    onChange={handleOnChange}
                                                    pattern="^\d*(.\d{0,2})?$"
                                                    name={String(data.id)}
                                                    value={amount[data.id] || ''}
                                                    onKeyPress={(e) => onKeyPress(e, data.id)}
                                                />
                                                <PrimaryButton
                                                    label={
                                                        lotData.con_check === 1
                                                            ? 'Place Max Bid'
                                                            : 'Place Max Bid'
                                                    }
                                                    disabled={
                                                        isAuthenticate() === undefined ||
                                                        amount[data.id] === undefined ||
                                                        amount[data.id] === ''
                                                    }
                                                    onClick={(e) =>
                                                        handleClick(
                                                            {
                                                                id: data.id,
                                                                wsprice: amount[data.id],
                                                                bid_increment: data.incrementamt,
                                                            },
                                                            parseFloat(data.next_bid),
                                                            lotData.con_check,
                                                            data.id,
                                                            data.incrementamt,
                                                            data.wprice,
                                                            data.current_max_offer_bid,
                                                        )
                                                    }
                                                />
                                            </div>
                                        ) : lotData.current_projectid !== data.id &&
                                          data.market_status === 'open' &&
                                          new Date() < new Date(data.date_closed) ? (
                                            <div className="placeBid gridViewbtn">
                                                <CustomInput
                                                    placeholder={`Enter bid`}
                                                    label={`Enter bid`}
                                                    variant="outlined"
                                                    onChange={handleOnChange}
                                                    pattern="^\d*(.\d{0,2})?$"
                                                    name={String(data.id)}
                                                    value={amount[data.id] || ''}
                                                    onKeyPress={(e) => onKeyPress(e, data.id)}
                                                />
                                                <PrimaryButton
                                                    label={
                                                        lotData.con_check === 1
                                                            ? 'Place Max Bid'
                                                            : 'Place Max Bid'
                                                    }
                                                    disabled={
                                                        isAuthenticate() === undefined ||
                                                        amount[data.id] === undefined ||
                                                        amount[data.id] === ''
                                                    }
                                                    onClick={(e) =>
                                                        handleClick(
                                                            {
                                                                id: data.id,
                                                                wsprice: amount[data.id],
                                                                bid_increment: data.incrementamt,
                                                            },
                                                            parseFloat(data.next_bid),
                                                            lotData.con_check,
                                                            data.id,
                                                            data.incrementamt,
                                                            data.wprice,
                                                            data.current_max_offer_bid,
                                                        )
                                                    }
                                                />
                                            </div>
                                        ) : (
                                            ' '
                                        )
                                    }
                                    highbid_user_id={data.highbid_user_id}
                                    userDetails={props.userDetails}
                                />
                            ))}
                        </div>
                    ) : mobile ? (
                        <div className="lotPdtWrp customContainer">
                            {auctionData.map((data, index) => (
                                <MobileListCard
                                    imgSrc={
                                        data.avatar
                                            ? `${
                                                  process.env.REACT_APP_PRODUCT_IMG +
                                                  data?.lotImages[0]?.file_name
                                              }`
                                            : '/images/pdtimg.png'
                                    }
                                    Name={data.title}
                                    currentPrice={data.wprice}
                                    offerPrice={data.current_max_offer_bid}
                                    label={'View Auction'}
                                    timerBasic={true}
                                    // onClick={() => {
                                    //     window.open(
                                    //         `/lotview/${id}/${data.id}/${
                                    //             props.userDetails.userdetails.id || 0
                                    //         }?page=${parseInt(body.page)}&scrollLocation=${
                                    //             window.pageYOffset
                                    //         }&perPage=${pageLotCount}`,
                                    //         '_blank',
                                    //     )
                                    // }}
                                    onClick={() => (
                                        localStorage.setItem('scrollLocation', window.pageYOffset),
                                        handleRedirectInternal(
                                            history,
                                            `lotview/${id}/${data.id}/${
                                                props.userDetails.userdetails.id || 0
                                            }`,
                                            `?page=${parseInt(body.page)}&scrollLocation=${
                                                window.pageYOffset
                                            }&perPage=${pageLotCount}`,
                                        )
                                    )}
                                    redirectUrl={`/lotview/${id}/${data.id}/${
                                        props.userDetails.userdetails.id || 0
                                    }?page=${parseInt(body.page)}&scrollLocation=${
                                        window.pageYOffset
                                    }&perPage=${pageLotCount}`}
                                    // onClick={(e) => toggleDrawer(e, data)}
                                    bidCount={data.bid_count}
                                    id={data.lotof}
                                    prodId={data.id}
                                    isFav={data.isFav}
                                    onFavClick={() => addFav(data.id, data.isFav)}
                                    lotId={data.sku}
                                    startTime={data.date_added}
                                    endTime={data.date_closed}
                                    lowest={data.lowest}
                                    highest={data.highest}
                                    market_status={data.market_status}
                                    card_type="lotView"
                                    description={data.description}
                                    handleBidHistory={(e) => handleHistory(data.id)}
                                    lotBidding={
                                        new Date() < new Date(lotData.date_added) ? (
                                            <div className="placeBid gridViewbtn">
                                                <CustomInput
                                                    placeholder={`Enter bid`}
                                                    label={`Enter bid`}
                                                    variant="outlined"
                                                    onChange={handleOnChange}
                                                    pattern="^\d*(.\d{0,2})?$"
                                                    name={String(data.id)}
                                                    value={amount[data.id] || ''}
                                                    onKeyPress={(e) => onKeyPress(e, data.id)}
                                                />
                                                <PrimaryButton
                                                    label={
                                                        lotData.con_check === 1
                                                            ? 'Place Max Bid'
                                                            : 'Place Max Bid'
                                                    }
                                                    disabled={
                                                        isAuthenticate() === undefined ||
                                                        amount[data.id] === undefined ||
                                                        amount[data.id] === ''
                                                    }
                                                    onClick={(e) =>
                                                        handleClick(
                                                            {
                                                                id: data.id,
                                                                wsprice: amount[data.id],
                                                                bid_increment: data.incrementamt,
                                                            },
                                                            parseFloat(data.next_bid),
                                                            lotData.con_check,
                                                            data.id,
                                                            data.incrementamt,
                                                            data.wprice,
                                                            data.current_max_offer_bid,
                                                        )
                                                    }
                                                />
                                            </div>
                                        ) : lotData.current_projectid !== data.id &&
                                          data.market_status === 'open' &&
                                          new Date() < new Date(data.date_closed) ? (
                                            <div className="placeBid gridViewbtn">
                                                <CustomInput
                                                    placeholder={`Enter bid`}
                                                    label={`Enter bid`}
                                                    variant="outlined"
                                                    onChange={handleOnChange}
                                                    pattern="^\d*(.\d{0,2})?$"
                                                    name={String(data.id)}
                                                    value={amount[data.id] || ''}
                                                    onKeyPress={(e) => onKeyPress(e, data.id)}
                                                />
                                                <PrimaryButton
                                                    label={
                                                        lotData.con_check === 1
                                                            ? 'Place Max Bid'
                                                            : 'Place Max Bid'
                                                    }
                                                    disabled={
                                                        isAuthenticate() === undefined ||
                                                        amount[data.id] === undefined ||
                                                        amount[data.id] === ''
                                                    }
                                                    onClick={(e) =>
                                                        handleClick(
                                                            {
                                                                id: data.id,
                                                                wsprice: amount[data.id],
                                                                bid_increment: data.incrementamt,
                                                            },
                                                            parseFloat(data.next_bid),
                                                            lotData.con_check,
                                                            data.id,
                                                            data.incrementamt,
                                                            data.wprice,
                                                            data.current_max_offer_bid,
                                                        )
                                                    }
                                                />
                                            </div>
                                        ) : (
                                            ' '
                                        )
                                    }
                                />
                            ))}
                        </div>
                    ) : (
                        <div className="lotPdtWrp customContainer">
                            <table className="table tableCnt">
                                <thead className="tableHeader">
                                    <tr>
                                        <th>Lot #</th>
                                        <th>Image</th>
                                        <th>Title</th>
                                        <th>Description</th>
                                        <th>Est</th>
                                        {/* <th>
                                            Closing <br /> At
                                        </th> */}
                                        {parseInt(lotData.bidding_hide) != 1 ? (
                                            <>
                                                <th>
                                                    {lotData.market_status === 'sold' ||
                                                    lotData.market_status === 'unsold'
                                                        ? 'Sold Price'
                                                        : 'Current bid'}
                                                </th>
                                                {/* {new Date() < new Date(lotData.date_added) && (
                                    <th style={{ whiteSpace: 'pre' }}>Offer Price</th>
                                )} */}

                                                <th>
                                                    No .Of <br /> Bids
                                                </th>
                                            </>
                                        ) : null}
                                        <th>Status</th>
                                        {/* <th>Action</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {auctionData.map((data, index) => (
                                        <tr key={data.id}>
                                            <td className="slNo">
                                                <span
                                                    className="cursor"
                                                    // onClick={() => {
                                                    //     window.open(
                                                    //         `/lotview/${id}/${data.id}/${
                                                    //             props.userDetails.userdetails.id ||
                                                    //             0
                                                    //         }?page=${parseInt(
                                                    //             body.page,
                                                    //         )}&scrollLocation=${
                                                    //             window.pageYOffset
                                                    //         }&perPage=${pageLotCount}`,
                                                    //         '_blank',
                                                    //     )
                                                    // }}
                                                    onClick={() => (
                                                        localStorage.setItem(
                                                            'scrollLocation',
                                                            window.pageYOffset,
                                                        ),
                                                        handleRedirectInternal(
                                                            history,
                                                            `lotview/${id}/${data.id}/${props.userDetails.userdetails.id}`,
                                                            `?page=${parseInt(
                                                                body.page,
                                                            )}&scrollLocation=${
                                                                window.pageYOffset
                                                            }&perPage=${pageLotCount}`,
                                                        )
                                                    )}
                                                    // onClick={(e) => toggleDrawer(e, data)}
                                                >
                                                    {data.sku}
                                                </span>
                                            </td>
                                            <td className="lotImg lotCardWrpr">
                                                <div className="favBox">
                                                    <span
                                                        name={data.id}
                                                        onClick={() => addFav(data.id, data.isFav)}
                                                        className="material-icons Favicon"
                                                    >
                                                        {data.isFav === 1
                                                            ? 'favorite'
                                                            : 'favorite_border'}
                                                    </span>
                                                </div>
                                                <div
                                                    className="imgBtn"
                                                    // onClick={() => {
                                                    //     window.open(
                                                    //         `/lotview/${id}/${data.id}/${
                                                    //             props.userDetails.userdetails.id ||
                                                    //             0
                                                    //         }?page=${parseInt(
                                                    //             body.page,
                                                    //         )}&scrollLocation=${
                                                    //             window.pageYOffset
                                                    //         }&perPage=${pageLotCount}`,
                                                    //         '_blank',
                                                    //     )
                                                    // }}
                                                    onClick={() => (
                                                        localStorage.setItem(
                                                            'scrollLocation',
                                                            window.pageYOffset,
                                                        ),
                                                        handleRedirectInternal(
                                                            history,
                                                            `lotview/${id}/${data.id}/${props.userDetails.userdetails.id}`,
                                                            `?page=${parseInt(
                                                                body.page,
                                                            )}&scrollLocation=${
                                                                window.pageYOffset
                                                            }&perPage=${pageLotCount}`,
                                                        )
                                                    )}
                                                    // onClick={(e) => toggleDrawer(e, data)}
                                                >
                                                    <Link
                                                        to={`/lotview/${id}/${data.id}/${
                                                            props.userDetails.userdetails.id || 0
                                                        }?page=${parseInt(
                                                            body.page,
                                                        )}&scrollLocation=${
                                                            window.pageYOffset
                                                        }&perPage=${pageLotCount}`}
                                                    >
                                                        <LazyLoadImage
                                                            alt={data.title}
                                                            src={
                                                                process.env.REACT_APP_PRODUCT_IMG +
                                                                data?.lotImages[0]?.file_name
                                                            }
                                                            onError={onImageError}
                                                            effect="blur"
                                                            placeholderSrc="/images/imageLoading.svg"
                                                            height="100%"
                                                            width="100%"
                                                        />
                                                    </Link>
                                                </div>
                                            </td>
                                            <td className="cat">
                                                <a
                                                    className="cursor"
                                                    href={`/lotview/${id}/${data.id}/${
                                                        props.userDetails.userdetails.id || 0
                                                    }?page=${parseInt(body.page)}&scrollLocation=${
                                                        window.pageYOffset
                                                    }&perPage=${pageLotCount}`}
                                                    // onClick={() => {
                                                    //     window.open(
                                                    //         `/lotview/${id}/${data.id}/${
                                                    //             props.userDetails.userdetails.id ||
                                                    //             0
                                                    //         }?page=${parseInt(
                                                    //             body.page,
                                                    //         )}&scrollLocation=${
                                                    //             window.pageYOffset
                                                    //         }&perPage=${pageLotCount}`,
                                                    //         '_blank',
                                                    //     )
                                                    // }}
                                                    onClick={() => (
                                                        localStorage.setItem(
                                                            'scrollLocation',
                                                            window.pageYOffset,
                                                        ),
                                                        handleRedirectInternal(
                                                            history,
                                                            `lotview/${id}/${data.id}/${props.userDetails.userdetails.id}`,
                                                            `?page=${parseInt(
                                                                body.page,
                                                            )}&scrollLocation=${
                                                                window.pageYOffset
                                                            }&perPage=${pageLotCount}`,
                                                        )
                                                    )}
                                                    dangerouslySetInnerHTML={{
                                                        __html: data.title,
                                                    }}
                                                    // onClick={(e) => toggleDrawer(e, data)}
                                                ></a>
                                            </td>
                                            <td className="decs">
                                                <div className="onclisk">
                                                    <Typography
                                                        aria-owns={
                                                            open ? 'mouse-over-popover' : undefined
                                                        }
                                                        aria-haspopup="true"
                                                        onMouseEnter={(e) =>
                                                            handlePopoverOpen(e, data.description)
                                                        }
                                                        onMouseLeave={handlePopoverClose}
                                                    >
                                                        {' '}
                                                        <div
                                                            dangerouslySetInnerHTML={{
                                                                __html: data.description,
                                                            }}
                                                        />{' '}
                                                    </Typography>
                                                    <b
                                                        onMouseEnter={(e) =>
                                                            handlePopoverOpen(e, data.description)
                                                        }
                                                        onMouseLeave={handlePopoverClose}
                                                    >
                                                        read more
                                                    </b>
                                                </div>
                                            </td>
                                            <td style={{ whiteSpace: 'pre' }}>
                                                {currencyFormat(data.lowest)} -{' '}
                                                {currencyFormat(data.highest)}
                                            </td>
                                            {/* <td className="lotTime">
                                                <Timer
                                                    productTime={{
                                                        start: moment()
                                                            .subtract(1, 'hour')
                                                            .format(),
                                                        end: data.date_closed,
                                                    }}
                                                />
                                            </td> */}
                                            {parseInt(lotData.bidding_hide) != 1 ? (
                                                <>
                                                    <td className="CurrentPrice">
                                                        $ {setDecimalPoint(data.wprice, 2)}
                                                    </td>
                                                    {/* {new Date() < new Date(lotData.date_added) && (
                                        <td className="CurrentPrice">
                                            $ {setDecimalPoint(data.current_max_offer_bid, 2)}
                                        </td>
                                    )} */}
                                                    <td className="bids">
                                                        {data.bid_count}
                                                        {/* <a onClick={(e) => handleHistory(data.id)}>
                                                    {data.bid_count}
                                                </a> */}
                                                    </td>
                                                </>
                                            ) : null}
                                            <td className="winningLabel">
                                                {data.highbid_user_id &&
                                                    props.userDetails &&
                                                    data.highbid_user_id ==
                                                        props.userDetails.userdetails.id && (
                                                        <b>You're Winning!</b>
                                                    )}
                                            </td>
                                            {/* <td>
                                                {new Date() < new Date(lotData.date_added) ? (
                                                    <div className="placeBid d-flex justify-content-end align-items-end">
                                                        <CustomInput
                                                            placeholder={`Enter $${setDecimalPoint(
                                                                parseFloat(data.next_bid),
                                                                2,
                                                            )} or more`}
                                                            variant="standard"
                                                            onChange={handleOnChange}
                                                            pattern="^\d*(.\d{0,2})?$"
                                                            name={String(data.id)}
                                                            value={amount[data.id] || ''}
                                                            onKeyPress={(e) =>
                                                                onKeyPress(e, data.id)
                                                            }
                                                        />
                                                        <PrimaryButton
                                                            label={
                                                                lotData.con_check === 1
                                                                    ? 'Place Max Bid'
                                                                    : 'Place Max Bid'
                                                            }
                                                            disabled={
                                                                isAuthenticate() === undefined ||
                                                                amount[data.id] === undefined ||
                                                                amount[data.id] === ''
                                                            }
                                                            onClick={(e) =>
                                                                handleClick(
                                                                    {
                                                                        id: data.id,
                                                                        wsprice: amount[data.id],
                                                                        bid_increment:
                                                                            data.incrementamt,
                                                                    },
                                                                    parseFloat(data.next_bid),
                                                                    lotData.con_check,
                                                                    data.id,
                                                                    data.incrementamt,
                                                                    data.wprice,
                                                                    data.current_max_offer_bid,
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                ) : lotData.current_projectid !== data.id &&
                                                  data.market_status === 'open' &&
                                                  new Date() < new Date(data.date_closed) ? (
                                                    <div className="placeBid d-flex justify-content-end align-items-end">
                                                        <CustomInput
                                                            placeholder={`Enter $${setDecimalPoint(
                                                                parseFloat(data.next_bid),
                                                                2,
                                                            )} or more`}
                                                            variant="standard"
                                                            onChange={handleOnChange}
                                                            pattern="^\d*(.\d{0,2})?$"
                                                            name={String(data.id)}
                                                            value={amount[data.id] || ''}
                                                            onKeyPress={(e) =>
                                                                onKeyPress(e, data.id)
                                                            }
                                                        />
                                                        <PrimaryButton
                                                            label={
                                                                lotData.con_check === 1
                                                                    ? 'Place Max Bid'
                                                                    : 'Place Max Bid'
                                                            }
                                                            disabled={
                                                                isAuthenticate() === undefined ||
                                                                amount[data.id] === undefined ||
                                                                amount[data.id] === ''
                                                            }
                                                            onClick={(e) =>
                                                                handleClick(
                                                                    {
                                                                        id: data.id,
                                                                        wsprice: amount[data.id],
                                                                        bid_increment:
                                                                            data.incrementamt,
                                                                    },
                                                                    parseFloat(data.next_bid),
                                                                    lotData.con_check,
                                                                    data.id,
                                                                    data.incrementamt,
                                                                    data.wprice,
                                                                    data.current_max_offer_bid,
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                ) : (
                                                    ' '
                                                    // <div className="enterLvTst">
                                                    //     Enter Live Auction to Bid
                                                    // </div>
                                                )}
                                            </td> */}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}
                    {pageLotCount != 2000 ? (
                        <div className="pageNo">
                            {Object.keys(lotData).length !== 0 &&
                                Object.keys(body).length !== 0 && (
                                    <Pagination
                                        count={getPaginationCount(lotData.lot_count, pageLotCount)}
                                        defaultPage={6}
                                        boundaryCount={3}
                                        onChange={(e, value) => {
                                            let query = new URLSearchParams(props.location.search)
                                            query.set('page', value)
                                            query.set('scrollLocation', 0)
                                            history.push({
                                                pathname: props.location.pathname,
                                                search: '?' + query.toString(),
                                            })
                                        }}
                                        page={parseInt(body.page)}
                                    />
                                )}
                        </div>
                    ) : (
                        ''
                    )}
                    <Popover
                        id="mouse-over-popover"
                        className={classes.popover}
                        classes={{
                            paper: classes.paper,
                        }}
                        open={open}
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        onClose={handlePopoverClose}
                        disableRestoreFocus
                    >
                        <Typography>{popText}</Typography>
                    </Popover>
                    <ProductViewSlider
                        onCloseClick={(e) => setTDrawer(false)}
                        data={data}
                        // isOpen={Object.keys(data) != 0}
                        lotData={lotData}
                        isOpen={tDrawer}
                        addFav={addFav}
                        amount={amount}
                        handleOnChange={handleOnChange}
                        onKeyPress={onKeyPress}
                        handleClick={handleClick}
                        handleHistory={handleHistory}
                        currentProject={lotData.current_projectid !== data.id ? true : false}
                    />
                    <BidHistory
                        open={historyId !== undefined}
                        productId={historyId}
                        onClose={handleHistory}
                        auctionType={lotData.con_check === 1 ? 'liveAuction' : 'TimedAuction'}
                    />
                </div>
            )}
            <Popup
                modaltitle="Terms and Conditions"
                open={modalOpen}
                handleClose={() => modalClose()}
            >
                {' '}
                <div className="p-4 descFldDynTxt">
                    <h1>Terms & Conditions</h1>
                    <div
                        className="container text-left mt-4"
                        dangerouslySetInnerHTML={{
                            __html: terms_condition?.storeDetails?.results?.terms_condition,
                        }}
                    ></div>
                </div>
            </Popup>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        userDetails: state.User ? state.User : {},
        bidding: state.bidding ? state.bidding : {},
        serverTime: state.serverTime ? state.serverTime.time : '',
        searchText: state.rapidSearch,
    }
}

export default connect(mapStateToProps, null)(ProductView)
