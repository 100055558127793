import React, { useEffect, useState } from 'react'
import invoiceapi from '../../Utils/API/invoiceapi'
import Swal from 'sweetalert2'
import { useHistory } from 'react-router-dom'
import './invoice.css'

function Invoice(props) {
    const [Invoices, setInvoices] = useState([{}])
    const [Useraddress, setaddress] = useState({})
    const [wonAmount, setWonAmount] = useState(0)
    const [grandTotal, setGrandTotal] = useState(0)

    const history = useHistory()

    const invoiceData = async () => {
        const data = await invoiceapi(props.match.params.id)
        if (data.success === 'no') {
            Swal.fire({
                text: 'Invoice not Found',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Go Back',
            }).then((result) => {
                history.push('/dashboard')
            })
        } else {
            setInvoices(data.inv.arr_temp.SlibuyPayments)
            setaddress(data.inv.arr_temp.userShippdetail)
            setWonAmount(data.inv.arr_temp.sum_amount || 0)
            setGrandTotal(data.inv.arr_temp.sum_total_invoice || 0)
        }
    }

    useEffect(() => {
        invoiceData()
    }, [])

    useEffect(() => {
        console.log(Invoice)
    })

    return (
        <div className="container-lg beverlyhillsInvoice">
            <h3 className="invoiceTitle">Invoice</h3>
            <div className="d-flex justify-content-between align-items-center"></div>
            <div className="invoiceView " style={{ height: '100%', width: '100%' }}>
                <div className="card-header d-flex justify-content-between align-items-center p-4">
                    <a className="pt-2 d-inline-block" data-abc="true">
                        <img
                            src="/images/coin-auction-logo.png"
                            className="invLogo"
                            alt="beverlyhills Logo"
                        />
                    </a>
                    <div className="float-right invDate">
                        <h5 className="mb-0">Invoice Id : {Invoices[0].common_invoice}</h5>
                        {/* <h5 className="mb-0">Date: Jan 20 2021</h5> */}
                    </div>
                </div>
                <div className="card-body">
                    <div className="row mb-4">
                        <div className="col-sm-6 invAddr">
                            <h5 className="mb-3">From:</h5>
                            <h3 className="text-dark mb-1">Coins & Auctions Since 1994</h3>
                            <div>
                                <p>
                                    12014 SE Mill Plain Blvd, <br /> Ste#220B,
                                    <br /> Vancouver WA 98684 <br /> United States <br />
                                    Phone: (360) 253-5565
                                </p>
                            </div>
                        </div>
                        <div className="col-sm-6 invAddr text-right">
                            <h5 className="mb-3">To:</h5>
                            <h3 className="text-dark mb-1"> {Useraddress.first_name}</h3>
                            <div></div>
                            <div>
                                <p>
                                    {Useraddress.address} <br />
                                    {Useraddress.city} {Useraddress.state} <br />{' '}
                                    {Useraddress.country} <br />
                                    Phone: {Useraddress.phone}
                                </p>
                            </div>
                        </div>
                    </div>
                    <h4 className="invoiceSubTitle">Payment Type: Card Payment </h4>
                    <h4 className="invoiceSubTitle">Payment Details</h4>
                    <div className="transDet d-flex justify-content-between flex-wrap">
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th scope="col">ID</th>
                                    <th scope="col">Product Name</th>
                                    <th scope="col">Paid On</th>
                                    <th scope="col">Payment Type</th>
                                    <th scope="col">Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Invoices.map((Invoice) => (
                                    <tr>
                                        <td data-title="ID">{Invoice.id}</td>
                                        <td data-title="Product Name">{Invoice.title}</td>
                                        <td data-title="Paid On">
                                            {Invoice.paid_status ? Invoice.date_add : 'Unpaid'}
                                        </td>
                                        <td data-title="Payment Type">-</td>
                                        <td data-title="Amount">$ {Invoice.amount}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="row mt-5">
                        <div className="col-12">
                            <div className="table-responsive">
                                <table className="table table-clear beverlyInvTot">
                                    <tbody>
                                        <tr>
                                            <td className="left" align="right">
                                                <span className="text-dark">Won amount</span>
                                            </td>
                                            <td className="right">
                                                ${' '}
                                                {wonAmount && wonAmount != 0
                                                    ? parseFloat(wonAmount).toFixed(2)
                                                    : Invoices[0].amount}
                                            </td>
                                        </tr>
                                        {/* <tr>
                                            <td className="left" align="right">
                                                <span className="text-dark">
                                                    Admin added amount
                                                </span>
                                            </td>
                                            
                                            <td className="right">
                                                $ {Invoice.additional_amount_1}
                                            </td>
                                        </tr> */}
                                        {/* <tr>
                                            <td className="left" align="right">
                                                <span className="text-dark">Handling charges</span>
                                            </td>
                                            <td className="right">
                                                $ {Invoice.additional_amount_2}
                                            </td>
                                        </tr>  */}
                                        {/* <tr>
                                            <td className="left" align="right">
                                                <span className="text-dark">
                                                    {' '}
                                                    Additional charges
                                                </span>
                                            </td>
                                            <td className="right">
                                                $ {Invoice.additional_amount_3}
                                            </td>
                                        </tr> */}
                                        <tr style={{ background: 'rgb(243, 243, 243)' }}>
                                            <td className="left" align="right">
                                                <strong className="text-dark">Grand total</strong>
                                            </td>
                                            <td className="right">
                                                <strong className="text-dark">
                                                    $
                                                    {grandTotal && grandTotal != 0
                                                        ? parseFloat(grandTotal).toFixed(2)
                                                        : Invoices[0].amount}
                                                </strong>
                                                {/* <strong className="text-dark">
                                                    ${' '}
                                                    {parseFloat(Invoice.amount) +
                                                        parseFloat(Invoice.additional_amount_1) +
                                                        parseFloat(Invoice.additional_amount_2) +
                                                        parseFloat(Invoice.additional_amount_3)}
                                                </strong> */}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <h4 className="thankText">Thank you for shopping with us !</h4>
        </div>
    )
}

export default Invoice
