import axios from '.././Utils/Utility/axiosconfig'
import isAuthenticate from '.././Utils/Utility/auth'

export const auctionLotAction = (list) => ({
    type: 'CARTLIST',
    list: list,
})

const cartDetails = (body) => {
    console.log(
        'ooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooo',
    )
    return async (dispatch) => {
        try {
            const res = await axios.get('mobileapi/getUserCart?auction_io=1', body)
            console.log('auctionlotapi', res)
            if (res.data.success) {
                console.log(
                    'ppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppp',
                    res.data.data,
                )
                dispatch(auctionLotAction(res.data.data))
            } else {
                return `something went wrong`
            }
        } catch (error) {
            console.log(error)
        }
    }
}

export default cartDetails
