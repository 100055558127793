import React, { useEffect, useRef } from 'react'
import './App.css'
import Routes from './routes'
import { BrowserRouter as Router } from 'react-router-dom'
import { connect } from 'react-redux'
import { serverTime } from './actions/serverTimeAction'
import { livePriceAction } from './actions/livePriceAction'
import { socket, socketSeller } from './common/socket'
import { Userdetails } from './actions/useraction'
import isAuthenticate from './Utils/Utility/auth'
import { bidding } from './actions/biddingAction'
import { categoryAction } from './actions/categoryAction'
import { messageSocket, buyermessageScoket } from './actions/messagesocket'
import AlertState from './context/alert/alertState'
import { SnackbarProvider } from 'notistack'
import Button from '@material-ui/core/Button'
import { useCookies } from 'react-cookie'

const App = (props) => {
    const notistackRef = useRef()
    const onClickDismiss = (key) => () => {
        notistackRef.current.closeSnackbar(key)
    }
    const [cookies, setCookie] = useCookies(['user_session'])
    if (cookies.user_session && cookies.user_session != '') {
    } else {
        const session_id = Math.floor(100000 + Math.random() * 900000)
        setCookie('user_session', session_id, { path: '/' })
    }
    useEffect(() => {
        isAuthenticate() && props.User()
        socket.on('sliservertime', (data) => props.setServerTime(data.dTime))
        socket.on('metalsLivePrice', (data) => props.setMetalsLivePrice(data))
        socket.on('bidAddtime', (bidUpdateData) => props.bidding({ ...bidUpdateData }))
        socketSeller.on('bidAddtime', (bidUpdateData) => props.bidding({ ...bidUpdateData }))
        socket.on('chatmessages', (data) => {
            props.message(data.messages)
        })
        socket.on('insertMessages_result', (data) => {
            console.log(data, 'asdasdasd')
            props.byrmessage(data)
        })
        props.setCatrgorey()

        clearCacheData()
    }, [])

    const clearCacheData = () => {
        caches.keys().then((names) => {
            names.forEach((name) => {
                caches.delete(name)
            })
        })
    }

    const [ipValues, setIpValues] = React.useState(null)

    return (
        <AlertState>
            <SnackbarProvider
                maxSnack={3}
                ref={notistackRef}
                action={(key) => (
                    <Button style={{ color: '#fff' }} onClick={onClickDismiss(key)}>
                        <span className="material-icons">close</span>
                    </Button>
                )}
            >
                <div className="App">
                    <Router>
                        <Routes />
                    </Router>
                </div>
            </SnackbarProvider>
        </AlertState>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        setServerTime: (value) => dispatch(serverTime(value)),
        setMetalsLivePrice: (value) => dispatch(livePriceAction(value)),
        User: () => dispatch(Userdetails()),
        bidding: (value) => dispatch(bidding(value)),
        setCatrgorey: () => dispatch(categoryAction()),
        message: (value) => dispatch(messageSocket(value)),
        byrmessage: (value) => dispatch(buyermessageScoket(value)),
    }
}

export default connect(null, mapDispatchToProps)(App)
