import React from 'react'
import Slider from 'react-slick'
import './HomeTestimonial.css'
import { testimonial } from '../../../Utils/UI'
import Tooltip from '@material-ui/core/Tooltip'

const HomeTestimonial = () => {
    const testimonalArray = testimonial.filter((e) => e.stars > 4)

    const settings = {
        className: 'center',
        centerMode: true,
        infinite: true,
        centerPadding: '0px',
        slidesToShow: 3,
        speed: 500,
        nextArrow: <img src="/images/rightIcon.svg" />,
        prevArrow: <img src="/images/leftIcon.svg" />,
        responsive: [
            {
                breakpoint: 940,
                settings: {
                    centerPadding: '50px',
                    slidesToShow: 1,
                },
            },
        ],
    }

    return (
        <div className="tstSlickSlider">
            <Slider {...settings}>
                {testimonalArray.map((data, index) => (
                    <>
                        {/* {data.stars > 3 ? ( */}
                        <div>
                            <div className="testMCard">
                                <div className="topCnt row">
                                    <div className="col-md-5">
                                        <p>{data.date}</p>
                                    </div>
                                    <div className="col-md-2">
                                        <img src="/images/quotes.svg" />
                                    </div>
                                    <div className="col-md-5 text-right">
                                        <div className="d-flex align-items-center justify-content-end">
                                            {[1, 2, 3, 4, 5].map((values, index) => (
                                                <span
                                                    className={`material-icons ${
                                                        index + 1 <= data.stars ? 'active' : ''
                                                    }`}
                                                >
                                                    star
                                                </span>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <h5>{data.short_msg}</h5>
                                <Tooltip title={data.message}>
                                    <p className="rvwDscrption">{data.message}</p>
                                </Tooltip>
                                <div className="rvwrCnt text-center">
                                    <div className="mrtIcnWrpr">
                                        <span class="material-icons">account_circle</span>
                                    </div>
                                    <p className="mb-0">{data.userName}</p>
                                    <p className="my-0">
                                        <small>{data.location}</small>
                                    </p>
                                </div>
                            </div>
                        </div>
                        {/* ) : (
                            ''
                        )} */}
                    </>
                ))}
            </Slider>
        </div>
    )
}

export default HomeTestimonial
