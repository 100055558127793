// prettier-ignore
import React, { useContext, useEffect, useState } from 'react'
import CustomInput from '../../atoms/Inputs/CustomInput'
import PrimaryButton from '../../atoms/PrimaryButton'
import SecondaryButton from '../../atoms/SecondaryButton'
import clsx from 'clsx'
import Drawer from '@material-ui/core/Drawer'
import { Button, Badge } from '@material-ui/core'
import { useHistory, useLocation } from 'react-router-dom'
import { handleRedirectInternal, useCustomMediaQuery } from '../../../common/components'
import isAuthenticate from '../../../Utils/Utility/auth'
import './Header.css'
import { NavLink } from 'react-router-dom'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Link from '@material-ui/core/Link'
import Popper from '@material-ui/core/Popper'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import { makeStyles } from '@material-ui/core/styles'
import Swal from 'sweetalert2'
import { connect, useDispatch, useSelector } from 'react-redux'
import rapidSearch from '../../../actions/rapidSearch'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import cartDetails from '../../../actions/cartAuction'
import CartIcon from './CartIcon'
import Switch from '@material-ui/core/Switch'
import Tooltip from '@material-ui/core/Tooltip'
import { UserUnpaid } from '../../../actions/userUnpaid'
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    paper: {
        marginRight: theme.spacing(2),
    },
    list: {
        width: 300,
        // padding: '60px 70px 10px 60px',
    },
    fullList: {
        width: 'auto',
    },
}))

const Header = (props) => {
    const history = useHistory()
    const location = useLocation()
    const [text, setText] = React.useState('')
    const [text1, setText1] = React.useState('')
    const classes = useStyles()
    const [open, setOpen] = React.useState(false)
    const anchorRef = React.useRef(null)
    const dispatch = useDispatch()
    const [searchText, setSearchText] = React.useState([])
    const state_header = useSelector((state) => state.storeDetails)
    const [buynowenable, setBuynowEnable] = useState(false)
    const [lot_or_title, setLotorTitle] = useState(0)
    const [unpaidCount, setUnpaidCount] = useState(0)
    useEffect(() => {
        if (state_header?.storeDetails?.store_configuration?.length > 0) {
            state_header.storeDetails.store_configuration.map((data) => {
                if (data.variable == 'buynow_need_live_auction' && data.value == '1') {
                    setBuynowEnable(true)
                }
            })
        }
    }, [state_header])

    let deviceWidth = useCustomMediaQuery('(max-width:991px)')

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen)
    }

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return
        }

        setOpen(false)
    }

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault()
            setOpen(false)
        }
    }
    const hamMenu = useStyles()
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    })
    // top
    const toggleDrawers = (toppanel, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return
        }

        setState({ ...state, [toppanel]: open })
    }
    // top
    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return
        }

        setState({ ...state, [anchor]: open })
    }

    var pathname = window.location.pathname

    const onChange = (e) => {
        if (e.target.name == 'title') {
            setText1('')
            setLotorTitle(0)
            setText(e.target.value)
        } else {
            setText('')
            setLotorTitle(1)
            setText1(e.target.value)
        }
    }

    const onSubmit = (e) => {
        e.preventDefault()
        console.log('test view', text, text1)
        setState({
            top: false,
            right: false,
        })
        let query = new URLSearchParams(location.search)
        query.set('search', text != '' ? text : text1)
        query.set('ty', lot_or_title)
        if (window.location.pathname === '/past_auctions') {
            query.set('page', 1)
            history.push({ pathname: '/past_auctions', search: '?' + query.toString() })
        } else if (window.location.pathname === '/buynow') {
            query.set('page', 1)
            history.push({ pathname: '/buynow', search: '?' + query.toString() })
        } else {
            query.set('page', 1)
            if (text == '' && text1 == '') {
                query.set('limit', 10)
            } else {
                query.set('limit', 100)
            }
            history.push({ pathname: '/search', search: '?' + query.toString() })
        }
    }

    const searchtoggle = (event) => {
        localStorage.setItem('ty', event.target.checked ? 1 : 0)
        setLotorTitle(event.target.checked)
    }

    // top
    const toplists = (toppanel) => (
        <div
            className={clsx(hamMenu.list, {
                [classes.fullList]: toppanel === 'top' || toppanel === 'bottom',
            })}
            role="presentation"
            // onClick={toggleDrawer(anchor, true)}
            // onKeyDown={toggleDrawer(anchor, false)}
        >
            <header className="beforHead tpSliderWrpr">
                {/* <div className="navLogo">
                    <Link to="">
                        <img alt="LOGO" src="/images/Logo.png" />
                    </Link>
                    <Button className="headDrawerClose" onClick={toggleDrawers('top', false)}>
                        <span className="material-icons">close</span>
                    </Button>
                </div> */}
                <form className="Search" onSubmit={onSubmit}>
                    <div className="lotNoSearchContain d-flex align-items-center justify-content-start">
                        <div className="lotNoSearchWrapper">
                            <Autocomplete
                                freeSolo
                                options={searchText}
                                onChange={(event, newValue) => {
                                    console.log('newValue', newValue?.title, newValue)
                                    setText1('')
                                    setText(
                                        newValue?.title != undefined && newValue?.title != null
                                            ? newValue.title
                                            : newValue != null
                                            ? newValue
                                            : '',
                                    )
                                    setLotorTitle(0)
                                }}
                                onInputChange={(event, newInputValue, reason) => {
                                    if (reason === 'clear') {
                                        setText('')
                                    }
                                }}
                                inputValue={text}
                                getOptionLabel={(option) =>
                                    option.sku ? `${option.title} (${option.sku})` : option.title
                                }
                                renderInput={(params) => (
                                    <div className="customInput">
                                        <TextField
                                            {...params}
                                            name="title"
                                            onChange={onChange}
                                            variant={props.variant ? props.variant : 'outlined'}
                                            placeholder="Search Title"
                                            inputProps={{
                                                ...params.inputProps,
                                                onKeyDown: (e) => {
                                                    if (e.key === 'Enter') {
                                                        e.stopPropagation()
                                                    }
                                                },
                                            }}
                                        />
                                    </div>
                                )}
                            />
                        </div>
                        <div className="lotNoSearchWrapper">
                            <Autocomplete
                                freeSolo
                                options={[]}
                                onChange={(event, newValue) => {
                                    console.log('newValue', newValue?.title, newValue)
                                    setLotorTitle(1)
                                    setText1(
                                        newValue?.title != undefined && newValue?.title != null
                                            ? newValue.title
                                            : newValue != null
                                            ? newValue
                                            : '',
                                    )
                                }}
                                onInputChange={(event, newInputValue, reason) => {
                                    if (reason === 'clear') {
                                        setText1('')
                                    }
                                }}
                                inputValue={text1}
                                getOptionLabel={(option) =>
                                    option.sku ? `${option.title} (${option.sku})` : option.title
                                }
                                renderInput={(params) => (
                                    <div className="customInput">
                                        <TextField
                                            {...params}
                                            name="lotNumber"
                                            value={text1}
                                            onChange={onChange}
                                            variant={props.variant ? props.variant : 'outlined'}
                                            placeholder="Search Lot Number"
                                            inputProps={{
                                                ...params.inputProps,
                                                onKeyDown: (e) => {
                                                    if (e.key === 'Enter') {
                                                        e.stopPropagation()
                                                    }
                                                },
                                            }}
                                        />
                                    </div>
                                )}
                            />
                            <Button type="submit">
                                <span className="material-icons">search</span>
                            </Button>
                        </div>
                    </div>
                    {/* {window.location.pathname != '/past_auctions' ? (
                        <>
                            <div className="das-togle-switch">
                                <Switch
                                    checked={lot_or_title}
                                    onChange={(event) => searchtoggle(event)}
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                />
                            </div>
                            <Tooltip
                                placement="top"
                                title={
                                    <div
                                        style={{
                                            // width: '300px',
                                            // padding: '5px',
                                            fontSize: '16px',
                                            lineHeight: '24px',
                                        }}
                                    >
                                        Switch on to search with lot number.
                                    </div>
                                }
                                arrow
                            >
                                <span className="material-icons">info</span>
                            </Tooltip>
                        </>
                    ) : (
                        ''
                    )} */}
                </form>
            </header>
        </div>
    )
    // top
    const list = (anchor) => (
        <div
            className={clsx(hamMenu.list, {
                [classes.fullList]: anchor === 'top' || anchor === 'bottom',
            })}
            role="presentation"
            // onClick={toggleDrawer(anchor, true)}
            // onKeyDown={toggleDrawer(anchor, false)}
        >
            <header className="beforHead">
                <div className="navLogo">
                    <Link to="">
                        <img alt="LOGO" src="/images/coin-auction-logo.png" />
                    </Link>
                    <Button className="headDrawerClose" onClick={toggleDrawer('right', false)}>
                        <span className="material-icons">close</span>
                    </Button>
                </div>
                <form className="Search srchSidBar" onSubmit={onSubmit}>
                    <div className="lotNoSearchContain d-flex align-items-center justify-content-start">
                        <div className="lotNoSearchWrapper">
                            <Autocomplete
                                freeSolo
                                options={searchText}
                                onChange={(event, newValue) => {
                                    console.log('newValue', newValue?.title, newValue)
                                    setLotorTitle(0)
                                    setText1('')
                                    setText(
                                        newValue?.title != undefined && newValue?.title != null
                                            ? newValue.title
                                            : newValue != null
                                            ? newValue
                                            : '',
                                    )
                                }}
                                onInputChange={(event, newInputValue, reason) => {
                                    if (reason === 'clear') {
                                        setText('')
                                    }
                                }}
                                getOptionLabel={(option) =>
                                    option.sku ? `${option.title} (${option.sku})` : option.title
                                }
                                inputValue={text}
                                renderInput={(params) => (
                                    <div className="customInput">
                                        <TextField
                                            {...params}
                                            onChange={onChange}
                                            name="title"
                                            variant={props.variant ? props.variant : 'outlined'}
                                            placeholder="Search Title"
                                            inputProps={{
                                                ...params.inputProps,
                                                onKeyDown: (e) => {
                                                    if (e.key === 'Enter') {
                                                        e.stopPropagation()
                                                    }
                                                },
                                            }}
                                        />
                                    </div>
                                )}
                            />
                        </div>
                        <div className="lotNoSearchWrapper">
                            <Autocomplete
                                freeSolo
                                options={[]}
                                onChange={(event, newValue) => {
                                    console.log('newValue', newValue?.title, newValue)
                                    setText1(
                                        newValue?.title != undefined && newValue?.title != null
                                            ? newValue.title
                                            : newValue != null
                                            ? newValue
                                            : '',
                                    )
                                    setLotorTitle(1)
                                }}
                                onInputChange={(event, newInputValue, reason) => {
                                    if (reason === 'clear') {
                                        setText1('')
                                    }
                                }}
                                getOptionLabel={(option) =>
                                    option.sku ? `${option.title} (${option.sku})` : option.title
                                }
                                inputValue={text1}
                                renderInput={(params) => (
                                    <div className="customInput">
                                        <TextField
                                            {...params}
                                            onChange={onChange}
                                            name="lotNumber"
                                            value={text1}
                                            variant={props.variant ? props.variant : 'outlined'}
                                            placeholder="Search Lot Number"
                                            inputProps={{
                                                ...params.inputProps,
                                                onKeyDown: (e) => {
                                                    if (e.key === 'Enter') {
                                                        e.stopPropagation()
                                                    }
                                                },
                                            }}
                                        />
                                    </div>
                                )}
                            />
                            <Button type="submit">
                                <span className="material-icons">search</span>
                            </Button>
                        </div>
                    </div>

                    {/* {window.location.pathname != '/past_auctions' ? (
                        <>
                            <Switch
                                checked={lot_or_title}
                                onChange={(event) => searchtoggle(event)}
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                            />
                            <Tooltip
                                placement="top"
                                title={
                                    <div
                                        style={{
                                            // width: '300px',
                                            // padding: '5px',
                                            fontSize: '16px',
                                            lineHeight: '24px',
                                        }}
                                    >
                                        Switch on to search with lot number.
                                    </div>
                                }
                                arrow
                            >
                                <span className="material-icons">info</span>
                            </Tooltip>
                        </>
                    ) : (
                        ''
                    )} */}
                </form>
                <>
                    {isAuthenticate() != null ? (
                        <>
                            <List onClick={toggleDrawer('right', false)}>
                                <ListItem
                                    className={pathname === '/search' ? 'active' : ''}
                                    button
                                    onClick={() => handleRedirectInternal(history, 'search')}
                                >
                                    <i className="fas fa-gavel"></i>
                                    <ListItemIcon>Auctions</ListItemIcon>
                                </ListItem>

                                {buynowenable ? (
                                    <ListItem
                                        className={pathname === '/buynow' ? 'active' : ''}
                                        button
                                        onClick={() => handleRedirectInternal(history, 'buynow')}
                                    >
                                        <span className="material-icons">local_mall</span>
                                        <ListItemIcon>Buy Now</ListItemIcon>
                                    </ListItem>
                                ) : (
                                    ''
                                )}
                                <ListItem
                                    className={pathname === '/past_auctions' ? 'active' : ''}
                                    button
                                    onClick={() => handleRedirectInternal(history, 'past_auctions')}
                                >
                                    <i className="fas fa-gavel"></i>
                                    <ListItemIcon>Past Auctions</ListItemIcon>
                                </ListItem>

                                <ListItem
                                    className={pathname === '/dashboard' ? 'active' : ''}
                                    button
                                    onClick={() => handleRedirectInternal(history, 'dashboard')}
                                >
                                    <span className="material-icons">dashboard</span>
                                    <ListItemIcon>Dashboard</ListItemIcon>
                                </ListItem>
                                <ListItem
                                    className={pathname === '/contactus' ? 'active' : ''}
                                    button
                                    onClick={() => handleRedirectInternal(history, 'contactus')}
                                >
                                    <span className="material-icons">perm_phone_msg</span>
                                    <ListItemIcon>Contact Us</ListItemIcon>
                                </ListItem>
                                {/* <ListItem
                                    className={pathname === '/privacypolicy' ? 'active' : ''}
                                    button
                                    onClick={() => handleRedirectInternal(history, 'privacypolicy')}
                                >
                                    <span className="material-icons">privacy_tip</span>
                                    <ListItemIcon>Privacy policy</ListItemIcon>
                                </ListItem> */}
                                <ListItem
                                    className={pathname === '/TermsandConditions' ? 'active' : ''}
                                    button
                                    onClick={() =>
                                        handleRedirectInternal(history, 'TermsandConditions')
                                    }
                                >
                                    <span className="material-icons">fact_check</span>
                                    <ListItemIcon>Terms and Conditions</ListItemIcon>
                                </ListItem>
                                <ListItem>
                                    <PrimaryButton
                                        label="Log Out"
                                        onClick={() => {
                                            localStorage.removeItem('userToken')
                                            Swal.fire({
                                                icon: 'success',
                                                title: 'Logged out successfully',
                                                timer: 1500,
                                                showConfirmButton: false,
                                                position: 'center',
                                            })
                                            history.push('/login')
                                        }}
                                    />
                                </ListItem>
                            </List>
                        </>
                    ) : (
                        <>
                            <List onClick={toggleDrawer('right', false)}>
                                <ListItem
                                    button
                                    onClick={() => handleRedirectInternal(history, 'search')}
                                >
                                    {/* <span className="material-icons">shopping_bag</span>
                                     */}
                                    <i className="fas fa-gavel"></i>
                                    <ListItemIcon>Auctions</ListItemIcon>
                                </ListItem>
                                <ListItem
                                    className={pathname === '/past_auctions' ? 'active' : ''}
                                    button
                                    onClick={() => handleRedirectInternal(history, 'past_auctions')}
                                >
                                    <i className="fas fa-gavel"></i>
                                    <ListItemIcon>Past Auctions</ListItemIcon>
                                </ListItem>
                                <ListItem
                                    button
                                    onClick={() => handleRedirectInternal(history, 'contactus')}
                                >
                                    <span className="material-icons">perm_phone_msg</span>
                                    <ListItemIcon>Contact Us</ListItemIcon>
                                </ListItem>
                                <ListItem
                                    button
                                    onClick={() => handleRedirectInternal(history, 'privacypolicy')}
                                >
                                    <span className="material-icons">privacy_tip</span>
                                    <ListItemIcon>Privacy policy</ListItemIcon>
                                </ListItem>
                                <ListItem
                                    button
                                    onClick={() =>
                                        handleRedirectInternal(history, 'termsandconditions')
                                    }
                                >
                                    <span className="material-icons">fact_check</span>
                                    <ListItemIcon>Terms and Conditions</ListItemIcon>
                                </ListItem>
                                <ListItem>
                                    <SecondaryButton
                                        label="Login"
                                        onClick={() => handleRedirectInternal(history, 'login')}
                                    />
                                </ListItem>
                                <ListItem>
                                    <PrimaryButton
                                        className="dasda"
                                        label="Register for Auction"
                                        onClick={() => handleRedirectInternal(history, 'signup')}
                                    />
                                </ListItem>
                            </List>
                        </>
                    )}
                </>
                {/* {useCustomMediaQuery('(max-width: 991px)') ? (
                ) : null} */}
            </header>
        </div>
    )

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open)
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus()
        }

        prevOpen.current = open
    }, [open])

    // console.log('deviceWidth====>' asdasd, deviceWidth)

    useEffect(() => {
        console.log('use effect rendered')
        dispatch(
            rapidSearch({
                search_keyword: text,
                status: window.location.pathname == '/past_auctions' ? 'sold' : 'open',
                type: window.location.pathname == '/past_auctions' ? 'auction' : 'lots',
                lot_search: lot_or_title ? 1 : 0,
            }),
        )
    }, [text])

    useEffect(() => {
        if (props.searchText?.rapidSearch?.length > 0) {
            setSearchText(props.searchText.rapidSearch)
        }
    }, [props.searchText])
    useEffect(() => {
        if (props.unpaidCount?.all_counts) {
            setUnpaidCount(props.unpaidCount?.all_counts?.itemunPaidCount || 0)
        }
    }, [props.unpaidCount])

    useEffect(() => {
        cartDetails({ auction_io: 1, city_sales_tax_enable: 0 })
    }, [])

    useEffect(() => {
        if (isAuthenticate() && props.Profile?.userdetails?.id) {
            dispatch(UserUnpaid(props.Profile.userdetails.id))
        }
    }, [props.Profile])
    return (
        <>
            {deviceWidth ? (
                <header>
                    <div className="resHeader customContainer d-flex justify-content-between align-items-center">
                        <a href="/">
                            <img
                                className="logo siteLogo"
                                src="/images/coin-auction-logo.png"
                                alt="Coins-and-Auctions-Since-1994"
                            />
                        </a>
                        <div className="quickNots">
                            {isAuthenticate() != null && <CartIcon />}
                            <div className="headerNav">
                                {['top'].map((toppanel) => (
                                    <React.Fragment key={toppanel}>
                                        {isAuthenticate() != null ? (
                                            <div className="imgBtn">
                                                <Button onClick={toggleDrawers(toppanel, true)}>
                                                    <span className="material-icons">search</span>
                                                </Button>
                                            </div>
                                        ) : null}
                                        <Drawer
                                            anchor={toppanel}
                                            open={state[toppanel]}
                                            onClose={toggleDrawers(toppanel, false)}
                                        >
                                            {toplists(toppanel)}
                                        </Drawer>
                                    </React.Fragment>
                                ))}
                            </div>
                            {isAuthenticate() != null ? (
                                <>
                                    {/* // deviceWidth ? ( */}

                                    <div className="headerNav">
                                        <Button aria-haspopup="true">
                                            <span
                                                className="material-icons"
                                                onClick={() => {
                                                    localStorage.removeItem('userToken')
                                                    Swal.fire({
                                                        icon: 'success',
                                                        title: 'Logged out successfully',
                                                        timer: 1500,
                                                        showConfirmButton: false,
                                                        position: 'center',
                                                    })
                                                    history.push('/login')
                                                }}
                                            >
                                                power_settings_new
                                            </span>
                                        </Button>
                                    </div>
                                    {/* // ) : ( */}
                                    <div className="headerNav">
                                        {['right'].map((anchor) => (
                                            <React.Fragment key={anchor}>
                                                <div className="imgBtn">
                                                    <Button onClick={toggleDrawer(anchor, true)}>
                                                        <span className="material-icons">
                                                            segment
                                                        </span>
                                                    </Button>
                                                </div>
                                                <Drawer
                                                    anchor={anchor}
                                                    open={state[anchor]}
                                                    onClose={toggleDrawer(anchor, false)}
                                                >
                                                    {list(anchor)}
                                                </Drawer>
                                            </React.Fragment>
                                        ))}
                                    </div>
                                    {/* // ) */}
                                </>
                            ) : (
                                <div className="headerNav">
                                    {['right'].map((anchor) => (
                                        <React.Fragment key={anchor}>
                                            <div className="imgBtn">
                                                <Button onClick={toggleDrawer(anchor, true)}>
                                                    <span className="material-icons">segment</span>
                                                </Button>
                                            </div>
                                            <Drawer
                                                anchor={anchor}
                                                open={state[anchor]}
                                                onClose={toggleDrawer(anchor, false)}
                                            >
                                                {list(anchor)}
                                            </Drawer>
                                        </React.Fragment>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                </header>
            ) : (
                <header>
                    <div
                        class={` ${
                            window.location.pathname === '/'
                                ? 'hmcustomContainer'
                                : 'customContainer'
                        } d-flex align-items-center justify-content-between`}
                    >
                        <a href="/">
                            <img
                                className="logo siteLogo"
                                src="/images/coin-auction-logo.png"
                                alt="Coins-and-Auctions-Since-1994-Logo"
                            />
                        </a>
                        <form className="Search d-flex justify-content-between" onSubmit={onSubmit}>
                            <div className="searchInputGroup">
                                <Autocomplete
                                    freeSolo
                                    options={searchText}
                                    onChange={(event, newValue) => {
                                        setLotorTitle(0)
                                        setText1('')
                                        setText(
                                            newValue?.title != undefined && newValue?.title != null
                                                ? newValue.title
                                                : newValue != null
                                                ? newValue
                                                : '',
                                        )
                                    }}
                                    inputValue={text}
                                    onInputChange={(event, newInputValue, reason) => {
                                        if (reason === 'clear') {
                                            setText('')
                                        }
                                    }}
                                    // onChange={onChange}
                                    getOptionLabel={(option) =>
                                        option.sku
                                            ? `${option.title} (${option.sku})`
                                            : option.title
                                    }
                                    renderInput={(params) => (
                                        <div className="customInput">
                                            <TextField
                                                {...params}
                                                onChange={onChange}
                                                name="title"
                                                value={text}
                                                variant={props.variant ? props.variant : 'outlined'}
                                                placeholder="Search Title"
                                                inputProps={{
                                                    ...params.inputProps,
                                                    onKeyDown: (e) => {
                                                        if (e.key === 'Enter') {
                                                            e.stopPropagation()
                                                        }
                                                    },
                                                }}
                                            />
                                        </div>
                                    )}
                                />
                            </div>
                            <div className="searchInputGroup">
                                <Autocomplete
                                    freeSolo
                                    options={[]}
                                    onChange={(event, newValue) => {
                                        console.log('newValue', newValue?.title, newValue)
                                        setText1(
                                            newValue?.title != undefined && newValue?.title != null
                                                ? newValue.title
                                                : newValue != null
                                                ? newValue
                                                : '',
                                        )
                                        setLotorTitle(1)
                                    }}
                                    onInputChange={(event, newInputValue, reason) => {
                                        if (reason === 'clear') {
                                            setText1('')
                                        }
                                    }}
                                    inputValue={text1}
                                    // onChange={onChange}
                                    getOptionLabel={(option) =>
                                        option.sku
                                            ? `${option.title} (${option.sku})`
                                            : option.title
                                    }
                                    renderInput={(params) => (
                                        <div className="customInput">
                                            <TextField
                                                {...params}
                                                onChange={onChange}
                                                name="lotNumber"
                                                value={text1}
                                                variant={props.variant ? props.variant : 'outlined'}
                                                onKeyDown={(event) => {
                                                    if (event.key == 'Enter') {
                                                        event.preventDefault()
                                                    }
                                                }}
                                                placeholder="Search Lot Number"
                                                inputProps={{
                                                    ...params.inputProps,
                                                    onKeyDown: (e) => {
                                                        if (e.key === 'Enter') {
                                                            e.stopPropagation()
                                                        }
                                                    },
                                                }}
                                            />
                                        </div>
                                    )}
                                />
                                <Button type="submit">
                                    <span className="material-icons">search</span>
                                </Button>
                            </div>
                        </form>
                        {/* {window.location.pathname != '/past_auctions' ? (
                            <>
                                <Switch
                                    checked={lot_or_title}
                                    onChange={(event) => searchtoggle(event)}
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                />
                                <Tooltip
                                    placement="top"
                                    title={
                                        <div
                                            style={{
                                                fontSize: '16px',
                                                lineHeight: '24px',
                                            }}
                                        >
                                            Switch on to search with lot number.
                                        </div>
                                    }
                                    arrow
                                >
                                    <span className="material-icons">info</span>
                                </Tooltip>
                            </>
                        ) : (
                            ''
                        )} */}
                        <ul className="headerLinks d-flex">
                            <NavLink
                                to="/"
                                className={window.location.pathname === '/' ? 'active' : ''}
                                activeClassName="inactive"
                            >
                                <li>Home</li>
                            </NavLink>
                            <NavLink to="/search" activeClassName="active">
                                <li>Auctions</li>
                            </NavLink>

                            {isAuthenticate() && buynowenable ? (
                                <NavLink to="/buynow" activeClassName="active">
                                    <li>Buy Now</li>
                                </NavLink>
                            ) : (
                                ''
                            )}
                            <NavLink to="/past_auctions" activeClassName="active">
                                <li>Past Auctions</li>
                            </NavLink>
                            {/* <NavLink to="#">
                                <li>Buy Now</li>
                            </NavLink> */}
                            {isAuthenticate() !== null ? (
                                <>
                                    <NavLink to="/dashboard">
                                        <li>Dashboard</li>
                                    </NavLink>
                                    <li>
                                        {' '}
                                        <CartIcon />{' '}
                                    </li>

                                    <li className="prlDrop">
                                        <div className={classes.root}>
                                            <div>
                                                <Button
                                                    className="prflButton"
                                                    ref={anchorRef}
                                                    aria-controls={
                                                        open ? 'menu-list-grow' : undefined
                                                    }
                                                    aria-haspopup="true"
                                                    onClick={handleToggle}
                                                >
                                                    <span className="material-icons">person</span>
                                                    {props.Profile.userdetails.first_name}
                                                </Button>
                                                <Popper
                                                    open={open}
                                                    anchorEl={anchorRef.current}
                                                    role={undefined}
                                                    transition
                                                    disablePortal
                                                >
                                                    {({ TransitionProps, placement }) => (
                                                        <Grow
                                                            {...TransitionProps}
                                                            style={{
                                                                transformOrigin:
                                                                    placement === 'bottom'
                                                                        ? 'center top'
                                                                        : 'center bottom',
                                                            }}
                                                        >
                                                            <Paper>
                                                                <ClickAwayListener
                                                                    onClickAway={handleClose}
                                                                >
                                                                    <MenuList
                                                                        autoFocusItem={open}
                                                                        id="menu-list-grow"
                                                                        onKeyDown={
                                                                            handleListKeyDown
                                                                        }
                                                                    >
                                                                        <MenuItem
                                                                            onClick={() => {
                                                                                localStorage.removeItem(
                                                                                    'userToken',
                                                                                )
                                                                                Swal.fire({
                                                                                    icon: 'success',
                                                                                    title: 'Logged out successfully',
                                                                                    timer: 1500,
                                                                                    showConfirmButton: false,
                                                                                    position:
                                                                                        'center',
                                                                                })
                                                                                history.push(
                                                                                    '/login',
                                                                                )
                                                                            }}
                                                                        >
                                                                            Logout
                                                                        </MenuItem>
                                                                    </MenuList>
                                                                </ClickAwayListener>
                                                            </Paper>
                                                        </Grow>
                                                    )}
                                                </Popper>
                                            </div>
                                        </div>
                                    </li>
                                </>
                            ) : (
                                <>
                                    <li>
                                        <SecondaryButton
                                            label="Login"
                                            onClick={() => handleRedirectInternal(history, 'login')}
                                        />
                                    </li>
                                    <li className="pr-0">
                                        <PrimaryButton
                                            label="Register for Auction"
                                            onClick={() =>
                                                handleRedirectInternal(history, 'signup')
                                            }
                                        />
                                    </li>
                                </>
                            )}
                        </ul>
                    </div>
                    {/* {unpaidCount ? (
                        <div className="auctionHasItems noPrint">
                            <img src="/images/TrophyIcon.svg" />
                            <h3>
                                {' '}
                                Congratulations! You have won an auction.
                                <NavLink style={{ color: 'blue' }} to={`/dashboard?s=2`}>
                                    Click here
                                </NavLink>{' '}
                                to make the payment.
                            </h3>
                        </div>
                    ) : null} */}
                </header>
            )}
        </>
    )
}

const mapStateToProps = (state) => ({
    Profile: state.User,
    searchText: state.rapidSearch,
    unpaidCount: state.userUnpaid,
})

export default connect(mapStateToProps, null)(Header)
