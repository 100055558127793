import React from 'react'
import CustomInput from '../../components/atoms/Inputs/CustomInput'
import PrimaryButton from '../../components/atoms/PrimaryButton/index'
import CustomInputs from '../../components/atoms/Inputs/CustomTextArea'
import { Formik } from 'formik'
import * as yup from 'yup'
import axios from '../../Utils/Utility/axiosconfig'
import { useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'
import './HomeContact.css'
import { TextField } from '@material-ui/core'

const HomeContact = () => {
    const history = useHistory()
    const contactusschema = yup.object({
        name: yup
            .string()
            .required('Required')
            .matches(/^[aA-zZ]+$/, 'only alphabets are allowed'),
        email: yup.string().email().required('Enter your Email Id'),
        pnumber: yup.string().required('Required!'),
        message: yup.string().required('Required!'),
    })

    const contactusapi = async (data) => {
        try {
            const res = await axios.post('mobileapi/send_contactus', {
                name: data.name,
                from_email: data.email,
                phone_number: data.pnumber ? data.pnumber : '',
                messages: data.message,
                subject: 'coins',
            })

            if (res.data.success === true) {
                Swal.fire({
                    title: 'Form Submitted Successfully',
                    icon: 'success',
                    timer: 2000,
                    showConfirmButton: false,
                    position: 'center',
                })
                history.push('/')
            }
        } catch (error) {
            console.log(error)
        }
    }

    const acceptstring = (change) => (e) => {
        console.log('cccc', e.target.value)
        if (/^[aA-zZ]+$/.test(e.target.value) || e.target.value == '') {
            change(e)
        }
    }
    const acceptnumber = (change) => (e) => {
        console.log('cccc', e.target.value)
        if (/^[0-9]+$/.test(e.target.value) || e.target.value == '') {
            change(e)
        }
    }

    // const resetForm = () => {
    //     Formik.values.name = ''
    //     Formik.values.email = ''
    //     Formik.values.pnumber = ''
    //     Formik.values.message = ''
    //     Formik.resetForm()
    // }

    return (
        <div className="contactWrapper d-flex align-items-center justify-content-between">
            <div className="cntcInfoWrpr">
                <div className="flxWrpGrd">
                    <img src="/images/mail_contact.svg" />
                    <div className="rtInfoTxtWrpr mlWrpr">
                        <a href="mailto:ken@coinssince1994.com">ken@coinssince1994.com</a> <br />
                        <a href="mailto:takumi@coinssince1994.com">takumi@coinssince1994.com</a>
                    </div>
                </div>
                <div className="flxWrpGrd">
                    <img src="/images/phone_contact.svg" />
                    <div className="rtInfoTxtWrpr">
                        <p>
                            <span>Toll-free :</span>{' '}
                            <a href="tel:8886552646">(888) 655-COIN(2646)</a>
                        </p>
                        <p>
                            <span>Text :</span> <a href="tel:3602815588">(360) 281-5588</a>
                        </p>
                        <p>
                            <span>Local :</span> <a href="tel:3602535565"> (360) 253-5565</a>
                        </p>
                    </div>
                </div>
                <div className="flxWrpGrd">
                    <img src="/images/location_contact.svg" />
                    <div className="rtInfoTxtWrpr">
                        <p>
                            12014 SE Mill Plain Blvd. <br /> Suite 220B Vancouver, WA <br />
                            98684
                        </p>
                    </div>
                </div>
            </div>
            <div className="contactFormWrapper">
                <Formik
                    initialValues={{ name: '', email: '', pnumber: '', message: '' }}
                    validationSchema={contactusschema}
                    onSubmit={(values, actions) => {
                        contactusapi(values).then(() => {
                            actions.setSubmitting(false)
                            actions.resetForm({
                                values: {
                                    name: '',
                                    email: '',
                                    pnumber: '',
                                    message: '',
                                },
                            })
                        })
                    }}
                >
                    {({ errors, touched, values, handleChange, handleBlur, handleSubmit }) => (
                        <form onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-md-12">
                                    <CustomInput
                                        variant="outlined"
                                        label="Name"
                                        name="name"
                                        value={values.name}
                                        onChange={acceptstring(handleChange)}
                                        helperText={errors.name && touched.name ? errors.name : ''}
                                    />
                                </div>
                                <div className="col-md-6  mt-3">
                                    <CustomInput
                                        variant="outlined"
                                        label="Email Address"
                                        name="email"
                                        value={values.email}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        helperText={
                                            errors.email && touched.email ? errors.email : ''
                                        }
                                    />
                                </div>
                                <div className="col-md-6  mt-3">
                                    <CustomInput
                                        variant="outlined"
                                        label="Phone No"
                                        name="pnumber"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.pnumber}
                                        helperText={
                                            errors.pnumber && touched.pnumber ? errors.pnumber : ''
                                        }
                                    />
                                </div>
                                <div className="col-md-12 mt-3">
                                    <CustomInput
                                        variant="outlined"
                                        label="Message"
                                        name="message"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        multiline
                                        rows={5}
                                        placeholder="Write your message here..."
                                        value={values.message}
                                        helperText={
                                            errors.message && touched.message
                                                ? 'Please Enter Message'
                                                : ''
                                        }
                                    />
                                </div>
                                <div className="col-md-12 mt-4">
                                    <PrimaryButton label="Send" type="submit" />
                                </div>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
        </div>
    )
}

export default HomeContact
