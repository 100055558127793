import React from 'react'
import Slider from 'react-slick'
import HomeAuctionCard from '../../components/molecules/ProductCard/HomeAuctionCard'

const HomeAuction = (props) => {
    const settings = {
        className: 'center',
        centerMode: false,
        infinite: true,
        centerPadding: '60px',
        slidesToShow: 1,
        speed: 500,
        variableWidth: true,
        nextArrow: <img src="/images/rightIcon.svg" />,
        prevArrow: <img src="/images/leftIcon.svg" />,
        responsive: [
            {
                breakpoint: 940,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                    variableWidth: false,
                },
            },
        ],
    }
    return (
        <div className="hmActnSlider">
            <Slider {...settings}>
                {props.auctionSearch.map((data, index) => (
                    <div>
                        <HomeAuctionCard
                            imgSrc={process.env.REACT_APP_PRODUCT_IMG + data.file_name}
                            Name={data.ptitle}
                            descrp={data.description}
                            label={data.con_check == 1 ? 'View Live Auction' : 'View Timed Auction'}
                            startTime={data.date_added}
                            endTime={data.date_closed}
                            id={data.id}
                            highbid_user_id={data.highbid_user_id}
                            userDetails={props.User}
                            con_check={data.con_check}
                        />
                    </div>
                ))}
            </Slider>
        </div>
    )
}

export default HomeAuction
