import React, { useState, useRef } from 'react'
import CustomInput from '../../components/atoms/Inputs/CustomInput'
import PrimaryButton from '../../components/atoms/PrimaryButton/index'
import { Button } from '@material-ui/core'
import Popup from '../../components/organisms/Popup/achpopup'
import { Formik } from 'formik'
import * as Yup from 'yup'
import valid from 'card-validator'
import _, { isEmpty } from 'lodash'
import { createBankStripe, BuyerAddBank, verifyBankdetails } from '../../Utils/API/payamountach'
import Swal from 'sweetalert2'
import './Addnewcard.css'
import Country from '../Signup/Country.json'
import State from '../Signup/State.json'
import cities from '../Signup/cities.json'
import SimpleSelect from '../../components/atoms/DropDown/index'
import SimpleSelect1 from '../../components/atoms/DropDown/Dropdown1'
import SimpleSelect2 from '../../components/atoms/DropDown/Dropdown2'
import CustomSelect from '../../components/atoms/Inputs/CustomSelect'

const Addnewcard = (props) => {
    const Data = ['Card Number', 'MM', 'YYYY', 'Name on the Card', 'Cvv']

    const [modalOpen, setmodalOpen] = useState(false)
    const [Id, setID] = useState('')
    const [sId, setStateId] = useState('')
    const [Countrycode, setCountrycode] = useState('')
    const [loading, setLoading] = useState(false)

    const modalClose = () => {
        setmodalOpen(false)
        props.setBankVerifyPopup(false)
    }

    const formRef = useRef()

    const addcardapi = async (values) => {
        setLoading(true)
        let user = JSON.parse(localStorage.getItem('userData'))
        try {
            var data = {
                site_id: process.env.REACT_APP_SITE_ID,
                email: user.email ? user.email : '',
                user_id: user.id ? user.id : '',
                holdername: values.bank_account_holder_name,
            }

            const isSuccess = await createBankStripe(data)
            if (
                isSuccess.data.status === 'success' ||
                isSuccess.data.data.message == 'Customer account already created!'
            ) {
                var bank_data = {
                    site_id: process.env.REACT_APP_SITE_ID,
                    user_id: user.id ? user.id : '',
                    account_no: values.bank_account_number,
                    rounting_no: values.bank_routing_number,
                    holdername: values.bank_account_holder_name,
                }
                const res_from_bank = await BuyerAddBank(bank_data)
                if (res_from_bank.data.status === 'success') {
                    props.upd()
                    setmodalOpen(false)
                    Swal.fire({
                        title: 'Bank Details Added Successfully',
                        icon: 'success',
                        timer: 1500,
                        showConfirmButton: false,
                        position: 'center',
                    })
                    props.setBankVerifyPopup(true)
                } else {
                    console.log(
                        '++++++++++++++++++++++++++++++++++++',
                        res_from_bank.data.data.message,
                    )
                    //setmodalOpen(false)
                    Swal.fire({
                        title: res_from_bank.data.data.message,
                        icon: 'error',
                        timer: 2500,
                        showConfirmButton: false,
                        position: 'center',
                    })
                }
            } else {
                Swal.fire({
                    title: isSuccess.data.data.message,
                    icon: 'error',
                    timer: 2500,
                    showConfirmButton: false,
                    position: 'center',
                })
            }
            setLoading(false)
        } catch (error) {
            console.log(error)
        }
    }

    const verifyapi = async (values) => {
        setLoading(true)
        let user = JSON.parse(localStorage.getItem('userData'))
        try {
            values.site_id = process.env.REACT_APP_SITE_ID
            values.user_id = user.id ? user.id : ''
            const res_ver = await verifyBankdetails(values)
            if (res_ver.data.status == 'success') {
                props.setBankVerifyPopup(false)
                // props.AllCard[parseInt(props.pay_index)].status = 'verified'
                // props.setAllCard([...props.AllCard])
                props.update_card()
                Swal.fire({
                    title: 'Bank Verified Successfully',
                    icon: 'success',
                    timer: 1500,
                    showConfirmButton: false,
                    position: 'center',
                })
            } else {
                //props.setBankVerifyPopup(false)
                Swal.fire({
                    title: res_ver.data.data.message,
                    icon: 'error',
                    timer: 1500,
                    showConfirmButton: false,
                    position: 'center',
                })
            }
            setLoading(false)
        } catch (error) {
            console.log(error)
        }
    }

    const addcard = Yup.object({
        bank_account_holder_name: Yup.string().required('Required'),
        bank_account_holder_type: Yup.string().required('Required'),
        bank_routing_number: Yup.string().required('Required').min(9, 'Minimum 9 digits'),
        bank_account_number: Yup.string().required('Required'),
    })

    const bankcard = Yup.object({
        payAmount1: Yup.string().required('Required'),
        payAmount2: Yup.string().required('Required'),
    })

    return (
        <div className="addNewcard-parent adBankPopup">
            <Button onClick={() => setmodalOpen(true)} className={`adBnkBtns`}>
                <div className="Addcard-Payment w-100">
                    <h6 className="m-0 d-flex align-items-center">
                        <span className="material-icons mr-2">account_balance</span>ADD A NEW BANK
                        DETAILS
                    </h6>
                </div>
            </Button>
            {modalOpen || props.bankverifypopup ? (
                <Popup
                    open={modalOpen || props.bankverifypopup}
                    modaltitle={modalOpen ? 'bank' : 'verify'}
                    handleClose={() => modalClose()}
                >
                    {modalOpen ? (
                        <Formik
                            initialValues={{
                                bank_account_holder_name: '',
                                bank_account_holder_type: '',
                                bank_routing_number: '',
                                bank_account_number: '',
                            }}
                            validationSchema={addcard}
                            innerRef={formRef}
                            onSubmit={(values) => {
                                addcardapi(values)
                            }}
                        >
                            {({
                                errors,
                                touched,
                                values,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                setFieldValue,
                            }) => (
                                <form onSubmit={handleSubmit}>
                                    <div className="AddCard-Popup-Banner">
                                        <div className="Addcard-Popup-Input">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <CustomInput
                                                            label="Holder Name"
                                                            variant="standard"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            name="bank_account_holder_name"
                                                            helperText={
                                                                errors.bank_account_holder_name &&
                                                                touched.bank_account_holder_name
                                                                    ? 'Please Enter Name'
                                                                    : ''
                                                            }
                                                            type="text"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    {console.log(
                                                        'ppppppppppppppppppppppppppppppppppppppppppppppppppppppp',
                                                        values.bank_account_holder_type,
                                                    )}
                                                    <div className="form-group cstmActSlct">
                                                        <CustomSelect
                                                            variant={'standard'}
                                                            //label={'Account Type'}
                                                            value={values.bank_account_holder_type}
                                                            onChange={(e) =>
                                                                setFieldValue(
                                                                    'bank_account_holder_type',
                                                                    e.target.value,
                                                                )
                                                            }
                                                            helperText={
                                                                errors.bank_account_holder_type &&
                                                                touched.bank_account_holder_type
                                                                    ? 'Select Account Type'
                                                                    : ''
                                                            }
                                                        >
                                                            <option value={''}>
                                                                Select Account Type
                                                            </option>

                                                            <option value={'personalcheckings'}>
                                                                {'Personal Checkings'}
                                                            </option>
                                                            <option value={'personalsavings'}>
                                                                {'Personal Savings'}
                                                            </option>
                                                            <option value={'businesschecking'}>
                                                                {'Business Checking'}
                                                            </option>
                                                            <option value={'personalsavings'}>
                                                                {'Business Savings'}
                                                            </option>
                                                        </CustomSelect>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    {/* <div className="row"> */}
                                                    <div className="form-group">
                                                        <CustomInput
                                                            label="Routing Number"
                                                            variant="standard"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            name="bank_routing_number"
                                                            //inputProps={{ maxLength: 2 }}
                                                            helperText={
                                                                errors.bank_routing_number &&
                                                                touched.bank_routing_number
                                                                    ? 'Enter Valid Routing Number'
                                                                    : ''
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <CustomInput
                                                            label="Account Number"
                                                            variant="standard"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            name="bank_account_number"
                                                            //inputProps={{ maxLength: 4 }}
                                                            helperText={
                                                                errors.bank_account_number &&
                                                                touched.bank_account_number
                                                                    ? 'Invalid Account Number'
                                                                    : ''
                                                            }
                                                        />
                                                    </div>
                                                    {/* </div> */}
                                                </div>
                                            </div>
                                        </div>
                                        <PrimaryButton
                                            disabled={loading}
                                            label="Add"
                                            type="submit"
                                        />
                                    </div>
                                </form>
                            )}
                        </Formik>
                    ) : props.bankverifypopup ? (
                        <Formik
                            initialValues={{
                                payAmount1: '',
                                payAmount2: '',
                                site_id: '',
                                user_id: '',
                            }}
                            validationSchema={bankcard}
                            innerRef={formRef}
                            onSubmit={(values) => {
                                if (values.payAmount1.includes('.')) {
                                    var am1 = values.payAmount1.split('.')
                                    values.payAmount1 =
                                        am1.length > 0 ? parseInt(am1[1]) : values.payAmount1
                                }
                                if (values.payAmount2.includes('.')) {
                                    var am2 = values.payAmount2.split('.')
                                    values.payAmount2 =
                                        am2.length > 0 ? parseInt(am2[1]) : values.payAmount2
                                }

                                verifyapi(values)
                            }}
                        >
                            {({
                                errors,
                                touched,
                                values,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                setFieldValue,
                            }) => (
                                <form onSubmit={handleSubmit}>
                                    <div className="AddCard-Popup-Banner">
                                        <div className="Addcard-Popup-Input">
                                            <div className="row">
                                                <p>
                                                    Note:{' '}
                                                    <b style={{ color: 'red' }}>
                                                        *Please check your bank statement for the
                                                        microdeposit amounts and enter them.
                                                        Microdeposits might take 24 hours - 48 hours
                                                        to arrive. EX 0.14, 0.22.
                                                    </b>
                                                </p>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <CustomInput
                                                            label="Amount 1"
                                                            variant="standard"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            name="payAmount1"
                                                            helperText={
                                                                errors.payAmount1 &&
                                                                touched.payAmount1
                                                                    ? 'Please Enter Amount'
                                                                    : ''
                                                            }
                                                            type="text"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <CustomInput
                                                            label="Amount 2"
                                                            variant="standard"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            name="payAmount2"
                                                            helperText={
                                                                errors.payAmount2 &&
                                                                touched.payAmount2
                                                                    ? 'Please Enter Amount'
                                                                    : ''
                                                            }
                                                            type="text"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <PrimaryButton
                                            disabled={loading}
                                            label="Verify"
                                            type="submit"
                                        />
                                    </div>
                                </form>
                            )}
                        </Formik>
                    ) : (
                        ''
                    )}
                </Popup>
            ) : (
                ''
            )}
            {/* <Popup
                modaltitle="Account Verification"
                open={props.bankverifypopup}
                handleClose={() => props.setBankVerifyPopup(false)}
            >
                <Formik
                    initialValues={{
                        payAmount1: '',
                        payAmount2: '',
                        site_id: '',
                        user_id: '',
                    }}
                    validationSchema={bankcard}
                    innerRef={formRef}
                    onSubmit={(values) => {
                        if (values.payAmount1.includes('.')) {
                            var am1 = values.payAmount1.split('.')
                            values.payAmount1 =
                                am1.length > 0 ? parseInt(am1[1]) : values.payAmount1
                        }
                        if (values.payAmount2.includes('.')) {
                            var am2 = values.payAmount2.split('.')
                            values.payAmount2 =
                                am2.length > 0 ? parseInt(am2[1]) : values.payAmount2
                        }

                        verifyapi(values)
                    }}
                >
                    {({
                        errors,
                        touched,
                        values,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        setFieldValue,
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <div className="AddCard-Popup-Banner">
                                <div className="Addcard-Popup-Input">
                                    <div className="row">
                                        <p>
                                            Note:{' '}
                                            <b style={{ color: 'red' }}>
                                                *To verify your account, please enter the two micro
                                                deposits below. If you don't see them, they should
                                                arrive in 1-3 business days.
                                            </b>
                                        </p>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <CustomInput
                                                    label="Amount 1"
                                                    variant="standard"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="payAmount1"
                                                    helperText={
                                                        errors.payAmount1 && touched.payAmount1
                                                            ? 'Please Enter Amount'
                                                            : ''
                                                    }
                                                    type="text"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <CustomInput
                                                    label="Amount 2"
                                                    variant="standard"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="payAmount2"
                                                    helperText={
                                                        errors.payAmount2 && touched.payAmount2
                                                            ? 'Please Enter Amount'
                                                            : ''
                                                    }
                                                    type="text"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <PrimaryButton disabled={loading} label="Verify" type="submit" />
                            </div>
                        </form>
                    )}
                </Formik>
            </Popup> */}
        </div>
    )
}

export default Addnewcard
