import React, { useState, useEffect } from 'react'
import CustomInput from '../../components/atoms/Inputs/CustomInput'
import PrimaryButton from '../../components/atoms/PrimaryButton/index'
import CustomSelect from '../../components/atoms/Inputs/CustomSelect'
import DropDown from '../Signup/DropDown'
import * as yup from 'yup'
import { Formik, useFormik } from 'formik'
import axios from '../../Utils/Utility/axiosconfig'
import { useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'
import { mapData } from '../../common/components'
import Country from './Country.json'
import State from './State.json'
import cities from './cities.json'
import SimpleSelect from '../../components/atoms/DropDown/index'
import SimpleSelect1 from '../../components/atoms/DropDown/Dropdown1'
import SimpleSelect2 from '../../components/atoms/DropDown/Dropdown2'
import IntlTelInput from 'react-intl-tel-input'
import 'react-intl-tel-input/dist/main.css'

import './signup.css'
import { IconButton, InputAdornment } from '@material-ui/core'

const Signup = (props) => {
    const history = useHistory()

    const [showPassword, setShowPassword] = useState(false)

    const [telephoneValid, setTelephoneValid] = useState(true)
    const setValidity = (valid) => {
        console.log('validity changessssssssssss', valid)
        setTelephoneValid(valid)
    }

    // const phoneRegex = RegExp(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/)

    const signupSchema = yup.object({
        firstname: yup
            .string()
            .required('Enter your First Name...!')
            .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field'),
        lastname: yup
            .string()
            .required('Enter your Last Name...!')
            .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field'),
        email: yup.string().trim().email('Invalid email format!').required('Enter your Email...!'),
        phone: yup.string().required('Phone No is required'),
        password: yup.string().min(8, 'Minimum 8 characters').required('Password is required'),
        confirmpassword: yup
            .string()
            .min(8, 'Minimum 8 characters')
            .oneOf([yup.ref('password'), null], 'Password must match')
            .required('Confirm password is required'),
        zipcode: yup
            .string()
            .min(5, 'Invalid Zip Code')
            .max(6, 'Invalid Zip Code')
            .required('Zip Code Required'),
        addressline1: yup.string().required('Enter your Address1 ...!'),
        addressline2: yup.string(),
        city: yup.string(),
        state: yup.string().required('State is required'),
        country: yup.string().required('Country is required'),
    })

    useEffect(() => {
        console.log(Country)
    }, [])

    const registration = async (data) => {
        try {
            const {
                firstname,
                lastname,
                email,
                phone,
                password,
                country,
                state,
                city,
                zipcode,
                addressline1,
                addressline2,
            } = data

            const formData = {
                first_name: firstname,
                last_name: lastname,
                email: email,
                phone: phone,
                password: password,
                address1: addressline1,
                address2: addressline2,
                country,
                state,
                city,
                zip: zipcode,
                role: 'buyer',
                status: '',
                redirectURL: `${window.location.origin}/verifyUser`,
            }

            // const res = await axios.post('mobileapi/checkEmailExistance', { email: formData.email })
            formData.community = 'auction_io'
            formData.is_auctionio = 1
            const res = await axios.post('mobileapi/register', formData)
            if (res.data.success === 'no') {
                return Swal.fire({
                    icon: 'error',
                    title: res.data.message || res.data.failedreason,
                    timer: 1500,
                    showConfirmButton: false,
                    position: 'center',
                })
            }
            await history.push('/login')
            return Swal.fire({
                icon: 'success',
                title: 'Thanks for Registration',
                timer: 1500,
                showConfirmButton: false,
                position: 'center',
            })

            // history.push({
            //     pathname: '/login',
            //     // state: { personal: formData, card: null },
            // })
        } catch (error) {
            console.log(error)
        }
    }

    const [Id, setID] = useState('231')
    const [sId, setStateId] = useState('')
    const [Countrycode, setCountrycode] = useState('1')

    useEffect(() => {
        console.log('Countrycode', Countrycode)
        console.log('Id', Id)
        console.log('sId', sId)
    })

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword)
    }

    const processNumber = (isValid, phone, country) => {
        console.log('isvalidd state', isValid)
        return `+${country.dialCode} ${phone}`
        // if (isValid === true) {
        // } else {
        //     return (Formik.phone.error = 'Phone number no valid')
        // }
    }

    return (
        <div>
            <Formik
                initialValues={{
                    firstname: '',
                    lastname: '',
                    email: '',
                    phone: '',
                    password: '',
                    confirmpassword: '',
                    zipcode: '',
                    addressline1: '',
                    addressline2: '',
                    country: 'United States',
                    state: '',
                    city: '',
                }}
                validationSchema={signupSchema}
                onSubmit={(values) => {
                    console.log(values, '======>>>>>>^^^^^<<<<<')
                    registration(values)
                }}
            >
                {({
                    errors,
                    touched,
                    values,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldTouched,
                    setFieldValue,
                }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="Signup">
                            {console.log('formik.valuesss->', values)}
                            <div className="coinImg">
                                <img src="/images/loginback.jpg" />
                            </div>
                            <div className="Registration-Banner custom-container-signup">
                                <div className="logo-signup">
                                    <a href="/">
                                        <img
                                            className="cmlftogo"
                                            src="/images/coin-auction-logo.png"
                                            alt="logo"
                                        />
                                    </a>
                                </div>
                                <div className="signup-Text">
                                    <h1>Sign Up</h1>
                                </div>
                                <div className="signup-inputfields row">
                                    <div className="registration-inp col-6">
                                        <CustomInput
                                            variant="standard"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            label="First Name"
                                            name="firstname"
                                            value={values.firstname}
                                        />
                                        {errors.firstname && touched.firstname ? (
                                            <div className="errorMessage-1 errorTxt">
                                                {errors.firstname}
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className="registration-inp col-6">
                                        <CustomInput
                                            variant="standard"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            label="Last Name"
                                            name="lastname"
                                            value={values.lastname}
                                        />
                                        {errors.lastname && touched.lastname ? (
                                            <div className="errorMessage-2 errorTxt">
                                                {errors.lastname}
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className="registration-inp col-6">
                                        <CustomInput
                                            variant="standard"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            label="Email"
                                            name="email"
                                            value={values.email?.trim()}
                                        />
                                        {errors.email && touched.email ? (
                                            <div className="errorMessage-1 errorTxt">
                                                {errors.email}
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className="registration-inp intlIptWrpr col-6">
                                        <label>Phone No.</label>
                                        <IntlTelInput
                                            {...props}
                                            containerClassName="intl-tel-input"
                                            inputClassName={telephoneValid ? 'valid' : 'invalid'}
                                            label="Phone No."
                                            defaultValue={values.phone}
                                            fieldName={'phone'}
                                            onPhoneNumberBlur={(isValid) => {
                                                setFieldTouched('phone', true)
                                                setValidity(isValid)
                                            }}
                                            onPhoneNumberChange={(isValid, phone, country) => {
                                                setFieldValue(
                                                    'phone',
                                                    processNumber(isValid, phone, country),
                                                )
                                            }}
                                            separateDialCode={true}
                                        />
                                        {/* <IntlTelInput
                                            preferredCountries={['us']}
                                            onPhoneNumberChange={handleChange}
                                            onPhoneNumberBlur={handleBlur}

                                            // value={}
                                        /> */}
                                        {/* <CustomInput
                                            variant="standard"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            label="Phone No."
                                            name="phone"
                                            value={values.phone}
                                        /> */}
                                        {!telephoneValid && (
                                            <p className="text-danger mt-2 mb-0">
                                                Phonenumber invalid
                                            </p>
                                        )}
                                        {telephoneValid && errors.phone && touched.phone ? (
                                            <div className="errorMessage-2 errorTxt">
                                                {errors.phone}
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className="registration-inp col-6">
                                        <CustomInput
                                            variant="standard"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            label="Password"
                                            name="password"
                                            value={values.password}
                                            inputProps={{ maxLength: 12 }}
                                            type={showPassword ? 'text' : 'password'}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                    >
                                                        {values.showPassword ? (
                                                            <span className="material-icons">
                                                                visibility_off
                                                            </span>
                                                        ) : (
                                                            <span className="material-icons">
                                                                visibility
                                                            </span>
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                        {errors.password && touched.password ? (
                                            <div className="errorMessage-1 errorTxt">
                                                {errors.password}
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className="registration-inp col-6">
                                        <CustomInput
                                            variant="standard"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            label="Confirm Password"
                                            name="confirmpassword"
                                            value={values.confirmpassword}
                                            inputProps={{ maxLength: 12 }}
                                            type="password"
                                        />
                                        {errors.confirmpassword && touched.confirmpassword ? (
                                            <div className="errorMessage-2 errorTxt">
                                                {errors.confirmpassword}
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className="registration-inp col-6">
                                        <SimpleSelect
                                            dropCountr={Country}
                                            onChange={handleChange}
                                            countryID={(data) => setID(data)}
                                            ctrcode={(data) => setCountrycode(data)}
                                            value={values.country}
                                            name="country"
                                            label="Country"
                                            autoComplete="nope"
                                        />

                                        {/* <DropDown
                                            variant="standard"
                                            label="Country"
                                            name="country"
                                            value={values.country}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            dropCountr={Country}
                                            countryID={(data) => setID(data)}
                                            ctrcode={(data) => setCountrycode(data)}
                                        /> */}
                                        {errors.country && touched.country ? (
                                            <div className="errorMessage-2 errorTxt">
                                                {errors.country}
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className="registration-inp col-6">
                                        <CustomInput
                                            variant="standard"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            name="state"
                                            label="State"
                                            value={values.state}
                                        />
                                        {/* <SimpleSelect1
                                            value={values.state}
                                            onChange={handleChange}
                                            countryID={(data) => setID(data)}
                                            ctrcode={(data) => setCountrycode(data)}
                                            name="state"
                                            name="state"
                                            dropState={State.filter(
                                                (data) => data.country_id === Id.toString(),
                                            )}
                                            stateID={(data) => setStateId(data)}
                                            autoComplete="nope"
                                        /> */}
                                        {/* <DropDown
                                            variant="standard"
                                            label="State"
                                            name="state"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.state}
                                            dropState={State.filter(
                                                (data) => data.country_id === Id.toString(),
                                            )}
                                            stateID={(data) => setStateId(data)}
                                        /> */}
                                        {errors.state && touched.state ? (
                                            <div className="errorMessage-2 errorTxt">
                                                {errors.state}
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className="registration-inp col-6">
                                        {/* <DropDown
                                            variant="standard"
                                            label="City"
                                            name="city"
                                            value={values.city}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            dropCity={cities.filter(
                                                (data) => data.state_id === sId.toString(),
                                            )}
                                        /> */}
                                        <CustomInput
                                            variant="standard"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            name="city"
                                            label="City"
                                            value={values.city}
                                        />
                                        {/* <SimpleSelect2
                                            value={values.city}
                                            onChange={handleChange}
                                            name="city"
                                            label="City"
                                            dropCity={cities.filter(
                                                (data) => data.state_id === sId.toString(),
                                            )}
                                            autoComplete="nope"
                                        /> */}
                                        {errors.city && touched.city ? (
                                            <div className="errorMessage-2 errorTxt">
                                                {errors.city}
                                            </div>
                                        ) : null}
                                    </div>

                                    <div className="registration-inp col-6">
                                        <CustomInput
                                            variant="standard"
                                            label="Zip Code"
                                            name="zipcode"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.zipcode}
                                            inputProps={{ maxLength: 6 }}
                                        />
                                        {errors.zipcode && touched.zipcode ? (
                                            <div className="errorMessage-2 errorTxt">
                                                {errors.zipcode}
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className="registration-inp col-6">
                                        <CustomInput
                                            variant="standard"
                                            label="Address Line 1"
                                            name="addressline1"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.addressline1}
                                        />
                                        {errors.addressline1 && touched.addressline1 ? (
                                            <div className="errorMessage-1 errorTxt">
                                                {errors.addressline1}
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className="registration-inp col-6">
                                        <CustomInput
                                            variant="standard"
                                            label="Address Line 2"
                                            name="addressline2"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.addressline2}
                                        />
                                        {errors.addressline2 && touched.addressline2 ? (
                                            <div className="errorTxt">{errors.addressline2}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="signup-btn">
                                    <PrimaryButton label="Submit" type="submit" />
                                </div>
                                <div className="signin-Instead">
                                    <a href="/login">Sign in Instead</a>
                                </div>
                            </div>
                        </div>
                    </form>
                )}
            </Formik>
        </div>
    )
}

export default Signup
