import React, { useContext, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { Badge } from '@material-ui/core'
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined'
import { ShoppingCart } from '@material-ui/icons'
import { connect, useDispatch } from 'react-redux'
import cartDetails from '../../../actions/cartAuction'
const CartIcon = (props) => {
    const [cart_count, setCartCount] = useState(0)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(cartDetails({ auction_io: 1, city_sales_tax_enable: 0 }))
    }, [])

    useEffect(() => {
        setCartCount(props.cart_count)
    }, [props])

    return (
        <>
            <NavLink activeClassName="active" to="/cart">
                <Badge badgeContent={cart_count} color="primary">
                    {props.fillIcon ? (
                        <span className="material-icons">shopping_cart</span>
                    ) : (
                        <span className="material-icons-outlined">shopping_cart</span>
                    )}
                </Badge>
            </NavLink>
        </>
    )
}

const mapStateToProps = (state) => ({
    cart_count: state.cartDetails.cart_count,
})

export default connect(mapStateToProps)(CartIcon)
