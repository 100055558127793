import React, { useState } from 'react'
import CustomSelect from '../../components/atoms/Inputs/CustomSelect'

const DropDown = (props) => {
    return (
        <div className="customSelect">
            <CustomSelect
                variant="standard"
                label={props.label}
                InputLabelProps={{
                    shrink: false,
                }}
                customsel={true}
                id={props.id}
                value={props.value}
                autoFocus={props.autoFocus}
                name={props.name}
                onChange={props.onChange || props.onChange}
                placeholder={props.placeholder}
                onBlur={props.onBlur || props.onBlur}
                type={props.type}
                error={props.error}
                helperText={props.helperText}
            >
                {props.dropCountr
                    ? props.dropCountr.map((opt, optindex) => (
                          <option
                              key={optindex}
                              value={opt.name}
                              onClick={() => {
                                  props.countryID(opt.id)
                                  props.ctrcode(opt.phoneCode)
                              }}
                          >
                              {opt.name}
                          </option>
                      ))
                    : ''}
                {props.dropState
                    ? props.dropState.map((opt, optindex) => (
                          <option
                              key={optindex}
                              value={opt.name}
                              onClick={() => {
                                  props.stateID(opt.id)
                              }}
                          >
                              {opt.name}
                          </option>
                      ))
                    : ''}
                {props.dropCity
                    ? props.dropCity.map((opt, optindex) => (
                          <option key={optindex} value={opt.name}>
                              {opt.name}
                          </option>
                      ))
                    : ''}
            </CustomSelect>
        </div>
    )
}

export default DropDown
