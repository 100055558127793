import React, { useState, useEffect, useRef } from 'react'
import CheckoutCard from '../../components/molecules/CheckoutCard'
import CheckoutPayment from '../../components/molecules/CheckoutBuynowPayment'
import { connect, useDispatch, useSelector } from 'react-redux'
import { Userdetails } from '../../actions/useraction'
import './Checkout.css'
import _, { set } from 'lodash'
import axios from 'axios'
import Loader from '../../components/molecules/Loader'
import { useHistory } from 'react-router-dom'
import cartDetails from '../../actions/cartAuction'
import { socket } from '../../common/socket'
import Swal from 'sweetalert2'
function Checkout(props) {
    // console.log('state data ===========', props.location.state)
    const [viewCartItems, setViewCartItems] = useState([])
    const [card_id, setCartId] = useState([])
    const [ship_local, setShipLocal] = useState([])
    const [loading, setLoading] = useState(false)
    const [achtransaction, setAchtransaction] = useState(0)
    const [transactionfeeenable, setTransactionfeeEnable] = useState(0)
    const state_header = useSelector((state) => state.storeDetails)
    const history = useHistory()
    const id = new URLSearchParams(window.location.search.substring(1)).getAll('id')
    // const getCheckoutAuction = async (body) => {
    //     const token = localStorage.getItem('userToken')
    //     const res = await axios.post(
    //         `${process.env.REACT_APP_DOMAIN}mobileapi/getCheckoutAuction`,
    //         body,
    //         {
    //             headers: {
    //                 Authorization: 'Bearer ' + token,
    //                 domain: process.env.REACT_CONTROL_HUB_DOMAIN,
    //             },
    //         },
    //     )
    //     if (res.data.success) {
    //         setLoading(false)
    //         res.data.details.map((item) => {
    //             if (parseInt(item.hasshipping) == 0) {
    //                 item.total_payable_amount =
    //                     parseFloat(item.total_payable_amount) - parseFloat(item.shipping_fee)
    //                 item.shipping_fee = 0
    //             }
    //             ship_local.push({ id: item.buynow_id, hasshipping: item.hasshipping })
    //         })
    //         setViewCartItems(res.data.details)
    //         setShipLocal(ship_local)
    //     }
    // }

    //useEffect(() => {
    // getCheckoutAuction({ invoice_id: id })

    //     setLoading(true)
    // }, [])
    useEffect(() => {
        setLoading(true)
        if (props.cart_details?.length > 0) {
            setViewCartItems(props.cart_details)
        } else {
            setViewCartItems([])
        }
        setLoading(false)
    }, [props.cart_details])

    useEffect(() => {
        if (state_header?.storeDetails?.store_configuration?.length > 0) {
            state_header.storeDetails.store_configuration.map((data) => {
                if (data.variable == 'ach_transaction_fee') {
                    setAchtransaction(parseFloat(data.value))
                }
                if (data.variable == 'transaction_fee_changes') {
                    setTransactionfeeEnable(parseInt(data.value))
                }
            })
        }
    }, [state_header])

    const changetrasaction_fee_reload = () => {
        var viewCartItems_data = viewCartItems
        viewCartItems_data.map((data) => {
            data.total_payable_amount =
                parseFloat(data.total_payable_amount) - parseFloat(data.transaction_fee)
            data.transaction_fee = parseFloat(
                parseFloat(
                    parseFloat(
                        (parseFloat(
                            transactionfeeenable ? data.total_payable_amount : data.amount,
                        ) *
                            parseFloat(achtransaction)) /
                            100,
                    ),
                ).toFixed(2),
            )
            data.transaction_label = `${achtransaction + '%'}`
            data.total_payable_amount =
                parseFloat(data.total_payable_amount) + parseFloat(data.transaction_fee)
        })
        setViewCartItems([...viewCartItems_data])
    }
    const changetrasaction_fee_reload_card = () => {
        dispatch(cartDetails({}))
    }

    useEffect(() => {
        if (viewCartItems.length > 0) {
            var c_id = [],
                ship_local = []
            viewCartItems.map((data) => {
                c_id.push(data.cart_id)
                ship_local.push({ id: data.cart_id, hasshipping: data.hasshipping })
            })
            setCartId(c_id)
            setShipLocal(ship_local)
        }
    }, [viewCartItems])

    const viewProductRef = useRef(viewCartItems)
    const userRef = useRef(props.userDetails.userdetails)
    const dispatch = useDispatch()
    useEffect(() => {
        viewProductRef.current = viewCartItems
        userRef.current = props.userDetails.userdetails
    })

    useEffect(() => {
        dispatch(cartDetails({}))
    }, [])

    useEffect(() => {
        socket.on('realclosedupdates', (data) => {
            if (userRef.current) {
                // console.log('user inside', user)
                let user_id = userRef.current.id
                let product = viewProductRef.current
                var index = product.findIndex((s) => s.product_id === parseInt(data.pid, 10))

                // console.log('data', data)
                if (index !== -1) {
                    if (parseInt(user_id) != parseInt(data.usr)) {
                        Swal.fire({
                            title:
                                'Sorry,Some One Has Bought Your Item Or Item End time Reached ' +
                                product[index].title +
                                '.So It Removed From Your Cart.',
                            icon: 'error',
                            showConfirmButton: true,
                            position: 'center',
                        })
                        dispatch(cartDetails({}))
                    }
                }
            }
        })

        return () => {
            socket.off('realclosedupdates', (data) => {
                if (userRef.current) {
                    // console.log('user inside', user)
                    let user_id = userRef.current.id
                    let product = viewProductRef.current
                    var index = product.findIndex((s) => s.product_id === parseInt(data.pid, 10))

                    // console.log('data', data)
                    if (index !== -1) {
                        if (parseInt(user_id) != parseInt(data.usr)) {
                            Swal.fire({
                                title:
                                    'Sorry,Some One Has Bought Your Item Or Item End time Reached ' +
                                    product[index].title +
                                    '.So It Removed From Your Cart.',
                                icon: 'error',
                                showConfirmButton: true,
                                position: 'center',
                            })
                            dispatch(cartDetails({}))
                        }
                    }
                }
            })
        }
    }, [])

    return (
        <div className="checkoutContainer d-flex justify-content-between customContainer">
            {loading && <Loader />}
            {viewCartItems.length !== 0 ? (
                <>
                    <div className="checkoutPdt">
                        <p className="checkoutTitle">Checkout</p>
                        {viewCartItems.map((data, index) => (
                            <CheckoutCard data={data} />
                        ))}
                    </div>
                    <div className="paymentContainer ml-3">
                        <p className="paymentTitle">Payment Details</p>

                        <CheckoutPayment
                            // totalPrice={data.pdtPrice}
                            // name={data.pdtName}
                            // inv={data.inv}
                            // additional_amount_1={data.additional_amount_1}
                            // additional_amount_2={data.additional_amount_2}
                            // additional_amount_3={data.additional_amount_3}
                            // profile={props.userDetails.userdetails}
                            // conFee={data.Fee}
                            // taxPrice={data.Tax}
                            // grandTotal={data.Total}
                            amount={_.sumBy(viewCartItems, (x) => parseFloat(x.amount))}
                            invoice_id={card_id}
                            buyers_premium={_.sumBy(viewCartItems, (x) =>
                                parseFloat(x.buyers_premium + x.charge_fee),
                            )}
                            transaction_fee={_.sumBy(viewCartItems, (x) =>
                                parseFloat(x.transaction_fee),
                            )}
                            ship_local={ship_local}
                            shipping_fee={_.sumBy(viewCartItems, (x) => parseFloat(x.shipping_fee))}
                            sales_tax={_.sumBy(viewCartItems, (x) => parseFloat(x.sales_tax))}
                            total_payable_amount={_.sumBy(viewCartItems, (x) =>
                                parseFloat(x.total_payable_amount),
                            )}
                            changetrasaction_fee={changetrasaction_fee_reload}
                            changetrasaction_fee_card={changetrasaction_fee_reload_card}
                            trasaction_per_fee={achtransaction}
                            project_id_parent={viewCartItems.map((data) => {
                                return data.project_id
                            })}
                        />
                    </div>
                </>
            ) : (
                <p className="text-center w-100">No Data Found</p>
            )}
        </div>
    )
}
const mapDispatchToProps = (dispatch) => ({
    User: () => dispatch(Userdetails()),
})

const mapStateToProps = (state) => {
    return {
        userDetails: state.User ? state.User : {},
        cart_details: state.cartDetails.cart_details,
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Checkout)
