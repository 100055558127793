import React from 'react'
import PrimaryButton from '../../atoms/PrimaryButton'
import Timer from '../Timer'
import './ProductCard.css'
import { onImageError } from '../../../Utils/Utility'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'

function ListView(props) {
    const history = useHistory()
    return (
        <div className="listViewCnt" key={props.id}>
            <div className="listCard d-flex">
                <LazyLoadImage
                    className="cardImage"
                    src={props.imgSrc}
                    onError={onImageError}
                    effect="blur"
                    placeholderSrc="/images/imageLoading.svg"
                    height="100%"
                    width="100%"
                    alt="Next Lot"
                />
                <div className="listPdtCnt">
                    {props.category != '' && <p className="pdtCategory">{props.category}</p>}
                    <p className="pdtListName">{props.Name}</p>

                    <p className="pdtStart">
                        {moment(props.Start).tz('America/Los_Angeles').format('MM/DD/YYYY hh:mm A')}{' '}
                        PST
                    </p>
                    {/* <p className="pdtEnd">{moment(props.End).format('MM/DD/YYYY HH:mm A')}</p> */}
                    <p
                        className="listPdtDescrp"
                        dangerouslySetInnerHTML={{ __html: props.descrp }}
                    ></p>
                </div>
                <div className="timeBtn d-flex flex-column">
                    {props.startTime && <p className="timeTitle">Starts In</p>}
                    {props.endTime && <p className="timeTitle">Ends In</p>}
                    <Timer
                        className="Listtime"
                        productTime={{ start: props.Start, end: props.End }}
                        timedauction={props.con_check ? false : true}
                    />
                    <PrimaryButton
                        className="viewAuctionBtn"
                        onClick={() =>
                            history.push(
                                props.con_check
                                    ? `/auction/${props.id}?page=1`
                                    : `/auction/timed-auction/${props.id}?page=1`,
                            )
                        }
                    >
                        {props.label}
                    </PrimaryButton>
                </div>
            </div>
        </div>
    )
}

export default ListView
