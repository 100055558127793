import React from 'react'
import PrimaryButton from '../../atoms/PrimaryButton'
import Timer from '../Timer'
import './ProductCard.css'
import { onImageError, setDecimalPoint } from '../../../Utils/Utility'
import { useHistory } from 'react-router-dom'
import { currencyFormat } from '../../../common/components'
import { Chip } from '@material-ui/core'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import moment from 'moment-timezone'

function HomeAuctionCard(props) {
    const history = useHistory()

    return (
        <div className="homeGridCard" key={props.id}>
            {/* {props.card_type === 'lotView' && (
                <div className="favBox">
                    <span
                        name={props.prodid}
                        onClick={props.onFavClick}
                        className="material-icons Favicon"
                    >
                        {props.isFav === 1 ? 'favorite' : 'favorite_border'}
                    </span>
                </div>
            ,)} */}
            <div className="crdImage">
                <LazyLoadImage
                    className="cardImage"
                    src={props.imgSrc}
                    onError={onImageError}
                    onClick={props.onClick ? props.onClick : null}
                    effect="blur"
                    placeholderSrc="/images/imageLoading.svg"
                    height="100%"
                    width="100%"
                    alt="Next Lot"
                />
                {/* <span className={`badge grid-badge ${props.con_check ? 'live' : 'timed'}`}>
                    {props.con_check ? 'Live' : 'Timed'}
                </span> */}
            </div>
            <div className="auctionInfoWrpr d-flex align-items-stretch">
                <div className="pdtContent">
                    <p
                        className="pdtName mb-2"
                        onClick={props.onClick ? props.onClick : null}
                        dangerouslySetInnerHTML={{
                            __html: props.Name,
                        }}
                    ></p>
                    <div className="strtTmDiv d-flex align-items-center justify-content-start">
                        <img src="/images/calendar_icon.svg" className="mr-2" />
                        <p className="tmrVle">
                            {moment(props.startTime)
                                .tz('America/Los_Angeles')
                                .format('MM/DD/YYYY hh:mm A')}{' '}
                            PST
                        </p>
                    </div>
                    <p className="pdtDescrp" dangerouslySetInnerHTML={{ __html: props.descrp }}></p>
                </div>
                <div className="tmrActnCntnr">
                    {props.closedAuction ? (
                        <>
                            <PrimaryButton
                                cstmClass="w-100"
                                className="viewAuctionBtn"
                                onClick={() =>
                                    history.push(
                                        encodeURI(
                                            props.con_check
                                                ? `/auction/${props.id}?page=1`
                                                : `/auction/timed-auction/${props.id}?page=1`,
                                        ),
                                    )
                                }
                            >
                                {props.label}
                            </PrimaryButton>
                        </>
                    ) : (
                        <>
                            <span class="material-icons-outlined">timer</span>
                            {new Date() < new Date(props.startTime) ? (
                                <Timer
                                    productTime={{ start: props.startTime, end: props.endTime }}
                                    timerBasic={props.timerBasic}
                                    timedauction={props.con_check ? false : true}
                                />
                            ) : (
                                <>
                                    {props.con_check == 1 ? (
                                        <p className="red-text mb-0 mt-2">Live Auction Started</p>
                                    ) : (
                                        <Timer
                                            productTime={{
                                                start: props.startTime,
                                                end: props.endTime,
                                            }}
                                            timerBasic={props.timerBasic}
                                            timedauction={props.con_check ? false : true}
                                        />
                                    )}
                                </>
                            )}

                            <PrimaryButton
                                cstmClass="w-100"
                                className="viewAuctionBtn"
                                onClick={() =>
                                    history.push(
                                        encodeURI(
                                            props.con_check
                                                ? `/auction/${props.id}?page=1`
                                                : `/auction/timed-auction/${props.id}?page=1`,
                                        ),
                                    )
                                }
                            >
                                {props.label}
                            </PrimaryButton>
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

export default HomeAuctionCard
