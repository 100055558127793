import React, { useState, useEffect } from 'react'
import moment from 'moment'
import ImageGallery from 'react-image-gallery'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import CustomInput from '../../atoms/Inputs/CustomInput'
import PrimaryButton from '../../atoms/PrimaryButton'
import Timer from '../../molecules/Timer'
import '../../../views/ProductView/ProductView.css'
import 'react-image-gallery/styles/css/image-gallery.css'
import './ProductViewSlider.css'
import { setDecimalPoint } from '../../../Utils/Utility'
import isAuthenticate from '../../../Utils/Utility/auth'
import { Chip } from '@material-ui/core'
import { currencyFormat } from '../../../common/components'
import axios from '../../../Utils/Utility/axiosconfig'
import cartDetails from '../../../actions/cartAuction'
import { connect, useDispatch } from 'react-redux'
import Swal from 'sweetalert2'
import publicIp from 'public-ip'
import updateFav from '../../../Utils/API/fav'
import { useHistory } from 'react-router-dom'
import ReadMore from '../../atoms/ReadMore'
const useStyles = makeStyles({
    list: {
        width: 785,
        padding: '60px 70px 10px 60px',
    },
    fullList: {
        width: 'auto',
    },
})

const ProductViewSlider = ({
    isOpen,
    onCloseClick,
    data,
    amount,
    handleOnChange,
    onKeyPress,
    handleClick,
    handleHistory,
    lotData,
    currentProject,
    ...props
}) => {
    const classes = useStyles()
    const [currentLotImages, setCurrentLotImages] = React.useState([])
    const [lotData_new, setLotData] = useState({})
    const [CartAdded, setCartAdd] = useState(true)
    const [Loading, setLoading] = useState(true)
    const [Loadingcart, setLoadingcart] = useState(false)
    const history = useHistory()
    const dispatch = useDispatch()
    const getLotAuction = async (body) => {
        let { data } = await axios.post('mobileapi/getprodetails', body)
        if (data && data.success === 'yes') {
            setLotData(data.results)
            insertLotCount(data?.results?.id)
            let lotimages = []
            if (parseInt(data.attachments?.length) > 0) {
                data.attachments.map((imgObj) =>
                    lotimages.push({
                        original: process.env.REACT_APP_PRODUCT_IMG + imgObj.src,
                        thumbnail: process.env.REACT_APP_PRODUCT_IMG + imgObj.src,
                    }),
                )
            } else {
                lotimages.push({
                    original: '/images/pdtimg.png',
                    thumbnail: '/images/pdtimg.png',
                })
            }
            setCurrentLotImages(lotimages)
        }
        setLoading(false)
    }

    React.useEffect(() => {
        if (isOpen && Object.keys(data).length > 0) {
            // data.lotImages.map((imgObj) =>
            //     lotimages.push({
            //         original: process.env.REACT_APP_PRODUCT_IMG + imgObj.file_name,
            //         thumbnail: process.env.REACT_APP_PRODUCT_IMG + imgObj.file_name,
            //     }),
            // )
            getLotAuction({ product_id: data.id, auction_io: 1 })
        }
        //setCurrentLotImages(lotimages)
    }, [isOpen])

    const insertLotCount = async (id) => {
        const ip = await publicIp.v4()
        let body = { project_id: id, user_id: ip }
        let { data } = await axios.post('mobileapi/insertProductViewed', body)
    }

    const AddtoCart = async (body) => {
        let { data } = await axios.get('mobileapi/addtocart?id=' + body.id + '&qty=' + body.qty)
        if (data.status == 'yes') {
            setLoadingcart(false)
            Swal.fire({
                icon: 'success',
                title: 'Item Added To Cart Successfully',
                showConfirmButton: true,
                position: 'center',
            })
            dispatch(cartDetails({ auction_io: 1, city_sales_tax_enable: 0 }))
        } else {
            setLoadingcart(false)
            Swal.fire({
                icon: 'error',
                title: 'Something Went Wrong!',
                showConfirmButton: true,
                position: 'center',
            })
        }
    }

    const RemovetoCart = async (body) => {
        let { data } = await axios.get('mobileapi/removefromcart?id=' + body.id, {})
        if (data.success) {
            setLoadingcart(false)
            Swal.fire({
                icon: 'success',
                title: 'Item Removed From Cart Successfully',
                showConfirmButton: true,
                position: 'center',
            })
            dispatch(cartDetails({ auction_io: 1, city_sales_tax_enable: 0 }))
        } else {
            setLoadingcart(false)
            Swal.fire({
                icon: 'error',
                title: 'Something Went Wrong!',
                showConfirmButton: true,
                position: 'center',
            })
        }
    }

    useEffect(() => {
        if (props?.cart_details?.length) {
            if (lotData_new.id) {
                let position = props.cart_details.filter(
                    (data) => parseInt(data.project_id) == parseInt(lotData_new.id),
                )
                position.length > 0 ? setCartAdd(false) : setCartAdd(true)
            }
        } else {
            setCartAdd(true)
        }
    }, [props.cart_details, lotData_new])

    const addFav = async (id, isFav) => {
        if (isAuthenticate()) {
            let data = await updateFav({ id: id, userid: props.userDetails.userdetails.id }, isFav)
            if (data) {
                lotData_new.wlistpop = !lotData_new.wlistpop ? 1 : 0
                setLotData({ ...lotData_new })
                props.addFav(id, isFav, 1)
            }
        } else {
            history.push({ pathname: '/login' })
        }
    }

    return (
        <React.Fragment key={'right'}>
            <Drawer
                className="imgDrawer"
                anchor={'right'}
                open={isOpen}
                onClose={(event) => {
                    if (
                        event.type === 'keydown' &&
                        (event.key === 'Tab' || event.key === 'Shift')
                    ) {
                        return
                    }
                    onCloseClick(event)
                }}
            >
                {!Loading ? (
                    <div
                        className={clsx(classes.list, {
                            [classes.fullList]: false,
                        })}
                        role="presentation"
                    >
                        {/* {isAuthenticated ? (
            <div
              className={`biddingMessages ${
                state.bid_details.bidtopstatus
                  ? state.bid_details.bidtopstatus.includes("lost") ||
                    state.bid_details.bidtopstatus.includes("losing")
                    ? "lost"
                    : state.bid_details.bidtopstatus.includes("winning")
                    ? "winning"
                    : "won"
                  : null
              } d-flex justify-content-center align-items-center`}
            >
              <h3>
                {state.bid_details.bidtopstatus
                  ? state.bid_details.bidtopstatus.includes("lost") ||
                    state.bid_details.bidtopstatus.includes("losing")
                    ? Sentencecase(
                        state.bid_details.bidtopstatus
                          .split("autoBid")
                          .join("bid")
                      )
                    : state.bid_details.bidtopstatus.includes("winning") ||
                      state.bid_details.bidtopstatus.includes("won")
                    ? Sentencecase(
                        state.bid_details.bidtopstatus
                          .toString()
                          .split("$")
                          .join("€ ")
                      )
                    : ""
                  : ""}
              </h3>
            </div>
          ) : (
            ""
          )} */}
                        <span onClick={onCloseClick} className="material-icons closeBtn">
                            clear
                        </span>
                        <ImageGallery
                            items={
                                currentLotImages
                                // data &&
                                // Array.isArray(data.productImages) &&
                                // data.productImages.length !== 0
                                //     ? data.productImages
                                //     : [{ original: '', thumbnail: '' }]
                            }
                            thumbnailPosition="bottom"
                            showNav={false}
                            lazyLoad={true}
                            showBullets={false}
                            showFullscreenButton={true}
                            onErrorImageURL="/images/pdtimg.png"
                            showPlayButton={false}
                        />
                        <div className="titleFav d-flex justify-content-between align-items-start">
                            <div>
                                {lotData_new.sku ? (
                                    <p className="lotId text-left">Lot Id: {lotData_new.sku}</p>
                                ) : (
                                    ''
                                )}
                                <p className="lotViewTitle mb-2">{lotData_new.title}</p>
                                {lotData_new.lowest != 0 && lotData_new.highest != 0 ? (
                                    <Chip
                                        label={
                                            <span>
                                                EST: {currencyFormat(lotData_new.lowest)} -{' '}
                                                {currencyFormat(lotData_new.highest)}
                                            </span>
                                        }
                                    />
                                ) : (
                                    ''
                                )}
                            </div>

                            <span
                                name={lotData_new.id}
                                onClick={() => addFav(lotData_new.id, lotData_new.wlistpop)}
                                className="material-icons Favicon"
                            >
                                {lotData_new.wlistpop === 1 ? 'favorite' : 'favorite_border'}
                            </span>
                        </div>
                        <hr />
                        <div className="d-flex justify-content-between align-items-center">
                            <p className="lotDecs">
                                Buynow Price : {currencyFormat(lotData_new.bprice)}
                            </p>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                            <p className="lotDecs">Description :</p>
                        </div>
                        <p
                            className="lotDecsCnt mt-2 mb-2 pb-0 cursorDecoy"
                            // dangerouslySetInnerHTML={{ __html: lotData_new.description }}
                        >
                            <ReadMore
                                data={lotData_new.description}
                                className="description"
                                limit={200}
                                label="Show"
                                showOriginalOnExpand={true}
                            />
                        </p>

                        <hr className="mb-4" />
                        <div className="row">
                            <div className="col-12">
                                <PrimaryButton
                                    label={CartAdded ? 'Add To Cart' : 'Remove From Cart'}
                                    onClick={(e) => {
                                        if (CartAdded) {
                                            AddtoCart({
                                                id: lotData_new.id,
                                                qty: lotData_new.qty,
                                            })
                                        } else {
                                            RemovetoCart({ id: lotData_new.id })
                                        }
                                        setLoadingcart(true)
                                    }}
                                    disabled={Loadingcart}
                                />
                            </div>
                        </div>
                        <div className="bidDate d-flex justify-content-between">
                            {/* <div className="bidWrp d-flex">
                            <p className="totlaBidsTitle">Total Bids :</p>
                            <a className="bidNo" onClick={(e) => handleHistory(lotData_new.id)}>
                                {lotData_new.bid_count}
                            </a>
                        </div> */}
                            <div className="dateWrp d-flex">
                                {/* <p className="DateTime">Closing Date :</p>
                            <p className="DateTime">
                                {moment(lotData_new.date_closed).format('MM/DD/YYYY HH:mm A')}{' '}
                            </p> */}
                            </div>
                        </div>
                    </div>
                ) : (
                    ''
                )}
            </Drawer>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => ({
    cart_details: state.cartDetails.cart_details,
    userDetails: state.User ? state.User : {},
})

export default connect(mapStateToProps)(ProductViewSlider)
