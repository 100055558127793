import React, { useState, useEffect, useContext, useRef, useCallback } from 'react'
import './LotView.css'
import axios from 'axios'
import Timer from '../../components/molecules/Timer'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { Chip, LinearProgress, Button } from '@material-ui/core'
import Swal from 'sweetalert2'
import { capitalize, currencyFormat, handleRedirectInternal } from '../../common/components'
// import BiddingItem from '../../components/molecules/Bidding/BiddingItem'
import { useMediaQuery } from 'react-responsive'
import { makeStyles } from '@material-ui/core/styles'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import PropTypes from 'prop-types'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import 'react-photoswipe/lib/photoswipe.css'
import AuctionContext from '../../context/auction/auctionContext'
import ProductContext from '../../context/product/productContext'
import AuthContext from '../../context/auth/authContext'
import AlertContext from '../../context/alert/alertContext'
import UserContext from '../../context/user/userContext'
import Tooltip from '@material-ui/core/Tooltip'
// import AddCreditCard from '../AddCreditCard'
import ImageGallery from 'react-image-gallery'
import 'react-image-gallery/styles/css/image-gallery.css'
import Popup from '../../components/organisms/Popup'

import ReactPlayer from 'react-player/lazy'
import { socket, reInitializeSocket, socketSeller } from '../../common/socket'
import Slider from 'react-slick'
import Loaders from '../../components/molecules/Loader'
import { useDispatch } from 'react-redux'
import liveLotsReducer from '../../reducers/liveLotsReducer'
import auctionlotDetails from '../../actions/auctionLotsAction'
import auctionMsgDetails from '../../actions/auctionMessageAction'
import { connect } from 'react-redux'
import livelotDetails from '../../actions/liveLotsAction'
import BiddingLive from '../../components/molecules/BiddingLiveLot/BiddingLive'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import auctionPercentage from '../../actions/auctionPercentage'
import PrimaryButton from '../../components/atoms/PrimaryButton'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import lotSearchDetails from '../../actions/lotSearchAction'
import PreBidComponent from '../../components/molecules/PreBidComponent'
import { findIndex } from 'lodash'
import BidHistory from '../../components/organisms/BidHistory'
import qs from 'qs'

function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <div className="tabBody">{children}</div>}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

const useStyles = makeStyles((theme) => ({
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}))

function LotView(props) {
    const dispatch = useDispatch()
    const history = useHistory()
    const [auction, setAuction] = useState({})
    const [user, setUser] = useState()
    const [lotDetails, setLotDetails] = useState({})
    const [isLoading, setIsLoading] = useState(true)
    const [messageBoxContent, setMessageBoxContent] = useState([])
    const [currentLotIndex, setCurrentLotIndex] = useState('')
    const [currentLotImages, setCurrentLotImages] = useState([])
    const [completionPercentage, setCompletionPercentage] = useState(0)
    const [messageBoxElement, setMessageBoxElement] = useState()
    const [savedCards, setSavedCards] = useState([])
    const [viewAddCredit, setViewAddCredit] = useState(false)
    const noImageAvailable = '/assets/img.png'
    const [closedLot, setClosedLot] = useState(null)
    const [cancelBid, setCancelBid] = useState('')
    const [showTimer, setTimer] = useState(true)
    const [liveCountValue, setLiveCountValue] = useState(0)
    const [messageTemp, setMessageTemp] = useState('')
    const [updateBid, setUpdateBid] = useState('')
    const [historyId, setHistoryId] = useState(undefined)
    const [scrollLocationStore, setScrollLocationStore] = useState('')
    const [perPage, setPerPage] = useState(20)
    const [pageStore, setPageStore] = useState(1)
    const [reload, setReload] = useState(false)
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 8,
        slidesToScroll: 3,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
        // prevArrow: <SamplePrevArrow />,
        // nextArrow: <SampleNextArrow />,
    }

    useEffect(() => {
        if (props.allLotDetails?.allLotdetails?.results?.length) {
            let myArray = props.allLotDetails.allLotdetails.results
            let index = myArray.findIndex(
                (element) => parseInt(element.id) === parseInt(props.match.params.lotId),
            )
            console.log('currentLotIndex----------------- foundindex value', index)
            if (index > -1) {
                setCurrentLotIndex(index)
            }
        }
    }, [props.allLotDetails.allLotdetails.results])

    useEffect(() => {
        let auctionId = props.match.params.auctionId
        if (auctionId) {
            dispatch(
                lotSearchDetails({
                    limit: 1000,
                    orserby: 2,
                    page: 1,
                    lotof: auctionId,
                    order: 1,
                    userid: props.match.params.user_id ? props.match.params.user_id : '',
                }),
            )
        } else {
            console.log('error occured')
        }
    }, [props.match.params.auctionId])

    useEffect(() => {
        if (
            props.allLotDetails.allLotdetails.results &&
            Array.isArray(props.allLotDetails.allLotdetails.results) &&
            props.allLotDetails.allLotdetails.results.length !== 0
        ) {
            var auction_details = props.allLotDetails.allLotdetails.auctionList
            auction_details.isRegistered = props.allLotDetails.allLotdetails.isRegistered
            auction_details.currentProjectId = props.allLotDetails.allLotdetails.current_projectid
            setAuction(auction_details)
        }
    }, [props.allLotDetails.allLotdetails.auctionList])

    useEffect(() => {
        dispatch(
            livelotDetails({
                lotId: props.match.params.lotId,
                user_id: Number(props.match.params.user_id),
            }),
        )
    }, [props.match.params.lotId])

    useEffect(() => {
        if (props.liveLots.liveLots.list !== undefined) {
            if (Object.keys(props.liveLots.liveLots.list).length > 0) {
                setIsLoading(false)
                setLotDetails(props.liveLots.liveLots.list)
                let lotimages = []
                if (props.liveLots.liveLots.list.images.length > 0) {
                    props.liveLots.liveLots.list.images.map((imgObj) =>
                        lotimages.push({
                            original: process.env.REACT_APP_PRODUCT_IMG + imgObj.file_name,
                            thumbnail: process.env.REACT_APP_PRODUCT_IMG + imgObj.file_name,
                        }),
                    )
                } else {
                    lotimages.push({
                        original: '/images/pdtimg.png',
                        thumbnail: '/images/pdtimg.png',
                    })
                }
                setCurrentLotImages(lotimages.reverse())
            }
        }
    }, [props.liveLots.liveLots.list])

    useEffect(() => {
        if (props.location.search) {
            let query = qs.parse(props.location.search, { ignoreQueryPrefix: true })
            if (query.scrollLocation) {
                setScrollLocationStore(query.scrollLocation)
            }
            if (query.page) {
                setPageStore(query.page)
            }
            if (query.perPage) {
                setPerPage(query.perPage)
            }
        }
    }, [props.location.search])

    const lotDetailsRef = useRef(lotDetails)

    // useEffect(() => {
    //     socketForward.on('bidAddtime', (data) => {
    //         console.log('bid add time socket emit functions', data)
    //         // dispatch(
    //         //     livelotDetails({
    //         //         lotId: props.match.params.lotId,
    //         //         user_id: Number(props.match.params.user_id),
    //         //     }),
    //         // )
    //         setUpdateBid(data)
    //     })
    // }, [])

    // useEffect(() => {
    //     console.log('state updated')
    //     updateData(updateBid)
    // }, [updateBid])

    const handleHistory = (val) => setHistoryId(val)

    useEffect(() => {
        console.log('function initiated', lotDetails, props.bidding)
        if (Object.keys(props.bidding.bidding).length !== 0) {
            let bidding = props.bidding.bidding
            if (lotDetails && Object.keys(lotDetails).length > 0) {
                console.log('props bidding ids socket id', bidding.id, lotDetails.lotDetails.id)
                if (bidding.id == lotDetails.lotDetails.id) {
                    const newLot = lotDetails
                    console.log('product valuessssss-------------->2222', newLot)
                    if (bidding.bpop_cbidder == props.userDetails.userdetails.id) {
                        if (bidding.status == 'failed') {
                            bidding.error && toast.warning(bidding.error)
                        } else if (bidding.status == 'success') {
                            newLot.lotDetails.wprice = bidding.bpop_wprice
                            newLot.current_bid = bidding.bpop_wprice
                            if (bidding.bpop_higher == props.userDetails.userdetails.id) {
                                newLot.next_bid = bidding.bpop_cuser_nextbid || bidding.bpop_nextbid
                            } else {
                                newLot.next_bid = bidding.bpop_nextbid_org
                            }
                            newLot.lotDetails.bidcnt = bidding.bpop_bidcount
                            newLot.highbid_user_id = bidding.bpop_higher
                            setLotDetails(newLot)
                            // if (bidding.bpop_error) {
                            //     toast.success(bidding.bpop_error)
                            // }
                        }
                    } else {
                        console.log('in else part')
                        if (bidding.status !== 'failed') {
                            if (newLot.userBidCount !== undefined && newLot.userBidCount !== 0) {
                                if (bidding.bpop_higher != props.userDetails.userdetails.id) {
                                    if (
                                        Number(bidding.bpop_wprice) >=
                                        Number(newLot.lotDetails.next_bid)
                                    ) {
                                        newLot.next_bid = bidding.bpop_nextbid_org
                                    }
                                    newLot.lotDetails.wprice = bidding.bpop_wprice
                                    newLot.current_bid = bidding.bpop_wprice
                                    newLot.lotDetails.bidcnt = bidding.bpop_bidcount
                                    newLot.highbid_user_id = bidding.bpop_higher
                                    setLotDetails(newLot)
                                } else {
                                    newLot.lotDetails.wprice = bidding.bpop_wprice
                                    if (bidding.bpop_higher == props.userDetails.userdetails.id) {
                                        newLot.next_bid =
                                            bidding.bpop_wprice_morehigh +
                                            (bidding.bpop_wprice_morehigh_increment ||
                                                bidding.bpop_increment)
                                    } else {
                                        newLot.next_bid = bidding.bpop_nextbid_org
                                    }
                                    newLot.lotDetails.bidcnt = bidding.bpop_bidcount
                                    newLot.current_bid = bidding.bpop_wprice
                                    newLot.highbid_user_id = bidding.bpop_higher
                                    setLotDetails(newLot)
                                }
                            } else {
                                if (bidding.bpop_higher == props.userDetails.userdetails.id) {
                                    newLot.next_bid =
                                        bidding.bpop_wprice_morehigh +
                                        (bidding.bpop_wprice_morehigh_increment ||
                                            bidding.bpop_increment)
                                } else {
                                    newLot.next_bid = bidding.bpop_nextbid_org
                                }
                                newLot.lotDetails.wprice = bidding.bpop_wprice
                                newLot.current_bid = bidding.bpop_wprice
                                newLot.lotDetails.bidcnt = bidding.bpop_bidcount
                                newLot.highbid_user_id = bidding.bpop_higher
                                setLotDetails(newLot)
                            }
                        }
                    }
                }
            }
            setReload(!reload)
        }
    }, [props.bidding])

    // if (data.status !== 'failed') {
    //     if (parseInt(data.id) === parseInt(newLot.lotDetails.id)) {
    //         newLot.lotDetails.bidcnt = data.bpop_bidcount
    //         newLot.lotDetails.wprice = data.bpop_wprice
    //         newLot.current_bid = data.bpop_wprice
    //         if (data.bpop_higher == props.userDetails.userdetails.id) {
    //             newLot.next_bid = data.bpop_wprice_morehigh + data.bpop_increment
    //         } else {
    //             newLot.next_bid = data.bpop_nextbid_org
    //         }
    //     }
    //     setLotDetails(newLot)
    //     console.log('after bid place socket function lot data', newLot)
    // }

    useEffect(() => {
        socket.on('liveauctionclose', (data) => {
            console.log(
                'Inside Auction Close in livelotpage',
                data,
                data.auctionid,
                props.match.params.auctionId,
            )
            if (parseInt(data.auctionid) === parseInt(props.match.params.auctionId)) {
                console.log('Inside This Auction Close')
                // setTimer(false)
                Swal.fire({
                    icon: 'success',
                    title: 'This Auction Has Been Ended By Seller Now.',
                    showConfirmButton: true,
                    position: 'center',
                }).then(function (data) {
                    if (data.isConfirmed) {
                        return history.push('/search')
                    }
                })
            }
        })
        socketSeller.on('liveauctionclose', (data) => {
            console.log(
                'Inside Auction Close in livelotpage',
                data,
                data.auctionid,
                props.match.params.auctionId,
            )
            if (parseInt(data.auctionid) === parseInt(props.match.params.auctionId)) {
                console.log('Inside This Auction Close')
                // setTimer(false)
                Swal.fire({
                    icon: 'success',
                    title: 'This Auction Has Been Ended By Seller Now.',
                    showConfirmButton: true,
                    position: 'center',
                }).then(function (data) {
                    if (data.isConfirmed) {
                        return history.push('/search')
                    }
                })
            }
        })
    }, [])

    // useEffect(() => {
    //     socket.on('liveauctionclose', (data) => {
    //         console.log(
    //             'Inside Auction Close in livelotpage socket forward',
    //             data,
    //             data.auctionid,
    //             props.match.params.auctionId,
    //         )
    //         if (parseInt(data.auctionid) === parseInt(props.match.params.auctionId)) {
    //             console.log('Inside This Auction Close')
    //             setTimer(false)
    //             Swal.fire({
    //                 icon: 'success',
    //                 title: 'This Auction Has Been Ended By Seller Now.',
    //                 showConfirmButton: true,
    //                 position: 'center',
    //             }).then(function (data) {
    //                 if (data.isConfirmed) {
    //                     return history.push('/search')
    //                 }
    //             })
    //         }
    //     })
    // }, [])

    // useEffect(() => {
    //     socketForward.on('liveauctionAwarding', (data) => {
    //         console.log('closed auction emit', data)
    //         console.log('allauctionlots new', props.auctionLots.allauctionlots)
    //         let lotArray = props.allLotDetails.allLotdetails.results
    //         let closedIndex = lotArray.findIndex((ele) => ele.id === parseInt(data.lotid))
    //         let auctionDetails = auction
    //         if (
    //             closedIndex != null &&
    //             closedIndex < props.allLotDetails.allLotdetails.results.length - 1
    //         ) {
    //             auctionDetails.currentProjectId =
    //                 props.allLotDetails.allLotdetails.results[closedIndex + 1].id
    //             setAuction(auctionDetails)
    //         }
    //         // setClosedLot(data)
    //     })
    // }, [])

    useEffect(() => {
        socket.on('liveauctionAwarding', (data) => {
            if (data.site_id && data.site_id != process.env.REACT_APP_SITE_ID) {
                // skip if site_id not matching
                return false
            }

            console.log('closed auction emit in lot view page', data)
            setClosedLot(data)
        })
        socketSeller.on('liveauctionAwarding', (data) => {
            if (data.site_id && data.site_id != process.env.REACT_APP_SITE_ID) {
                // skip if site_id not matching
                return false
            }

            console.log('closed auction emit in lot view page', data)
            setClosedLot(data)
        })
    }, [])

    console.log('closedLot -------- >', closedLot)

    useEffect(() => {
        console.log('closedLot useEffec -------- >', closedLot)
        if (closedLot) {
            console.log(
                'closedLot allauctionlotsnew ===',
                props.allLotDetails.allLotdetails.results[currentLotIndex + 1],
                props.allLotDetails.allLotdetails.results.length > 1,
            )
            if (props.allLotDetails.allLotdetails.results[currentLotIndex + 1] !== undefined) {
                if (
                    parseInt(closedLot.lotid) ===
                    parseInt(props.allLotDetails.allLotdetails.results[currentLotIndex]?.id)
                ) {
                    setCurrentLotIndex(currentLotIndex + 1)
                    handleRedirectInternal(
                        history,
                        `lotview/${props.match.params.auctionId}/${
                            props.allLotDetails.allLotdetails.results[currentLotIndex + 1]?.id
                        }/${props.match.params.user_id}`,
                    )
                }
                console.log(
                    'closedLot new auction data before value set',
                    auction,
                    closedLot.lotid,
                    props.allLotDetails.allLotdetails.results[currentLotIndex]?.id,
                )
                let newAuction = auction
                newAuction.currentProjectId = closedLot.next_lot
                console.log(
                    'closedLot new Auction Data after data set',
                    newAuction,
                    auction,
                    props.allLotDetails.allLotdetails.results[currentLotIndex + 1]?.id,
                    closedLot.next_lot,
                )
                setAuction(newAuction)
                setClosedLot(null)
            } else {
                Swal.fire({
                    icon: 'success',
                    title: 'This Auction Has Been Closed.',
                    showConfirmButton: true,
                    position: 'center',
                }).then(function (data) {
                    if (data.isConfirmed) {
                        return history.push('/search')
                    }
                })
            }
        }
    }, [closedLot])

    const handleLotChangeClick = (e, type) => {
        if (type === 'next') {
            if (currentLotIndex < props.allLotDetails.allLotdetails.results.length - 1) {
                setCurrentLotIndex(currentLotIndex + 1)
            }
        }
        if (type === 'prev') {
            if (currentLotIndex > 0) {
                setCurrentLotIndex(currentLotIndex - 1)
            }
        }
    }

    const handleLotImageClick = (e, index) => {
        if (currentLotIndex !== index) {
            setCurrentLotIndex(index)
        }
    }

    console.log('currentLotIndex----------------->props.match.params', props.match.params.lotId)

    useEffect(() => {
        if (
            props.allLotDetails.allLotdetails.results?.length &&
            currentLotIndex !== '' &&
            currentLotIndex !== undefined
        ) {
            console.log(
                'currentLotIndex----------------->outside if',
                currentLotIndex,
                props.allLotDetails.allLotdetails.results[currentLotIndex]?.id,
            )
            if (
                props.allLotDetails.allLotdetails.results[currentLotIndex]?.id !=
                props.match.params.lotId
            ) {
                console.log(
                    'currentLotIndex----------------->currentlotid and params id are not same',
                    currentLotIndex,
                    props.allLotDetails.allLotdetails.results[currentLotIndex]?.id,
                )
                handleRedirectInternal(
                    history,
                    `lotview/${props.match.params.auctionId}/${
                        props.allLotDetails.allLotdetails.results[currentLotIndex]?.id
                    }/${props.match.params.user_id || 0}`,
                )
            }
        }
    }, [currentLotIndex])

    const isMobile = useMediaQuery({
        query: '(max-width: 600px)',
    })
    const [isOpen, setOpen] = useState(false)
    const classes = useStyles()

    const productDetails = [
        {
            title: 'Description',
            description: lotDetails.lotDetails && lotDetails.lotDetails.description,
        },
        {
            title: 'Shipping',
            description: auction.store_comment_two ? auction.store_comment_two : '',
        },
        {
            title: 'Terms',
            description: auction.store_comment_four ? auction.store_comment_four : '',
        },
        {
            title: 'Payment',
            description:
                (auction.store_comment_one ? auction.store_comment_one : '') +
                (auction.store_comment_five ? auction.store_comment_five : ''),
        },
    ]

    const [value, setValue] = useState(0)
    const [expanded, setExpanded] = useState(false)

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const handleExpand = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false)
    }

    useEffect(() => {
        if (auction.id) {
            init()
        }
    }, [auction])

    const config = {
        iceServers: [
            {
                urls: 'turn:100.20.224.224:3478',
                credential: 'Aucsoft@!',
                username: 'AsUser',
            },
        ],
    }

    let peerConnection
    const videoRef = useRef()

    useEffect(() => {
        socket.on('cancelbidemitted', (data) => {
            console.log('cancel bid emitted data', data)
            setCancelBid(data)
        })
        socketSeller.on('cancelbidemitted', (data) => {
            console.log('cancel bid emitted data', data)
            setCancelBid(data)
        })
    }, [])

    useEffect(() => {
        if (cancelBid) {
            console.log('cancel bid update values', lotDetails, cancelBid, cancelBid.prodata)
            if (
                cancelBid &&
                // cancelBid.prodata &&
                // Object.keys(cancelBid.prodata).length > 0 &&
                lotDetails &&
                Object.keys(lotDetails).length > 0
            ) {
                let newLot = lotDetails

                console.log('current id vs new lot id', cancelBid.id, newLot.lotDetails.id)

                if (parseInt(cancelBid.project_id) === parseInt(newLot.lotDetails.id)) {
                    newLot.lotDetails.bidcnt = cancelBid.bpop_bidcount
                    newLot.lotDetails.wprice = cancelBid.bpop_wprice || newLot.lotDetails.wprice
                    newLot.current_bid = cancelBid.prodata
                        ? cancelBid.prodata.proposed_amount
                        : newLot.lotDetails.wprice
                    newLot.next_bid = cancelBid.bpop_nextbid || newLot.next_bid
                    newLot.highbid_user_id = cancelBid.prodata ? cancelBid.prodata.user_id : ''

                    if (
                        cancelBid.cancelbid &&
                        cancelBid.cancelbid.uid == props.userDetails.userdetails.id
                    ) {
                        newLot.cancel_bid = cancelBid.cancelbid.bid
                    }
                }
                setLotDetails(newLot)
            }
        }
    }, [cancelBid])

    useEffect(() => {
        socket.on('candidate', (id, candidate) => {
            console.log('peerConnection', peerConnection)
            peerConnection
                .addIceCandidate(new RTCIceCandidate(candidate))
                .catch((e) => console.error(e))
        })
    }, [])

    useEffect(() => {
        return () => {
            socket.emit('watcher-disconnect', localStorage.getItem('broadcasterID'))
            if (peerConnection) {
                peerConnection.close()
            }
            // reInitializeSocket()
        }
    }, [])

    useEffect(() => {
        socket.on('disconnectPeer', () => {
            peerConnection.close()
        })
    }, [])

    useEffect(() => {
        window.onunload = window.onbeforeunload = () => {
            socket.emit('watcher-disconnect', localStorage.getItem('broadcasterID'))
            if (peerConnection) {
                peerConnection.close()
            }
            // reInitializeSocket()
        }
    }, [window])

    useEffect(() => {
        setUser(props.userDetails.userdetails)
    }, [props.userDetails])

    const tooltipLotdtls = (lot) => {
        return (
            <div className="toolTopInfo">
                <LazyLoadImage
                    src={process.env.REACT_APP_PRODUCT_IMG + lot.avatar}
                    effect="blur"
                    placeholderSrc="/images/imageLoading.svg"
                    height="100%"
                    width="100%"
                    onClick={(e) => handleLotImageClick(e)}
                    onError={onImageError}
                    alt="Next Lot"
                />

                <p>Lot Name : {lot.title}</p>
                <p>Current Price : US ${lot.wprice}</p>
            </div>
        )
    }

    async function init() {
        const payload = {
            auctionid: auction.id,
        }
    }

    const onImageError = (e) => (e.target.src = '/images/coin-auction-logo.png')

    // const handleClick = async (body, nPrice, auction_type, lot_id) => {
    //     if (isAuthenticate()) {
    //         if (lotData.con_check === 1) {
    //             if (parseInt(lotData.is_registered) === 0) {
    //                 Swal.fire({
    //                     icon: 'error',
    //                     title: 'Please Register Live Auction And Participate.',
    //                     timer: 2500,
    //                     showConfirmButton: false,
    //                     position: 'center',
    //                 })
    //                 return window.scrollTo(0, 0)
    //             } else {
    //                 body.hard_bid = 0
    //                 body.auction_io = 1
    //             }
    //         }
    //         if (
    //             moment(props.serverTime).isBefore(moment(lotData.date_added)) &&
    //             auction_type !== 1
    //         ) {
    //             toast.warning('Auction is not started!')
    //             return false
    //         }

    //         if (nPrice > body.wsprice) {
    //             toast.warning(`Enter $${nPrice} or more`)
    //         } else {
    //             if (auction_type === 1) {
    //                 await preAuctionBid({
    //                     wsprice: parseInt(body.wsprice),
    //                     userid: props.userDetails.userdetails.id,
    //                     id: parseInt(lot_id),
    //                     lotid: parseInt(lot_id),
    //                     bidplan: 'auto',
    //                     auctionid: parseInt(id),
    //                 })
    //                 setAmount({ ...amount, [body.id]: '' })
    //             } else {
    //                 await commonBidding({
    //                     ...body,
    //                     email: props.userDetails.userdetails.email,
    //                     userid: props.userDetails.id,
    //                 })
    //                 setAmount({ ...amount, [body.id]: '' })
    //             }
    //         }
    //     } else {
    //         history.push({ pathname: '/login', state: props.location })
    //     }
    // }
    console.log('auction and lot data', auction, lotDetails)
    return (
        <>
            {isLoading ? (
                <div className="mnHtcontnr">
                    <div className="liveLotsLoader">
                        <Loaders name="live_auction" isLoading={isLoading} />
                    </div>
                </div>
            ) : (
                <div className="stdlotViewWrapper">
                    <ToastContainer
                        autoClose={5000}
                        hideProgressBar={true}
                        pauseOnFocusLoss={false}
                    />
                    <div className="row lotMain">
                        <div className="col-md-6 col-12 lotSection ">
                            <div className="topShftNav mb-5">
                                <a
                                    href={`/auction/${auction.id}?page=${pageStore}&scrollLocation=${scrollLocationStore}&perPage=${perPage}`}
                                    className="vwActnBtn d-flex align-items-center mr-auto"
                                >
                                    <span className="material-icons">chevron_left</span> View
                                    Auction
                                </a>
                            </div>
                            <div className="d-flex">
                                <div className="currentLot">
                                    <div className="imageSliderLive">
                                        <ImageGallery
                                            items={currentLotImages}
                                            thumbnailPosition="bottom"
                                            showNav={false}
                                            lazyLoad={true}
                                            showBullets={false}
                                            showFullscreenButton={true}
                                            showPlayButton={false}
                                            onImageError="/images/pdtimg.png"
                                            onErrorImageURL="/images/pdtimg.png"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-12">
                            <div className="topShftNav d-flex align-items-center justify-content-between mb-5">
                                <div className="prevLotShft">
                                    {currentLotIndex === 0 ? (
                                        ''
                                    ) : (
                                        <p
                                            className="d-flex cursorDecoy align-items-center mb-0"
                                            onClick={(e) => handleLotChangeClick(e, 'prev')}
                                        >
                                            <span className="material-icons">chevron_left</span>{' '}
                                            Prev Lot
                                        </p>
                                    )}
                                </div>
                                <div className="crntLtVe">Lot {lotDetails.lotDetails.sku}</div>
                                <div className="rtLotShft">
                                    {props.allLotDetails.allLotdetails.results &&
                                    currentLotIndex ===
                                        props.allLotDetails.allLotdetails.results.length - 1 ? (
                                        ''
                                    ) : (
                                        <p
                                            className="d-flex cursorDecoy align-items-center mb-0"
                                            onClick={(e) => handleLotChangeClick(e, 'next')}
                                        >
                                            Next Lot{' '}
                                            <span className="material-icons">chevron_right</span>
                                        </p>
                                    )}
                                </div>
                            </div>
                            <div className="lotDetails ltVws text-left">
                                <h2
                                    className="mb-2"
                                    dangerouslySetInnerHTML={{
                                        __html: lotDetails.lotDetails.title,
                                    }}
                                ></h2>
                                <h6 className="autnTitle text-left mb-4">{auction.title}</h6>
                            </div>
                            {lotDetails.highbid_user_id &&
                                lotDetails.highbid_user_id == props.userDetails.userdetails.id && (
                                    <div className="text-right">
                                        <h5 className="winningLabel text-right mb-4">
                                            <b>You're Winning!</b>
                                        </h5>
                                    </div>
                                )}
                            <div className="d-flex align-items-center justify-content-between mb-2 mt-5 estmtWrpr">
                                <p className="mb-0 estMtTxt">
                                    Estimate: {currencyFormat(lotDetails.lotDetails.lowest)} -
                                    {currencyFormat(lotDetails.lotDetails.highest)}
                                </p>
                                <div className="tmrDv">
                                    <Timer
                                        productTime={{
                                            start: lotDetails.lotDetails.date_added,
                                            end: lotDetails.lotDetails.date_closed,
                                        }}
                                        timerBasic={true}
                                    />
                                </div>
                            </div>
                            {/* {console.log('lot details', lotDetails, lotDetails.current_bid)} */}
                            {lotDetails.lotDetails &&
                            parseInt(lotDetails.lotDetails.bidding_hide) != 1 ? (
                                <div className="bidCnt">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <h2 className="text-left">
                                            {currencyFormat(lotDetails.current_bid)}
                                        </h2>
                                        {auction.con_check === 1 ? (
                                            <p className="cursorDecoy">
                                                {lotDetails.lotDetails.bidcnt} Bids
                                            </p>
                                        ) : auction.con_check === 0 ? (
                                            <p
                                                className="cursorDecoy"
                                                onClick={(e) =>
                                                    handleHistory(lotDetails.lotDetails.id)
                                                }
                                            >
                                                {lotDetails.lotDetails.bidcnt} Bids
                                            </p>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                    {props.allLotDetails.allLotdetails &&
                                        props.allLotDetails.allLotdetails.results &&
                                        props.allLotDetails.allLotdetails.results.length &&
                                        new Date() <
                                            new Date(lotDetails.lotDetails.date_closed) && (
                                            <>
                                                {new Date() <
                                                new Date(lotDetails.lotDetails.date_added) ? (
                                                    <PreBidComponent
                                                        lotdetails={lotDetails}
                                                        type="proxy"
                                                        size="medium"
                                                        auctionId={auction.id}
                                                        user={user}
                                                        is_not_approved={auction.isRegistered}
                                                        cancel_bid={lotDetails.cancel_bid || null}
                                                    />
                                                ) : (
                                                    auction.currentProjectId !=
                                                        lotDetails.lotDetails.id && (
                                                        <PreBidComponent
                                                            lotdetails={lotDetails}
                                                            type="proxy"
                                                            size="medium"
                                                            auctionId={auction.id}
                                                            user={user}
                                                            is_not_approved={auction.isRegistered}
                                                            cancel_bid={
                                                                lotDetails.cancel_bid || null
                                                            }
                                                        />
                                                    )
                                                )}
                                            </>
                                        )}
                                    {/* <div className="byprmWrpr">
                                    <p className="mb-0 mt-3 text-left">
                                        Buyer's Premium: {auction.buyer_premium}%
                                    </p>
                                </div> */}
                                </div>
                            ) : null}
                        </div>
                    </div>
                    {lotDetails.lotDetails.videos.length > 0 &&
                        lotDetails.lotDetails.videos?.split(',').length && (
                            <div className="row mt-5">
                                <div className="col-md-12 text-left">
                                    <h4 className="mb-4">Videos</h4>
                                </div>
                                <div className="col-md-12 vdosWrapper">
                                    {lotDetails.lotDetails.videos?.split(',').map((url) => (
                                        <div className="vdopPlayer">
                                            <ReactPlayer url={url} controls={true} />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    <div className="row mt-5">
                        <div className="col-12 col-md-12 descFldDynTxt">
                            <div className={`${isMobile && 'mobileView'} productDetails`}>
                                {isMobile ? (
                                    <div className="pvAccordian mt-2 mb-2 w-100">
                                        {productDetails.map((data, index) => (
                                            <Accordion
                                                expanded={expanded === `panel${index}`}
                                                onChange={handleExpand(`panel${index}`)}
                                            >
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls={`panel${index}a-content`}
                                                    id={`panel${index}a-header`}
                                                >
                                                    <Typography className={classes.heading}>
                                                        {data.title}
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography>
                                                        {data.title === 'Description' ? (
                                                            <div
                                                                dangerouslySetInnerHTML={{
                                                                    __html: data.description,
                                                                }}
                                                            />
                                                        ) : (
                                                            <div
                                                                dangerouslySetInnerHTML={{
                                                                    __html:
                                                                        data.description &&
                                                                        data.description.trim(),
                                                                }}
                                                            />
                                                        )}
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                        ))}
                                    </div>
                                ) : (
                                    <>
                                        <AppBar position="static">
                                            <Tabs
                                                value={value}
                                                onChange={handleChange}
                                                aria-label="simple tabs example"
                                            >
                                                {productDetails.map((data, index) => (
                                                    <Tab
                                                        label={data.title}
                                                        {...a11yProps({ index })}
                                                    />
                                                ))}
                                            </Tabs>
                                        </AppBar>
                                        {productDetails.map((data, index) => (
                                            <TabPanel value={value} index={index}>
                                                {data.title === 'Description' ? (
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: data.description,
                                                        }}
                                                    />
                                                ) : (
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: data.description,
                                                        }}
                                                    />
                                                )}
                                            </TabPanel>
                                        ))}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 lotSection upcomming-sliebar">
                            <div className="upcomingLots">
                                <h5 className="upcmgLtsTtle text-left mb-4 mt-5">OTHERS LOTS</h5>
                                <Slider {...settings}>
                                    {props.allLotDetails.allLotdetails.results &&
                                        props.allLotDetails.allLotdetails.results.length > 0 &&
                                        props.allLotDetails.allLotdetails.results.map(
                                            (lot, index) => (
                                                <Button
                                                    className={`ucImg  ${
                                                        currentLotIndex === index
                                                            ? 'active-slide'
                                                            : ''
                                                    }`}
                                                    key={index}
                                                >
                                                    <LazyLoadImage
                                                        src={
                                                            process.env.REACT_APP_PRODUCT_IMG +
                                                            lot.avatar
                                                        }
                                                        effect="blur"
                                                        placeholderSrc="/images/imageLoading.svg"
                                                        height="100%"
                                                        width="100%"
                                                        onClick={(e) =>
                                                            handleLotImageClick(e, index)
                                                        }
                                                        onError={onImageError}
                                                        alt="Next Lot"
                                                    />
                                                </Button>
                                            ),
                                        )}
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <BidHistory
                open={historyId !== undefined}
                productId={historyId}
                onClose={handleHistory}
                auctionType={auction.con_check === 1 ? 'liveAuction' : 'TimedAuction'}
            />
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        userDetails: state.User ? state.User : {},
        bidding: state.bidding ? state.bidding : {},
        serverTime: state.serverTime ? state.serverTime.time : '',
        liveLots: state.liveLots ? state.liveLots : '',
        auctionLots: state.auctionLots ? state.auctionLots : '',
        allLotDetails: state.allLotDetails ? state.allLotDetails : '',
    }
}

export default connect(mapStateToProps, null)(LotView)
