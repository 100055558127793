import React, { useState, useEffect } from 'react'
import SecondaryButton from '../../components/atoms/SecondaryButton/index'
import PrimaryButton from '../../components/atoms/PrimaryButton/index'
import { isEmpty, findIndex } from 'lodash'
import Pagination from '@material-ui/lab/Pagination'
import { getPaginationCount } from '../../Utils/Utility'
import { useHistory } from 'react-router-dom'
import './wonAuction.css'
import { handleRedirectInternal } from '../../common/components'
import Switch from '@material-ui/core/Switch'
import { currencyFormat } from '../../common/components'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import Tooltip from '@material-ui/core/Tooltip'
import Swal from 'sweetalert2'
import { connect, useDispatch, useSelector } from 'react-redux'
import Popup from '../../components/organisms/Popup'
import AdditionalPayment from '../../components/molecules/AdditionalPyament'
import moment from 'moment-timezone'
const WonAuction = (props) => {
    const data = ['Paid', 'Unpaid', 'Unpaid', 'Unpaid', 'Paid', 'Paid']
    const history = useHistory()
    const [invoice_id, setInvoice] = useState([])
    const [unpaid_count, setInvoicecount] = useState(0)
    const [total_amount, setTotalamount] = useState(0)
    const [include_trasactionfee, setTrasactionfee] = useState(true)
    const [transaction_fee_enable, setTransactionEnable] = useState(0)
    const [sales_tax, setSalesTax] = useState(0)
    const [modalOpen, setmodalOpen] = useState(true)
    const [addtional_pay, setAddtionalpay] = useState(false)
    const state_header = useSelector((state) => state.storeDetails)
    const [invobj, setInvObj] = useState([])
    const [inv_index, setInvindex] = useState(0)
    useEffect(() => {
        if (state_header?.storeDetails?.store_configuration?.length > 0) {
            state_header.storeDetails.store_configuration.map((data) => {
                if (data.variable == 'transaction_fee_changes' && data.value == '1') {
                    setTransactionEnable(1)
                }
                if (data.variable == 'sales_tax') {
                    setSalesTax(parseFloat(data.value))
                }
            })
        }
    }, [state_header])
    const add_to_card = (data) => {
        var arr = invoice_id
        var temp = []
        if (arr.includes(data.common_invoice)) {
            temp = arr.filter((val) => val != data.common_invoice)
        } else {
            temp = [...arr, data.common_invoice]
        }
        setInvoice(temp)
    }

    useEffect(() => {
        if (!isEmpty(props.data.results)) {
            var temp = [],
                arr = [],
                unpaid_count_local = 0,
                total_amount = 0,
                unpaid_obj = []
            props.data.results.map((data) => {
                arr = temp
                console.log(
                    'mmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm',
                    new Date(data.buynowdate_added),
                    new Date('12/16/2013 00:00:00'),
                )
                if (new Date(data.buynowdate_added) > new Date('12/16/2023 00:00:00')) {
                    console.log('kkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk')
                    if (data.paid == 0) {
                        unpaid_count_local = unpaid_count_local + 1
                        if (arr.includes(data.common_invoice)) {
                            // temp = arr.filter((val) => val != data.common_invoice)
                        } else {
                            temp = [...arr, data.common_invoice]
                            unpaid_obj.push({
                                inv_number: data.common_invoice,
                                aid: data.lotof,
                                buynow_check: data.payment_approve,
                            })
                        }
                    }
                    if (transaction_fee_enable) {
                        var total_amount_pre =
                            parseFloat(data.buynowamount) +
                            parseFloat(data.shippingcost) +
                            parseFloat(
                                (parseFloat(data.buynowamount) *
                                    parseFloat(data.buyerpremium_percentage)) /
                                    100,
                            ) +
                            parseFloat(
                                (parseFloat(data.buynowamount) * parseFloat(sales_tax)) / 100,
                            )
                        data.transaction_fee = parseFloat(
                            parseFloat(
                                parseFloat((parseFloat(total_amount_pre) * 3.1) / 100) + 0.3,
                            ).toFixed(2),
                        )
                        data.total_amount =
                            parseFloat(total_amount_pre) + parseFloat(data.transaction_fee)
                    } else {
                        data.transaction_fee = parseFloat(
                            parseFloat(
                                parseFloat((parseFloat(data.buynowamount) * 3.1) / 100) + 0.3,
                            ).toFixed(2),
                        )
                        data.total_amount =
                            parseFloat(data.buynowamount) + parseFloat(data.transaction_fee)
                    }

                    if (data.paid == 0) {
                        total_amount = parseFloat(total_amount) + parseFloat(data.total_amount)
                    }
                }
            })
            setInvoice(temp)
            setInvoicecount(unpaid_count_local)
            setTotalamount(total_amount)
            setInvObj([...unpaid_obj])
        }
    }, [props.data, transaction_fee_enable])

    const MovetoCheckout = (data) => {
        var tot_amount = 0
        if (transaction_fee_enable) {
            var total_amount_pre =
                parseFloat(data.buynowamount) +
                parseFloat(data.shippingcost) +
                parseFloat(
                    (parseFloat(data.buynowamount) * parseFloat(data.buyerpremium_percentage)) /
                        100,
                ) +
                parseFloat((parseFloat(data.buynowamount) * parseFloat(sales_tax)) / 100)
            data.transaction_fee = parseFloat(
                parseFloat(parseFloat((parseFloat(total_amount_pre) * 3.1) / 100) + 0.3).toFixed(2),
            )
            data.total_amount = parseFloat(total_amount_pre) + parseFloat(data.transaction_fee)
        } else {
            data.transaction_fee = parseFloat(
                parseFloat(parseFloat((parseFloat(data.buynowamount) * 3.1) / 100) + 0.3).toFixed(
                    2,
                ),
            )
            data.total_amount = parseFloat(data.buynowamount) + parseFloat(data.transaction_fee)
        }

        if (data.paid == 0) {
            tot_amount = parseFloat(tot_amount) + parseFloat(data.total_amount)
        }

        if (parseFloat(tot_amount) > 1000) {
            Swal.fire({
                title: 'Due to the size of your invoice, Coins & Auctions Since 1994 will contact you directly with an invoice',
                icon: 'error',
                timer: 2500,
                showConfirmButton: false,
                position: 'center',
            })
        } else {
            // var inv_block = 0,
            //     inv_number = 0
            // invobj.map((d) => {
            //     if (parseInt(d.buynow_check) != 1) {
            //         inv_block = 1
            //         inv_number = d.inv_number
            //     }
            // })
            if (data.buynow_check != 1) {
                Swal.fire({
                    title: `Invoice # ${data.common_invoice} is not ready to checkout.`,
                    icon: 'error',
                    timer: 2500,
                    showConfirmButton: false,
                    position: 'center',
                })
            } else {
                window.location.href = `Checkout?id=${data.common_invoice}`
            }
        }
    }

    const modalClose = () => {
        setAddtionalpay(false)
    }

    return (
        <div>
            <div className="wonAuction-Table table-responsive invoiceTbl">
                <table className="table">
                    <thead className="thead-light">
                        {/* <tr>
                            <td colSpan="8"></td>
                            <td colSpan="2">
                                <SecondaryButton
                                    label={`Checkout (${unpaid_count})`}
                                    onClick={() => {
                                        // history.push({
                                        //     pathname: `/Checkout?id=${invoice_id.join('&id=')}`,
                                        //     state: {},
                                        // })
                                        if (parseInt(unpaid_count) > 0) {
                                            MovetoCheckout()
                                        }

                                        // handleRedirectInternal(
                                        //     history,
                                        //     `Checkout?id=${invoice_id.join('&id=')}`,
                                        // )
                                    }}
                                    disabled={parseInt(unpaid_count) > 0 ? true : false}
                                />
                            </td>
                        </tr> */}
                        <tr>
                            <th scope="col">Auction ID</th>
                            <th scope="col">Auction Date</th>
                            <th scope="col">Invoice No</th>
                            <th scope="col">Lot Count</th>
                            <th scope="col">Auction Title</th>
                            {/* <th scope="col">Quantity</th> */}
                            {/* <th scope="col"> Date Ended</th> */}
                            <th scope="col">Amount</th>
                            {/* <th scope="col">
                                {' '}
                                <div className="row">
                                    <p>Transaction Fee</p>
                                    <Tooltip
                                        placement="top"
                                        title={
                                            <div
                                                style={{
                                                    width: '300px',
                                                    padding: '5px',
                                                    fontSize: '18px',
                                                    color: 'white',
                                                }}
                                            >
                                                (3.1% + 30 cents) of won amount.
                                            </div>
                                        }
                                        arrow
                                    >
                                        <span className="material-icons">info</span>
                                    </Tooltip>
                                </div>{' '}
                            </th> */}
                            <th colSpan="5">
                                {include_trasactionfee ? 'Card Fee' : 'Check/ACH Payment'}
                                <div className="tableSwitch">
                                    <div className="das-togle-switch">
                                        <Switch
                                            checked={include_trasactionfee}
                                            onChange={(event) =>
                                                setTrasactionfee(event.target.checked)
                                            }
                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                        />
                                    </div>
                                    <Tooltip
                                        placement="top"
                                        title={
                                            <div
                                                style={{
                                                    // width: '300px',
                                                    // padding: '5px',
                                                    fontSize: '16px',
                                                    lineHeight: '24px',
                                                }}
                                            >
                                                Switch off to view total amount without transaction
                                                fee.
                                            </div>
                                        }
                                        arrow
                                    >
                                        <span className="material-icons">info</span>
                                    </Tooltip>
                                </div>
                            </th>
                            <th scope="col">Action</th>
                            {/* <th scope="col">Invoice</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {!isEmpty(props.data.results) ? (
                            <>
                                {props.data.results.map((data, index) => (
                                    <tr>
                                        <td>{data.lotof}</td>
                                        <td>
                                            {' '}
                                            {moment(
                                                data.con_check ? data.date_added : data.date_closed,
                                            )
                                                .tz('America/Los_Angeles')
                                                .format('MM/DD/YYYY hh:mm A')}{' '}
                                            PST
                                        </td>
                                        <td>{data.common_invoice}</td>
                                        <td>{data.lotcount}</td>
                                        <td>
                                            {' '}
                                            <p
                                                dangerouslySetInnerHTML={{
                                                    __html: data.auctionlot_title,
                                                }}
                                            ></p>
                                        </td>
                                        {/* <td>{data.qty}</td> */}
                                        {/* <td>{data.dateclosing}</td> */}
                                        <td>{currencyFormat(parseFloat(data.buynowamount))}</td>
                                        {/* <td>{currencyFormat(parseFloat(data.transaction_fee))}</td> */}
                                        <td colSpan="5">
                                            {data.paid === 0 &&
                                            new Date(data.buynowdate_added) >
                                                new Date('12/16/2023 00:00:00') ? (
                                                <>
                                                    {currencyFormat(
                                                        parseFloat(
                                                            include_trasactionfee
                                                                ? data.total_amount
                                                                : data.buynowamount,
                                                        ),
                                                    )}
                                                </>
                                            ) : (
                                                '-'
                                            )}
                                        </td>
                                        {/* <td className={data}>{data.dateclosing}</td> */}
                                        <td>
                                            {data.paid === 0 &&
                                            data.transcationid == null &&
                                            new Date(data.buynowdate_added) >
                                                new Date('12/16/2023 00:00:00') &&
                                            data.buynow_check != 1 ? (
                                                <p style={{ color: 'red' }}>
                                                    Your invoice is pending approval.
                                                </p>
                                            ) : data.paid === 0 &&
                                              data.transcationid == null &&
                                              new Date(data.buynowdate_added) >
                                                  new Date('12/16/2023 00:00:00') ? (
                                                <SecondaryButton
                                                    label={`Checkout`}
                                                    onClick={() => {
                                                        MovetoCheckout(data)
                                                    }}
                                                />
                                            ) : data.buynowpaid === 0 && data.partial === 1 ? (
                                                <SecondaryButton
                                                    label={`Pay (${currencyFormat(
                                                        parseFloat(data.ship_offer),
                                                    )})`}
                                                    onClick={() => {
                                                        setAddtionalpay(true)
                                                        setInvindex(index)
                                                    }}
                                                />
                                            ) : (
                                                <SecondaryButton
                                                    label="Invoice"
                                                    onClick={() => {
                                                        if (
                                                            new Date(data.buynowdate_added) >
                                                            new Date('12/16/2023 00:00:00')
                                                        ) {
                                                            history.push(
                                                                `/New_Invoice/${data.common_invoice}`,
                                                            )
                                                        } else {
                                                            history.push(
                                                                `/Invoice/${data.common_invoice}`,
                                                            )
                                                        }
                                                    }}
                                                />
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </>
                        ) : (
                            <tr>
                                <td colspan="12" className="border-none">
                                    <div className="card card-body text-center mt-2">
                                        <b>No Auction Found</b>
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            <div className="won-pgn">
                <Pagination
                    onChange={(e, value) => {
                        props.paramsupd(value)
                    }}
                    count={
                        props.data.total_pagecnt
                            ? getPaginationCount(props.data.total_pagecnt, 10)
                            : '1'
                    }
                />
            </div>
            <Popup
                modaltitle="Payment Information"
                open={modalOpen}
                handleClose={() => setmodalOpen(false)}
            >
                {' '}
                <div className="p-4 descFldDynTxt">
                    <div className="container">Transaction fee is only for a credit card.</div>
                </div>
            </Popup>
            <Popup
                modaltitle="Additional Pay"
                open={addtional_pay}
                handleClose={() => modalClose()}
            >
                {' '}
                <div className="p-4 descFldDynTxt">
                    <AdditionalPayment
                        item={props?.data?.results ? props.data.results[inv_index] : ''}
                        user={props?.user ? props.user : ''}
                        setmodalOpen={setAddtionalpay}
                    />
                </div>
            </Popup>
        </div>
    )
}

export default WonAuction
